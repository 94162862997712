import React, {Component} from 'react';
import {Row, Col, Button, Modal} from 'react-bootstrap'
import _ from 'lodash';

//User defined components
import Spinner from '../../indicators/spinner.js';

import api from '../../util/api.js';

const { removeDevicesFromSite } = api;

class SiteRemoveDevicesModal extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            apiCalling: false,
            failCalling: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        console.log("siteremovedevicesmodal nextprop", nextProps);
        
      }
    
    resetState = () => {
        this.setState({ //Set state back to default.
          apiCalling: false,
          failCalling: false,
          show: false,
        });
        this.props.handleHideModal();
    }

    removeDevices = (e) => {
        console.log("deleting site: ",this.props.siteId);
        console.log("this.props.devicesSelected: ", this.props.devicesSelected);
        var devicesSelectedIds = _.map(this.props.devicesSelected, 'deviceId');
        console.log("devicesSelectedIds: ", devicesSelectedIds);
        this.setState({
            apiCalling: true,
            failCalling: false,
        }, () => {
            removeDevicesFromSite(this.props.siteId, devicesSelectedIds)
            .then((res) => {
                this.resetState();
                this.props.refreshDevices();
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    apiCalling: false,
                    failCalling: true,
                });
            });
        });
        
    }
      


	render() {
        return (

            <Modal show={this.props.show/*true*/} onHide={this.resetState} bsSize="lg">
                <Modal.Header closeButton>
                <Modal.Title>Remove Devices From Site <Spinner active={ this.state.apiCalling } fail={this.state.failCalling}/></Modal.Title>
                </Modal.Header>
                <Modal.Body bsClass={'samModalBody'}>
                    {
                    Array.isArray(this.props.devicesSelected) && this.props.devicesSelected.length > 0 ?
                        <div>
                            <h1>Are you sure you want to remove the selected devices from this site: "{this.props.siteName}"?</h1>
                            <br></br>
                            <h3>Note: This does not delete the devices, they can still be assigned to sites in the future.</h3>
                        </div>
                    : 
                        <h1>You must select at least one device.</h1>
                    }
                </Modal.Body>
                <Modal.Footer>
                <div>
                    <Button onClick={this.resetState}>Cancel</Button>
                {
                    Array.isArray(this.props.devicesSelected) && this.props.devicesSelected.length > 0 &&
                
                        <Button onClick={this.removeDevices}>Remove</Button>
                
                }
                </div>
                
                </Modal.Footer>
            </Modal>
        );
    }
}

export default SiteRemoveDevicesModal;