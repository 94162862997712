import React, { Component } from 'react';

class ExternalSensorSingle extends React.Component {

  render() {
    let mac = this.props.label;

    return (
      <div>
        <h3>{this.props.label !== " " ? this.props.label : "Sensor" }</h3>
        <span>{this.props.mac.toUpperCase()}</span>
        <span>{" "}</span>
        <span>{this.props.data}</span>
        <br/>
      </div>
    )
  }

}

export default ExternalSensorSingle;
