/*
  Notes.

  There are 3 ways that we format schedules.

  =============================
    1 : The Database Schema
  =============================

  {
  	name: <string>
  	periods_per_day: <int> either 2 or 4
  	schedule_config: <string> "5+1+1" or "7"
  	t_units: <string> "F" or "C"
  	coolSchedule: <Object> either 3 members or 7
  		{
  			period_xy: <string>
  			start_time: <string>
  			target_temp: <int>
  			temp_set: <int>
  			occupied: <string>
  		}
  	heatSchedule: <Object> either 3 members or 7
  }

*/

//  Input: One of the user's schedule objects from our database.
//  Output: A Cool Schedule object that is suitable to be sent through
//    the API and assigned to the device.
function mapCoolScheduleToFormat(inputObj) {
  let resultObj = {};
  //  Copy over the first four members
  resultObj.program = "C";
  resultObj.t_units = inputObj.t_units;
  resultObj.periods_per_day = inputObj.periods_per_day;
  resultObj.schedule_config = inputObj.schedule_config;

  //  Logic for the fifth member, the schedule:
  resultObj.schedule = {};
  if (inputObj.schedule_config == "5+1+1") {
    resultObj.schedule["mo-fr"] = getScheduleWithAppendedPeriodXy(inputObj.coolSchedule["mo-fr"]);
    resultObj.schedule.sa = getScheduleWithAppendedPeriodXy(inputObj.coolSchedule["sa"]);
    resultObj.schedule.su = getScheduleWithAppendedPeriodXy(inputObj.coolSchedule["su"]);
  }
  else if (inputObj.schedule_config == "7") {
    resultObj.schedule.mo = getScheduleWithAppendedPeriodXy(inputObj.coolSchedule["mo"]);
    resultObj.schedule.tu = getScheduleWithAppendedPeriodXy(inputObj.coolSchedule["tu"]);
    resultObj.schedule.we = getScheduleWithAppendedPeriodXy(inputObj.coolSchedule["we"]);
    resultObj.schedule.th = getScheduleWithAppendedPeriodXy(inputObj.coolSchedule["th"]);
    resultObj.schedule.fr = getScheduleWithAppendedPeriodXy(inputObj.coolSchedule["fr"]);
    resultObj.schedule.sa = getScheduleWithAppendedPeriodXy(inputObj.coolSchedule["sa"]);
    resultObj.schedule.su = getScheduleWithAppendedPeriodXy(inputObj.coolSchedule["su"]);
  }
  return resultObj;
}

//  Input: One of the user's schedule objects from our database.
//  Output: A Heat Schedule object that is suitable to be sent through
//    the API and assigned to the device.
function mapHeatScheduleToFormat(inputObj) {
  let resultObj = {};
  //  Copy over the first four members
  resultObj.program = "H";
  resultObj.t_units = inputObj.t_units;
  resultObj.periods_per_day = inputObj.periods_per_day;
  resultObj.schedule_config = inputObj.schedule_config;

  //  Logic for the fifth member, the schedule:
  resultObj.schedule = {};
  if (inputObj.schedule_config == "5+1+1") {
    resultObj.schedule["mo-fr"] = getScheduleWithAppendedPeriodXy(inputObj.heatSchedule["mo-fr"]);
    resultObj.schedule.sa = getScheduleWithAppendedPeriodXy(inputObj.heatSchedule["sa"]);
    resultObj.schedule.su = getScheduleWithAppendedPeriodXy(inputObj.heatSchedule["su"]);
  }
  else if (inputObj.schedule_config == "7") {
    resultObj.schedule.mo = getScheduleWithAppendedPeriodXy(inputObj.heatSchedule["mo"]);
    resultObj.schedule.tu = getScheduleWithAppendedPeriodXy(inputObj.heatSchedule["tu"]);
    resultObj.schedule.we = getScheduleWithAppendedPeriodXy(inputObj.heatSchedule["we"]);
    resultObj.schedule.th = getScheduleWithAppendedPeriodXy(inputObj.heatSchedule["th"]);
    resultObj.schedule.fr = getScheduleWithAppendedPeriodXy(inputObj.heatSchedule["fr"]);
    resultObj.schedule.sa = getScheduleWithAppendedPeriodXy(inputObj.heatSchedule["sa"]);
    resultObj.schedule.su = getScheduleWithAppendedPeriodXy(inputObj.heatSchedule["su"]);
  }
  return resultObj;
}

//  A helper for mapCoolScheduleToFormat() and mapHeatScheduleToFormat()
//  Input: An array of periods (could also think of this as a "day")
//  Output: A matching array, but each element now has a period_xy member.
function getScheduleWithAppendedPeriodXy(schedule) {
  let resultSchedule = schedule;

  for(let i = 0; i < schedule.length; i++) {
    resultSchedule[i]["period_xy"] = i;
  }

  return resultSchedule;
}

/*

  ============================
  How Do Device Schedules Work
  ============================

  The device schedule is an array of arrays.
  Each array has the following 7 numbers:
  A B C D I F J
  A: Are we setting values for cool (0) or heat (1)
  B: temp units. Fahrenheit (0) or Celsius (1)
  C: periods per day. 2 or 4.
  D: what day
  I: what period. 0-1 for 2 period schedule, 0-3 for 4 period schedule.
  F: start time. number of minutes after midnight.
  J: setpoint. described here in units fahrenheit * 2.

*/

function userScheduleToDeviceSchedule(inputObj, coolSchedule, heatSchedule) {
  //  B
  let units = "";
  if (inputObj.t_units === "F") units = 0;
  else if (inputObj.t_units === "C") units = 1;
  //  C
  let periods_per_day = inputObj.periods_per_day;

  let coolArr = userScheduleToArr(inputObj.coolSchedule, 0, units, periods_per_day);
  let heatArr = userScheduleToArr(inputObj.heatSchedule, 1, units, periods_per_day);

  let result = coolArr.concat(heatArr);

  for(var i = 0; i < result.length; i++){
    result[i][6] = result[i][6] * 2;
  }
  //console.log("userScheduleToDeviceSchedule result: ");
  //console.log(result);

  return result;
}

function userScheduleToArr(schedule, a, b, c) {
  let result = [];
  //  D
  //  If it has "mo-fr", we can grab the three arrays we need.
  if (schedule.hasOwnProperty("mo-fr")) {
    let moFrSchedule = schedule["mo-fr"];
    for (let i = 0; i < moFrSchedule.length; i++) {
      result.push(userScheduleToArrHelper(moFrSchedule[i], a, b, c, 0, i));
    }
    let saSchedule = schedule["sa"];
    for (let i = 0; i < saSchedule.length; i++) {
      result.push(userScheduleToArrHelper(saSchedule[i], a, b, c, 1, i));
    }
    let suSchedule = schedule["su"];
    for (let i = 0; i < suSchedule.length; i++) {
      result.push(userScheduleToArrHelper(suSchedule[i], a, b, c, 2, i));
    }
  }
  //  If it doesn't have "mo-fr", we can grab the seven arrays we need.
  else {
    let moSchedule = schedule["mo"];
    for (let i = 0; i < moSchedule.length; i++) {
      result.push(userScheduleToArrHelper(moSchedule[i], a, b, c, 0, i));
    }
    let tuSchedule = schedule["tu"];
    for (let i = 0; i < tuSchedule.length; i++) {
      result.push(userScheduleToArrHelper(tuSchedule[i], a, b, c, 1, i));
    }
    let weSchedule = schedule["we"];
    for (let i = 0; i < weSchedule.length; i++) {
      result.push(userScheduleToArrHelper(weSchedule[i], a, b, c, 2, i));
    }
    let thSchedule = schedule["th"];
    for (let i = 0; i < thSchedule.length; i++) {
      result.push(userScheduleToArrHelper(thSchedule[i], a, b, c, 3, i));
    }
    let frSchedule = schedule["fr"];
    for (let i = 0; i < frSchedule.length; i++) {
      result.push(userScheduleToArrHelper(frSchedule[i], a, b, c, 4, i));
    }
    let saSchedule = schedule["sa"];
    for (let i = 0; i < saSchedule.length; i++) {
      result.push(userScheduleToArrHelper(saSchedule[i], a, b, c, 5, i));
    }
    let suSchedule = schedule["su"];
    for (let i = 0; i < suSchedule.length; i++) {
      result.push(userScheduleToArrHelper(suSchedule[i], a, b, c, 6, i));
    }
  }

  return result;
}

function userScheduleToArrHelper(scheduleBlock, a, b, c, d, i) {
  let result = [];
  result.push(a);
  result.push(b);
  result.push(c);
  result.push(d);
  result.push(i);
  result.push(timeStringToMinutes(scheduleBlock.start_time));
  result.push(scheduleBlock.temp_set);
  return result;
}

function deviceScheduleToUserSchedule(configuration, name) {

  let result = {};

  //  Name
  result.name = name;
  let tstat_prog = JSON.parse(configuration.tstat_prog);
  //  Config
  if (configuration.tstat_prog_mode == "1")
    result.schedule_config = "5+1+1";
  else if (configuration.tstat_prog_mode == "2")
    result.schedule_config = "7";

  //  Periods per day--just grab from the first element. They all match.
  result.periods_per_day = tstat_prog[0][2];

  //  Temp Units--just grab from the first element. They all match.
  if (tstat_prog[0][1] === 0)
    result.t_units = "F";
  else if (tstat_prog[0][1] === 1)
    result.t_units = "C";

  // The first half are cooling, second half are heating.
  let resultSchedules = scheduleArrToFormat(tstat_prog.slice(0, tstat_prog.length/2),
                                           tstat_prog.slice(tstat_prog.length/2, tstat_prog.length),
                                           result.t_units);

  result.coolSchedule = scheduleArrToObject(resultSchedules[0]);
  result.heatSchedule = scheduleArrToObject(resultSchedules[1]);

  return result;
}

//  Helper for deviceScheduleToUserSchedule()
function scheduleArrToFormat(coolArr, heatArr, t_units) {
  let coolResult = [];
  let heatResult = [];

  for (let i = 0; i < coolArr.length; i++) { // 95
    let temp = {};
    // temp.period_xy = coolArr[i][4].toString();
    temp.start_time = minutesToTimeString(coolArr[i][5]);
    temp.temp_set = coolArr[i][6];
    temp.target_temp = ((parseInt(heatArr[i][6])
                          + parseInt(coolArr[i][6])) / 2);
    temp.occupied = occupiedOrNot(t_units, coolArr[i][6], "C");
    coolResult.push(temp);
  }

  for (let i = 0; i < heatArr.length; i++) { // 40
    let temp = {};
    // temp.period_xy = heatArr[i][4];
    temp.start_time = minutesToTimeString(heatArr[i][5]);
    temp.temp_set = heatArr[i][6];
    temp.target_temp = ((parseInt(heatArr[i][6])
                          + parseInt(coolArr[i][6])) / 2);
    temp.occupied = occupiedOrNot(t_units, coolArr[i][6], "H");
    heatResult.push(temp);
  }

  let result = [];
  result.push(coolResult, heatResult);
  return result;
}

//  Helper for deviceScheduleToUserSchedule()
function scheduleArrToObject(arr) {
  let result = {};

  switch(arr.length) {
    case(6):
      result["mo-fr"] = [];
      result["mo-fr"].push(arr[0], arr[1]);
      result["sa"] = [];
      result["sa"].push(arr[2], arr[3]);
      result["su"] = [];
      result["su"].push(arr[4], arr[5]);
      break;
    case(12):
      result["mo-fr"] = [];
      result["mo-fr"].push(arr[0], arr[1], arr[2], arr[3]);
      result["sa"] = [];
      result["sa"].push(arr[4], arr[5], arr[6], arr[7]);
      result["su"] = [];
      result["su"].push(arr[8], arr[9], arr[10], arr[11]);
      break;
    case(14):
      result["mo"] = [];
      result["mo"].push(arr[0], arr[1]);
      result["tu"] = [];
      result["tu"].push(arr[2], arr[3]);
      result["we"] = [];
      result["we"].push(arr[4], arr[5]);
      result["th"] = [];
      result["th"].push(arr[6], arr[7]);
      result["fr"] = [];
      result["fr"].push(arr[8], arr[9]);
      result["sa"] = [];
      result["sa"].push(arr[10], arr[11]);
      result["su"] = [];
      result["su"].push(arr[12], arr[13]);
      break;
    case(28):
      result["mo"] = [];
      result["mo"].push(arr[0], arr[1], arr[2], arr[3]);
      result["tu"] = [];
      result["tu"].push(arr[4], arr[5], arr[6], arr[7]);
      result["we"] = [];
      result["we"].push(arr[8], arr[9], arr[10], arr[11]);
      result["th"] = [];
      result["th"].push(arr[12], arr[13], arr[14], arr[15]);
      result["fr"] = [];
      result["fr"].push(arr[16], arr[17], arr[18], arr[19])
      result["sa"] = [];
      result["sa"].push(arr[20], arr[21], arr[22], arr[23])
      result["su"] = [];
      result["su"].push(arr[24], arr[25], arr[26], arr[27])
      break;
  }

  return result;
}

//  Input: Number of minutes after midnight
//  Output: Time string "HH:MM"
function minutesToTimeString(inputMinutes) {
  let numHours = Math.floor(inputMinutes / 60);
  let numMinutes = inputMinutes % 60;

  let hourString = numHours.toString();
  if (hourString.length === 1) { // if single digit, add leading 0
    hourString = "0" + hourString;
  }
  let minutesString = numMinutes.toString();
  if (minutesString.length === 1) { // if single digit, add leading 0
    minutesString = "0" + minutesString;
  }
  return hourString + ":" + minutesString;
}


//  Input: Time String "HH:MM"
//  Output: Number of minutes after midnight
function timeStringToMinutes(timeStr) {
  let splitStr = timeStr.split(":");

  let hourStr = splitStr[0];
  let minStr = splitStr[1];

  return parseInt(hourStr) * 60 + parseInt(minStr);
}

//  units: either "F" or "C"
//  temp_set: setpoint number
//  program: "H" or "C"
//
//  returns: "occupied" or "unoccupied"
function occupiedOrNot(units, temp_set, program) {
    //  New var we will use to make our decision.
    let temp;
    //  If they've given us C, convert to F.
    if (units === "C") {
      temp = tempCToF(temp);
    }
    //  Otherwise, we have F and we're good to go.
    else temp = temp_set;

    //  Cooling with a setpoint equal to 95 indicates unoccupied...
    if (program === "C" && temp >= 95) {
      return "unoccupied";
    }
    //  ... same with Heating with a setpoint equal to 40.
    else if (program === "H" && temp <= 40) {
      return "unoccupied";
    }

    //  Otherwise, we're occupied.
    return "occupied";
}

function tempCToF(unitsCelsius) {
  let result = unitsCelsius * 9 / 5;
  result = result + 32;
  return result.toFixed(1);
}

module.exports = {
  mapCoolScheduleToFormat: mapCoolScheduleToFormat,
  mapHeatScheduleToFormat: mapHeatScheduleToFormat,
  userScheduleToDeviceSchedule: userScheduleToDeviceSchedule,
  deviceScheduleToUserSchedule: deviceScheduleToUserSchedule
}
