import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    fill='white'
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="M302 76.6c-22.2 2.8-43.5 10.6-62 22.9-9.5 6.3-29.3 25.9-35.1 34.8-4.9 7.6-3.5 7.3-16.4 4.3-24.9-5.7-53.8-1.1-76 12.2-29.1 17.4-47.3 46-51 80.2-.6 5.5-.6 5.5-6.1 8.4-13.8 7.3-28.5 20.6-37.8 34.6-26.3 39.1-22.3 91.7 9.5 127.4 10.1 11.4 27.2 22.8 42.4 28.2 18.8 6.7 11 6.4 185.9 6.4 172.3 0 167.6.1 184.5-5.7 20.3-6.9 38.1-19.8 51.3-37.3 6.9-9 14.4-24.6 17.5-36.5 2.3-8.8 2.6-11.9 2.6-25.5 0-13.5-.3-16.7-2.5-25.1-7.8-28.9-27.6-53.8-53.1-66.9l-6.8-3.5.8-6.5c1.3-11 .7-33.7-1.1-43-5.5-27.3-18-50.7-37.5-70-20.1-19.9-44.4-32.9-70.4-37.6-8.3-1.5-32.2-2.6-38.7-1.8zm28.3 30.5c41.5 6.2 75 35.4 86.5 75.4 5.6 19.3 5.3 41.2-.7 60.5-1.2 3.6-2.1 7.2-2.1 8.1 0 1.4 14.9 9.3 24.3 12.8 9.8 3.7 24.1 16.1 31.2 27.1 8.9 13.6 12 24.5 11.9 41.5-.1 10.2-.5 13.2-2.7 20-9.1 27.3-30.4 46.4-58.2 52-7.2 1.4-24.2 1.5-168.5 1.3-174.8-.4-162.2 0-176-5.8-13.6-5.8-28.4-18.7-35.7-31.1-12-20.2-13.3-47.1-3.3-68.3 8.8-18.5 23.7-31.8 45-40.1l9.5-3.7.1-14.2c0-10.2.5-15.9 1.8-20.6 6.4-23.9 25.5-44 49.4-52.1 9-3 10.7-3.2 22.7-3.3 15.1 0 22.1 1.6 38.7 8.9 5.6 2.4 10.3 4.4 10.4 4.2.1-.1 2.2-4.5 4.7-9.8 8.2-17.7 19-31.5 32.9-41.9 22.9-17.3 51.4-24.9 78.1-20.9z" />
  </svg>
)
export default SvgComponent
