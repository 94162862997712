import React, { Component } from 'react'
import { Row, Col, Button, Modal, Checkbox } from 'react-bootstrap'

// Import React Table
import ReactTable from 'react-table'
import 'react-table/react-table.css'

//User defined components
import Spinner from '../../indicators/spinner.js'
import SiteRemoveDevicesModal from './siteremovedevicesmodal.js'
import SiteAddDevicesModal from './siteadddevicesmodal.js'

import api from '../../util/api.js'

const { deleteSite, getSiteDevices } = api

class SiteCurrentDevicesTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      apiCalling: false,
      failCalling: false,
      devicesSelected: [],
      showRemoveDevicesBool: false,
      showAddDevicesModalBool: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log('sitedeletionmodal nextprop', nextProps)
  }

  componentDidMount() {
    this.fetchDevicesForSite()
  }

  resetState = () => {
    this.setState({
      //Set state back to default.
      apiCalling: false,
      failCalling: false,
      show: false,
    })
    this.props.handleHideModal()
  }

  addDevices = (e) => {
    console.log('adding devices')

    /*
        this.setState({
            apiCalling: true,
            failCalling: false,
        }, () => {
            deleteSite(this.props.siteId, this.props.siteName)
            .then((res) => {
                this.resetState();
                this.props.refreshSites();
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    apiCalling: false,
                    failCalling: true,
                });
            });
        });
        */
  }

  fetchDevicesForSite = () => {
    //console.log("process.env.REACT_APP_API_URL" + process.env.REACT_APP_API_URL);
    console.log('Getting devices for site: ' + this.props.siteId)
    this.setState(
      {
        apiCalling: true,
        failCalling: false,
      },
      () => {
        getSiteDevices(this.props.siteId)
          .then((devices) => {
            console.log('got devices: ', devices)
            //var modifiedThermostats = devices.thermostats.filter(thermostat => (thermostat != null));
            this.setState({
              devices: devices,
            })
            /*
              this.setState({
                    apiCalling: false,
                    failCalling: false,
              });*/
          })
          .catch((err) => {
            console.log(err)
            this.setState({
              apiCalling: false,
              failCalling: true,
            })
          })
      },
    )
  }

  renderDeviceCheckboxButton = (props) => {
    var mac = props.original.mac_address
    var deviceId = props.original._id
    var selectedIndex = this.state.devicesSelected.findIndex((device) => device.deviceId === deviceId)
    if (selectedIndex === -1) var checked = false
    else var checked = true
    return (
      <Checkbox
        onClick={this.handleCheckboxClick}
        macAddress={mac}
        deviceId={deviceId}
        key={deviceId}
        checked={checked}
        style={{ marginTop: '-1px' }}
      ></Checkbox>
    )
  }

  //To maintain a state for each checkbox we have an array that contains the ID of every selected device.
  //This way we can sort the table, filter the table, etc. and still know which devices have the checkbox checked
  handleCheckboxClick = (e) => {
    //e.preventDefault();
    console.log('handleCheckboxClick clicked e.target.checked', e.target.checked)
    var macAddress = e.target.attributes.macAddress.value
    var deviceId = e.target.attributes.deviceId.value
    //If a box ix unchecked we must remove it from the list of selected devices
    if (!e.target.checked) {
      //First find the index of the device in the devicesSelected array of objects
      var index = this.state.devicesSelected.findIndex((device) => device.deviceId === deviceId)
      //This shouldn't happen, but if device isn't in the array we just ignore
      if (index === -1) return

      this.setState({
        devicesSelected: [
          ...this.state.devicesSelected.slice(0, index),
          ...this.state.devicesSelected.slice(index + 1),
        ],
      })
    } else {
      this.setState({
        /*devicesSelected: [...this.state.devicesSelected, {
          macAddress: macAddress,
          deviceId: deviceId
        }]*/
        devicesSelected: this.state.devicesSelected.concat({
          macAddress: macAddress,
          deviceId: deviceId,
        }),
      })
    }
  }

  checkboxSortMethod = (a, b) => {
    var aSelectedIndex = this.state.devicesSelected.findIndex((device) => device.deviceId === a)
    var bSelectedIndex = this.state.devicesSelected.findIndex((device) => device.deviceId === b)
    return aSelectedIndex > bSelectedIndex ? -1 : 1
  }

  tableFilterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
  }

  tableFields = [
    {
      accessor: '_id',
      Header: 'Select Device',
      filterable: false,
      sortable: true,
      Cell: this.renderDeviceCheckboxButton,
      sortMethod: this.checkboxSortMethod,
      width: 100,
    },

    { accessor: 'mac_address', Header: 'Mac Address' },

    { accessor: 'name', Header: 'Name' },

    //{ accessor: '', Header: "Single OTA", sortable: false, filterable: false, sortable: false, Cell: this.renderChooseFirmwareButton},

    {
      Header: 'FW Version',
      id: 'version',
      accessor: (device) => {
        console.log('device: ', device)
        if (
          device &&
          device.configuration &&
          device.configuration.fw
        ) {
          return device.configuration.fw
        }

        if (
          device &&
          device.configuration &&
          device.configuration.chassis_fw
        ) {
          return device.configuration.chassis_fw
        }

        return 'Undefinedxx'
      },
      sortable: true,
      filterable: true,
      sortable: true,
    },

    /*
    { id: "otaStatus",
        accessor: device => {
        if(device && "otaStatus" in device){
            return device.otaStatus;
        }

        return "";

        }, Header: "OTA Status", sortable: false, filterable: false, sortable: false, Cell: this.renderOtaStatusCell},
        */
  ]
  //<SiteAddDevicesModal show={this.state.showAddDevicesModalBool} handleHideModal={this.handleHideAddDevicesModal} refreshSites={this.getSites} siteId={this.state.siteToAddDevices._id} siteName={this.state.siteToAddDevices.name}></SiteAddDevicesModal>

  handleHideRemoveDevicesModal = (e) => {
    this.setState({ showRemoveDevicesBool: false })
  }

  handleShowRemoveDevicesModal = (e) => {
    console.log('selectedDevices: ', this.state.devicesSelected)
    this.setState({
      showRemoveDevicesBool: true,
    })
  }

  handleHideAddDevicesModal = (e) => {
    this.setState({ showAddDevicesModalBool: false })
  }

  handleShowAddDevicesModal = (e) => {
    this.setState({ showAddDevicesModalBool: true })
  }

  render() {
    return (
      <React.Fragment>
        <SiteRemoveDevicesModal
          show={this.state.showRemoveDevicesBool}
          handleHideModal={this.handleHideRemoveDevicesModal}
          refreshDevices={this.fetchDevicesForSite}
          siteId={this.props.siteId}
          siteName={this.props.siteName}
          devicesSelected={this.state.devicesSelected}
        ></SiteRemoveDevicesModal>
        <SiteAddDevicesModal
          show={this.state.showAddDevicesModalBool}
          handleHideModal={this.handleHideAddDevicesModal}
          refreshDevices={this.fetchDevicesForSite}
          siteId={this.props.siteId}
          siteName={this.props.siteName}
        ></SiteAddDevicesModal>
        <ReactTable
          minRows={6}
          data={this.state.devices}
          columns={this.tableFields}
          defaultPageSize={10}
          className='-striped -highlight'
          filterable={true}
          defaultFilterMethod={this.tableFilterMethod}
          showPaginationTop={true}
          noDataText='No Devices Found'
        />
        <Row style={{ display: 'flex', paddingLeft: '5px', paddingRight: '5px' }}>
          <Button
            style={{ width: '200px' }}
            className={'btn-warning'}
            siteid={this.props.siteId}
            onClick={this.handleShowRemoveDevicesModal}
          >
            Remove Selected Devices
          </Button>
          <Button
            style={{ width: '200px', marginLeft: 'auto' }}
            className={'btn-primary'}
            siteid={this.props.siteId}
            onClick={this.handleShowAddDevicesModal}
          >
            Add New Devices
          </Button>
        </Row>
      </React.Fragment>
    )
  }
}

export default SiteCurrentDevicesTable
