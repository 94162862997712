import React, { Component } from 'react';

class Slideshow extends React.Component {
  render() {
    return (
      <div>
        {this.props.children[this.props.step]}
      </div>
    )
  }
}

export default Slideshow;
