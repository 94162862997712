import React, { Component } from 'react'
import { Row, Col, Button, Modal, Checkbox } from 'react-bootstrap'

// Import React Table
import ReactTable from 'react-table'
//import "react-table/react-table.css";
import _ from 'lodash'

//User defined components
import Spinner from '../../indicators/spinner.js'
import api from '../../util/api.js'

const { fetchUserDevicesPromise, addDevicesToSite } = api

var apiUrl = 'https://jdpy43j0u7.execute-api.us-west-2.amazonaws.com'

class SiteAddDevicesModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      apiCalling: false,
      failCalling: false,
      devicesSelected: [],
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log('sitedeletionmodal nextprop', nextProps)
  }

  componentDidMount() {
    this.fetchThermostats()
  }

  resetState = () => {
    this.setState({
      //Set state back to default.
      apiCalling: false,
      failCalling: false,
      show: false,
      devicesSelected: [],
    })
    this.props.handleHideModal()
  }

  addDevices = (e) => {
    console.log('adding devices to siteId: ', this.props.siteId)
    console.log('devices selected: ', this.state.devicesSelected)
    var deviceIds = _.map(this.state.devicesSelected, 'deviceId')
    this.setState(
      {
        apiCalling: true,
        failCalling: false,
      },
      () => {
        addDevicesToSite(this.props.siteId, deviceIds)
          .then((res) => {
            this.resetState()
            this.props.refreshDevices()
          })
          .catch((err) => {
            console.log(err)
            alert(err)
            this.setState({
              apiCalling: false,
              failCalling: true,
            })
          })
      },
    )
  }

  fetchThermostats = () => {
    //console.log("process.env.REACT_APP_API_URL" + process.env.REACT_APP_API_URL);
    this.setState({
      showSpinner: true,
    })
    fetchUserDevicesPromise()
      .then((res) => res.json())
      .then((response) => {
        //Successful get site live data
        if (response && response.success === true && response.data && 'thermostats' in response.data) {
          this.setState({
            devices: response.data.thermostats,
            deviceTypeSelected: 'thermostat',
          })
        }
        //Unsuccessful login
        else {
          //TODO: handle missing devices? should never happen though
          console.log('unsuccessful')
        }
        this.setState({
          showSpinner: false,
        })
      })
      .catch((err) => {
        console.error(err)
        this.setState({
          showSpinner: false,
        })
      })
  }

  //To maintain a state for each checkbox we have an array that contains the ID of every selected device.
  //This way we can sort the table, filter the table, etc. and still know which devices have the checkbox checked
  handleCheckboxClick = (e) => {
    //e.preventDefault();
    var macAddress = e.target.attributes.macAddress.value
    var deviceId = e.target.attributes.deviceId.value
    //If a box ix unchecked we must remove it from the list of selected devices
    if (!e.target.checked) {
      //First find the index of the device in the devicesSelected array of objects
      var index = this.state.devicesSelected.findIndex((device) => device.deviceId === deviceId)
      //This shouldn't happen, but if device isn't in the array we just ignore
      if (index === -1) return

      this.setState({
        devicesSelected: [
          ...this.state.devicesSelected.slice(0, index),
          ...this.state.devicesSelected.slice(index + 1),
        ],
      })
    } else {
      this.setState({
        /*devicesSelected: [...this.state.devicesSelected, {
          macAddress: macAddress,
          deviceId: deviceId
        }]*/
        devicesSelected: this.state.devicesSelected.concat({
          macAddress: macAddress,
          deviceId: deviceId,
        }),
      })
    }
  }

  renderDeviceCheckboxButton = (props) => {
    var mac = props.original.mac_address
    var deviceId = props.original._id
    var selectedIndex = this.state.devicesSelected.findIndex((device) => device.deviceId === deviceId)
    if (selectedIndex === -1) var checked = false
    else var checked = true
    return (
      <Checkbox
        onClick={this.handleCheckboxClick}
        macAddress={mac}
        deviceId={deviceId}
        key={deviceId}
        checked={checked}
      ></Checkbox>
    )
  }

  //To maintain a state for each checkbox we have an array that contains the ID of every selected device.
  //This way we can sort the table, filter the table, etc. and still know which devices have the checkbox checked
  handleCheckboxClick = (e) => {
    //e.preventDefault();
    var macAddress = e.target.attributes.macAddress.value
    var deviceId = e.target.attributes.deviceId.value
    //If a box ix unchecked we must remove it from the list of selected devices
    if (!e.target.checked) {
      //First find the index of the device in the devicesSelected array of objects
      var index = this.state.devicesSelected.findIndex((device) => device.deviceId === deviceId)
      //This shouldn't happen, but if device isn't in the array we just ignore
      if (index === -1) return

      this.setState({
        devicesSelected: [
          ...this.state.devicesSelected.slice(0, index),
          ...this.state.devicesSelected.slice(index + 1),
        ],
      })
    } else {
      this.setState({
        /*devicesSelected: [...this.state.devicesSelected, {
          macAddress: macAddress,
          deviceId: deviceId
        }]*/
        devicesSelected: this.state.devicesSelected.concat({
          macAddress: macAddress,
          deviceId: deviceId,
        }),
      })
    }
  }

  checkboxSortMethod = (a, b) => {
    var aSelectedIndex = this.state.devicesSelected.findIndex((device) => device.deviceId === a)
    var bSelectedIndex = this.state.devicesSelected.findIndex((device) => device.deviceId === b)
    return aSelectedIndex > bSelectedIndex ? -1 : 1
  }

  tableFilterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
  }

  tableFields = [
    {
      accessor: '_id',
      Header: 'Select Device',
      filterable: false,
      sortable: true,
      Cell: this.renderDeviceCheckboxButton,
      sortMethod: this.checkboxSortMethod,
      width: 100,
    },

    { accessor: 'mac_address', Header: 'Mac Address' },

    { accessor: 'name', Header: 'Name' },

    //{ accessor: '', Header: "Single OTA", sortable: false, filterable: false, sortable: false, Cell: this.renderChooseFirmwareButton},

    {
      Header: 'FW Version',
      id: 'version',
      accessor: (device) => {
        if (
          device &&
          device.configuration &&
          device.configuration.fw
        ) {
          return device.configuration.fw
        }

        if (
          device &&
          device.configuration &&
          device.configuration.chassis_fw
        ) {
          return device.configuration.chassis_fw
        }

        return 'Undefined'
      },
      sortable: true,
      filterable: true,
      sortable: true,
    },
    /*
    { id: "otaStatus",
        accessor: device => {
        if(device && "otaStatus" in device){
            return device.otaStatus;
        }

        return "";

        }, Header: "OTA Status", sortable: false, filterable: false, sortable: false, Cell: this.renderOtaStatusCell},
        */
  ]

  render() {
    return (
      <Modal show={this.props.show /*true*/} onHide={this.resetState} bsSize='lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            Add Devices <Spinner active={this.state.apiCalling} fail={this.state.failCalling} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body bsClass={'samModalBody'}>
          <ReactTable
            data={this.state.devices}
            columns={this.tableFields}
            defaultPageSize={10}
            className='-striped -highlight'
            filterable={true}
            defaultFilterMethod={this.tableFilterMethod}
            showPaginationTop={true}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.resetState}>Cancel</Button>
          <Button onClick={this.addDevices}>Add Devices</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default SiteAddDevicesModal
