import React, { Component } from 'react'
import { ToggleButtonGroup, ToggleButton, ButtonToolbar } from 'react-bootstrap'
import Datetime from 'react-datetime'
import Moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../css/xcspeccustom.css'

class PastDatePick extends React.Component {
  constructor() {
    super()
    const date = new Date()
    // set yesterday to 00:00:00
    const yesterday = new Date(Datetime.moment().subtract(1, 'day').startOf('day'))
    const endOfDayOfDate = new Date(Datetime.moment().endOf('day'))
    this.state = {
      used: false,
      currentMoment: date,
      startDatetime: date,
      // endDatetime: endOfDayOfDate,
    }

    this.dateRangePickerRef = React.createRef()
  }

  //  If Live mode is selected, populates graphs with live data and sets up
  //    regular check-ins.
  //  If Past mode is selected, clears regular check-ins.
  handleChangeViewMode = (newMode) => {
    this.props.handleChangeViewMode(newMode)
  }

  //  Used by our date picker widget to only make available days that
  //    are in the past.
  isValidDate = (current) => {
    var yesterday = Datetime.moment().subtract(1, 'day')
    return current.isBefore(yesterday)
  }

  //  Updates state with relevant times so that, when the user submits,
  //    the graphs are populated with data from the day that they picked.
  handleTimeChange = (startDatetime, endDatetime) => {
    this.setState({ used: true })
    var startMoment = Moment(startDatetime)
    var endMoment = Moment(endDatetime)

    this.props.getPastDataAndDrawGraphs(startMoment, endMoment)
    /*
    let endTime = Moment(moment).add(1,'days');
    this.props.getPastDataAndDrawGraphs(moment, endTime);
    this.setState({
      currentMoment: moment.toDate()
    });
    */
  }

  render() {
    return (
      <div style={{ height: '100px' }}>
        <ButtonToolbar>
          <ToggleButtonGroup
            type='radio'
            name='viewmode'
            defaultValue={this.props.viewMode}
            onChange={this.handleChangeViewMode}
            justified
          >
            <ToggleButton value={'live'}>View Live Data</ToggleButton>
            <ToggleButton value={'past'}>View A Specific Date</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>
        <div>
          <div
            ref={this.dateRangePickerRef}
            id='daterangepicker'
            style={{
              display: this.props.viewMode === 'past' ? 'inline' : 'none',
              topMargin: '4px',
              bottomMargin: '4px',
              zIndex: '1001',
              width: '50%',
            }}
          >
            {/*console.log("this.dateRangePickerRef: ", this.dateRangePickerRef)*/}

            <DatePicker
              selectsRange={true}
              startDate={this.state.startDatetime}
              endDate={this.state.endDatetime}
              maxDate={new Moment(this.state.startDatetime).add('days', 7).toDate()}
              onChange={(update) => {
                let start = update[0]
                let end = update[1]

                // compare the two date object to see if they are the same
                if (start && end && Moment(start).isSame(end, 'day')) {
                  console.log('The same!!!!')
                  end = Moment(start).endOf('day').toDate()
                  console.log('new end: ', end)
                }


                console.log('update', update)
                console.log('fixed update', [start, end])
                //setDateRange(update);
                this.setState(
                  {
                    startDatetime: start,
                    endDatetime: end,
                  },
                  () => {
                    //Second item was selected, draw this date
                    if (update[1] !== null) {
                      this.handleTimeChange(this.state.startDatetime, this.state.endDatetime)
                    }
                  },
                )
              }}
              isClearable={true}
            />

            {/*
          <Datetime
            inputProps={{ style: { width: "100%", margin: "4px"}, value:  this.state.currentMoment }}
            viewMode='days'
            isValidDate={ this.isValidDate }
            onChange={this.handleTimeChange}
            initialValue={new Date()}
            closeOnSelect={true}/>
          */}

            <center style={{ color: 'blue' }}>{this.state.used ? '' : 'Select a date!'}</center>
          </div>
        </div>
      </div>
    )
  }
}

export default PastDatePick
