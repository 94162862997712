import axios from 'axios';

// var config = require('../config.js');

//var apiUrl = config.domains.api;
// var apiUrl = "https://jdpy43j0u7.execute-api.us-west-2.amazonaws.com";
var apiUrl = "https://2c3hufax8c.execute-api.us-west-2.amazonaws.com";

import constants from '../../config/constants.js'

function handleAxiosResponse(resolve, reject, res){
  if(res.hasOwnProperty("response") && res.response.hasOwnProperty("data") && res.response.data.hasOwnProperty("success") && res.response.data.success == true)
    return resolve();
  else{
    if(res.hasOwnProperty("response") && res.response.hasOwnProperty("data") && res.response.data.hasOwnProperty("message"))
      return reject(res.response.data.message);
    else
      return reject("There was a problem with your request");
  }
}

function getDevice(siteID, deviceID) {
  let requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID;

  return fetch(requestStr, {
   method: 'GET',
   headers: {
    Authorization: localStorage.getItem('token')
   }
  })
  .then(res => res.json())
}

function getLiveData(siteID, deviceID) {
  let requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID + "/livedata";

  return fetch(requestStr, {
   method: 'GET',
   headers: {
    Authorization: localStorage.getItem('token')
   }
  })
  .then(res => res.json())
} 

//node version was v10.15.3
function patchSiteMasterScheduleId(siteID, masterScheduleId) {
  let requestStr = apiUrl + "/site/" + siteID + "/masterscheduleid";

  return fetch(requestStr, {
   method: 'PATCH',
   headers: {
    Authorization: localStorage.getItem('token')
  },
  body: JSON.stringify({
    masterScheduleId: masterScheduleId
  })
  })
  .then(res => res.json())
}

//node version was v10.15.3
function setHold(siteID, deviceID, holdValue) {
  let requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID + "/temperaturehold";

  return fetch(requestStr, {
   method: 'POST',
   headers: {
    Authorization: localStorage.getItem('token')
  },
  body: JSON.stringify({
    h: holdValue
  })
  })
  .then(res => res.json())
}

function setFan(siteID, deviceID, bool) {
  let requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID + "/fanmode";

  return fetch(requestStr, {
   method: 'POST',
   headers: {
    Authorization: localStorage.getItem('token')
  },
  body: JSON.stringify({
    fm: bool ? "ON" : "A"
  })
  })
  .then(res => res.json())
}

function setLock(siteID, deviceID, mode) {
  let requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID + "/lockout";
  let body = {
    lk: mode
  }
  // console.log(body);
  return fetch(requestStr, {
    method: 'POST',
    headers: {
      Authorization: localStorage.getItem('token')
    },
    body: JSON.stringify(body)
  })
  .then(res => res.json())
}

function setEngagedTransaction(siteID, deviceID, seconds) {
  let requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID + "/engagedtransactioninterval";
  let body = {
    data: seconds
  }
  console.log(body);

  return fetch(requestStr, {
    method: 'POST',
    headers: {
      Authorization: localStorage.getItem('token')
    },
    body: JSON.stringify(body)
  })
  .then(res => res.json())
}

function setSetpoint(siteID, deviceID, program, value) {
  let v = value.toFixed(1);
  let requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID + "/targettemperature";
  console.log("api called setSetpoint");
  return fetch(requestStr, {
   method: 'POST',
   headers: {
    Authorization: localStorage.getItem('token')
    },
    body: JSON.stringify({
      mode: program,
      temp: v.toString()
    })
  })
  .then((res) => {
    console.log(res);
    return res.json();
  })
  .catch((e) => {
    console.log("error in setSetpoint");
    console.log(e);
  })
}

function setOpMode(siteID, deviceID, opMode) {
  let requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID + "/opmode";

  return fetch(requestStr, {
   method: 'POST',
   headers: {
    Authorization: localStorage.getItem('token')
    },
    body: JSON.stringify({
      "om": opMode
    })
  })
  .then(res => res.json())
}

function setProgMode(siteID, deviceID, progMode) {
  let requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID + "/progmode";

  return fetch(requestStr, {
   method: 'POST',
   headers: {
    Authorization: localStorage.getItem('token')
    },
    body: JSON.stringify({
      data: progMode
    })
  })
  .then(res => res.json())
}

function setSchedule(siteID, deviceID, scheduleId) {
  let requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID + "/setthermschedule";

  let bodyObj = {
    scheduleId: scheduleId
  };


  //tstat_prog_mode: "1"
  console.log("schedule bodyObj: !!!!!!!!!!!!!!!!!!!");
  console.log(JSON.stringify(bodyObj));

  return fetch(requestStr, {
    method: 'POST',
    headers: {
      Authorization: localStorage.getItem('token')
    },
    body: JSON.stringify(bodyObj)
  })
  /*
  .then((res) => {
    res.json();
    requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID + "/progmode";
    if(schedule.schedule_config === "5+1+1")
      bodyObj = {
        data: "1"
      };
    else
      bodyObj = {
        data: "2"
      };
    console.log("prog_mode bodyObj: !!!!!!!!!!!!!!!!!!!");
    console.log(JSON.stringify(bodyObj));
    return fetch(requestStr, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('token')
      },
      body: JSON.stringify(bodyObj)
    })
  })
  */
  .then(res => res.json())
  .catch((err) => {
    console.log(err);
  })
}

// function setSchedule(siteID, deviceID, schedule) {
//   let requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID + "/setthermschedule";
//
//   // console.log(JSON.stringify(schedule));
//   console.log(JSON.stringify(mapToFormat.userScheduleToDeviceSchedule(schedule)));
//
//   return fetch(requestStr, {
//     method: 'POST',
//     headers: {
//       Authorization: localStorage.getItem('token')
//     },
//     body: JSON.stringify(schedule)
//   })
//   .then(res => res.json())
// }

function postSchedule(scheduleObj) {
  let requestStr = apiUrl + "/user/thermostatschedules";

  return fetch(requestStr, {
   method: 'POST',
   headers: {
    Authorization: localStorage.getItem('token')
  },
  body: JSON.stringify(scheduleObj)
  })
  .then((res) => {
    console.log(res);
    return res.json()
  })
  .catch((err) => {
    console.log(err);
  })
}

function postHolidaySchedule(scheduleObj) {
  let requestStr = apiUrl + "/user/thermostatholidayschedules";

  return fetch(requestStr, {
   method: 'POST',
   headers: {
    Authorization: localStorage.getItem('token')
  },
  body: JSON.stringify(scheduleObj)
  })
  .then((res) => {
    console.log(res);
    return res.json()
  })
  .catch((err) => {
    console.log(err);
  })
}

function deleteSchedule(scheduleId, name) {
  let requestStr = apiUrl + "/user/thermostatschedules";

  return new Promise((resolve, reject) => {
    axios({
          method: 'delete',
          headers: {
            Authorization: localStorage.getItem('token')
          },
          url: requestStr,
          timeout: 5000,
          data: {
            scheduleId: scheduleId,
            name: name
          }
    })
    .then(() => {
      console.log("success");
      return resolve();
    })
    .catch ((err) => {
      console.log(err);
      return reject();
    })
  });
}

function deleteHolidaySchedule(scheduleId, name) {
  let requestStr = apiUrl + "/user/thermostatholidayschedules";

  return new Promise((resolve, reject) => {
    axios({
          method: 'delete',
          headers: {
            Authorization: localStorage.getItem('token')
          },
          url: requestStr,
          timeout: 5000,
          data: {
            scheduleId: scheduleId,
            name: name
          }
    })
    .then(() => {
      console.log("success");
      return resolve();
    })
    .catch ((err) => {
      console.log(err);
      return reject();
    })
  });
}

function setMessageArea(siteID, deviceID, tstat_msg) {
  let requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID + "/messagearea";

  return fetch(requestStr, {
   method: 'POST',
   headers: {
     Authorization: localStorage.getItem('token')
   },
   body: JSON.stringify({
     mt: tstat_msg
   })
  })
  .then(res => res.json())
}

//  Mode is an integer 0-3
//
//  What should be displayed on the Message Area on the front panel?
//
//  0: Co2
//  1: Temp, Hum
//  2: Co2, Temp, Hum
//  3: Text Only
function setMessageAreaMode(siteID, deviceID, mode) {
  let requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID + "/messageareamode";

  return fetch(requestStr, {
    method: 'POST',
    headers: {
      Authorization: localStorage.getItem('token')
    },
    body: JSON.stringify({
      data: mode
    })
  })
  .then(res => res.json())
}

function getCommand(siteID, deviceID, commandID) {
  let requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID + "/commandexists/" + commandID;
  console.log(requestStr);
  return fetch(requestStr, {
    method: "GET",
    headers: {
      Authorization: localStorage.getItem('token'),
    }
  })
  .then(res => res.json())
}

function getBulkData(siteID, deviceID, starttime, endtime) { // hours: number, datetime: ISO string
  let requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID + "/bulkdata";
  return fetch(requestStr, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem('token')
    },
    body: JSON.stringify({
      starttime: starttime,
      endtime: endtime
    })
  })
  .then(res => res.json())
}

function createSite(name) {
  let requestStr = apiUrl + "/site";

  return new Promise((resolve, reject) => {
    axios({
          method: 'post',
          headers: {
            Authorization: localStorage.getItem('token')
          },
          url: requestStr,
          timeout: 5000,
          data: {
            name: name
          }
    })
    .then((res) => {
      if(res.hasOwnProperty("data") && res.data.hasOwnProperty("success") && res.data.success == true)
        return resolve();
      else
        return reject("create Site failed");
    })
    .catch ((err) => {
      console.log(err);
      return reject();
    })
  });
}

function deleteSite(siteId, siteName) {
  let requestStr = apiUrl + "/site";

  return new Promise((resolve, reject) => {
    axios({
          method: 'delete',
          headers: {
            Authorization: localStorage.getItem('token')
          },
          url: requestStr,
          timeout: 5000,
          data: {
            siteId: siteId,
            siteName: siteName
          }
    })
    .then((res) => {
      if(res.hasOwnProperty("data") && res.data.hasOwnProperty("success") && res.data.success == true)
        return resolve();
      else
        return reject("delete site failed");
    })
    .catch ((err) => {
      console.log(err);
      return reject();
    })
  });
}

function getSiteDevices(siteId) {
  let requestStr = apiUrl + "/site/" + siteId + "/devices";

  return new Promise((resolve, reject) => {
    axios({
          method: 'get',
          headers: {
            Authorization: localStorage.getItem('token')
          },
          url: requestStr,
          timeout: 5000,
    })
    .then((res) => {
      console.log("res is: ", res);
      console.log(res.data.data.devices);
      if(res.hasOwnProperty("data") && res.data.hasOwnProperty("data") && res.data.success == true && res.data.data.hasOwnProperty("devices"))
        return resolve(res.data.data.devices);
      else
        return reject("get site devices failed");
    })
    .catch ((err) => {
      console.log(err);
      return reject();
    })
  });
}

function fetchDeviceIdFromPortalDeviceId(siteId, portalDeviceId) {
  let requestStr = apiUrl + "/site/" + siteId + "/portaldevice/" + portalDeviceId + "/deviceid";

  return new Promise((resolve, reject) => {
    axios({
          method: 'get',
          headers: {
            Authorization: localStorage.getItem('token')
          },
          url: requestStr,
          timeout: 5000,
    })
    .then((res) => {
      console.log("fetchDeviceIdFromPortalDeviceId res is: ", res);
      //console.log(res.data.data.devices);
      if(res.hasOwnProperty("data") && res.data.hasOwnProperty("data") && res.data.success == true && res.data.data.hasOwnProperty("deviceId"))
        return resolve(res.data.data.deviceId);
      else
        return reject("get deviceId from portalDeviceId failed");
    })
    .catch ((err) => {
      console.log("fetchDeviceIdFromPortalDeviceId err is:");
      console.log(err);
      return reject();
    })
  });
}

function addDevicesToSite(siteId, deviceIds) {
  let requestStr = apiUrl + "/site/" + siteId + "/devices";

  return new Promise((resolve, reject) => {
    axios({
          method: 'post',
          headers: {
            Authorization: localStorage.getItem('token')
          },
          url: requestStr,
          timeout: 5000,
          data: {
            devices: deviceIds
          }
    })
    .then((res) => {
      console.log(res);
      if(res.hasOwnProperty("data") && res.data.hasOwnProperty("success") && res.data.success == true)
        return resolve();
      else{
        if(res.hasOwnProperty("data") && res.data.hasOwnProperty("message"))
          return reject(res.data.message);
        else
          return reject("There was a problem with your request");
      }
    })
    .catch ((err) => {
      if(err.hasOwnProperty("response") && err.response.hasOwnProperty("data") && err.response.data.hasOwnProperty("message"))
          return reject(err.response.data.message);
        else
          return reject("There was a problem with your request");
      //return reject();
    })
  });
}

function removeDevicesFromSite(siteId, deviceIds) {
  let requestStr = apiUrl + "/site/" + siteId + "/devices";

  return new Promise((resolve, reject) => {
    axios({
          method: 'delete',
          headers: {
            Authorization: localStorage.getItem('token')
          },
          url: requestStr,
          timeout: 5000,
          data: {
            devices: deviceIds
          }
    })
    .then((res) => {
      console.log(res);
      if(res.hasOwnProperty("data") && res.data.hasOwnProperty("success") && res.data.success == true)
        return resolve();
      else{
        if(res.hasOwnProperty("data") && res.data.hasOwnProperty("message"))
          return reject(res.data.message);
        else
          return reject("There was a problem with your request");
      }
    })
    .catch ((err) => {
      return handleAxiosResponse(resolve, reject, err);
      //return reject();
    })
  });
}

function getUserPromise(userId){
  return new Promise((resolve, reject) => {
    let requestStr = apiUrl + "/user";
    fetch(requestStr, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token')
      }
    })
    .then(res => res.json())
    .then(response => {
      if(response && response.success === true && response.data){
        console.log("fetched user");
        //console.log(response.data.user.thermostatschedules);
        /*
        this.setState({
          user: response.data.user
        }, () => {
        });
        */
       return resolve(response.data.user);
      }
      else {
        console.log("Couldn't get schedules.")
        return resolve();
      }
    })
  });
}

function getSiteLiveData(siteId){
  console.log('getSiteLiveData')
  return new Promise((resolve, reject) =>{
    fetch(apiUrl + "/site/" + siteId + "/livedata", {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token')
      }
    })
    .then((res) => {
      return resolve(res);
    })
    .catch ((err) => {
      console.log(err);
      return reject();
    })
  })
}

function fetchUserResolvedSitesAndDevices(){
  return new Promise((resolve, reject) => {
    fetch(apiUrl + '/user', {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token')
      }
    })
    .then((res) => {
      return resolve(res);
    })
    .catch ((err) => {
      console.log(err);
      return reject();
    })
  })
}

function fetchUserPromise(){
  return new Promise((resolve, reject) => {
    fetch(apiUrl + '/user', {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token')
      }
    })
    .then((res) => {
      return resolve(res);
    })
    .catch ((err) => {
      console.log(err);
      return reject();
    })
  });
}

function postAuthenticatePromise(username, password){
  return new Promise((resolve, reject) => {
    fetch(apiUrl + '/authenticate', {
      method: 'POST',
      body: JSON.stringify({
        username: username,
        password: password
      })
    })
    .then((res) => {
      return resolve(res);
    })
    .catch ((err) => {
      console.log(err);
      return reject();
    })
  });
}

function fetchUserDevicesPromise(){
  return new Promise((resolve, reject) => {
    fetch(apiUrl + "/devices", {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token')
      }
    })
    .then((res) => {
      return resolve(res);
    })
    .catch ((err) => {
      console.log(err);
      return reject();
    })
  });
}

function postThermostatClockPromise(siteId, deviceId, clockData){
  return new Promise((resolve, reject) => {
    let requestStr = apiUrl + "/site/" + siteId + "/device/" + deviceId + "/clock";
    console.log("setting clock via fetch, clockData: ", clockData);
    return fetch(requestStr, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('token')
      },
      body: JSON.stringify({
        [constants.THERMOSTAT_COMMAND_KEYS_OBJ.CLOCK]: clockData
      })
    })
    .then((res) => {
      console.log("clock set, res: ", res);
      return resolve(res);
    })
    .catch ((err) => {
      console.log(err);
      return reject();
    })
    
  });
}

function postThermostatTemperatureUnitsPromise(siteId, deviceId, temperatureUnitsString){
  return new Promise((resolve, reject) => {
    let requestStr = apiUrl + "/site/" + siteId + "/device/" + deviceId + "/temperatureunits";
    return fetch(requestStr, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('token')
      },
      body: JSON.stringify({
        [constants.THERMOSTAT_COMMAND_KEYS_OBJ.TEMPERATURE_DISPLAY_UNITS]: temperatureUnitsString
      })
    })
    .then((res) => {
      console.log("clock set, res: ", res);
      return resolve(res);
    })
    .catch ((err) => {
      console.log(err);
      return reject();
    })
    
  });
}

function postThermostatScheduleFromStringPromise(siteID, deviceID, scheduleAsString){
  return new Promise((resolve, reject) => {
    let requestStr = apiUrl + "/site/" + siteID + "/device/" + deviceID + "/setthermschedule";
    return fetch(requestStr, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('token')
      },
      body: scheduleAsString
    })
    .then((res) => {
      return resolve(res);
    })
    .catch ((err) => {
      console.log(err);
      return reject();
    })
  });
}

function postCreateThermostatHolidayScheduleEvent(portalDeviceId, holidayScheduleId){
  return new Promise((resolve, reject) => {
    let requestStr = apiUrl + "/thermostat/holidayscheduleevent";
    return fetch(requestStr, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('token')
      },
      body: JSON.stringify({
        deviceId: portalDeviceId,
        holidayScheduleId: holidayScheduleId,
      })
    })
    .then((res) => {
      return resolve(res);
    })
    .catch ((err) => {
      console.log(err);
      return reject();
    })
  });
}

function axiosPostThermostatBulkCommandsPromise(devicesSelected, commands){
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      headers: {
        Authorization: localStorage.getItem('token')
      },
      url: apiUrl + "/thermostat/bulkcommand",
      timeout: 15000,
      data: {
        thermostatIds: devicesSelected,
        commands: commands
      }
    })
    .then((res) => {
      return resolve(res);
    })
    .catch ((err) => {
      console.log(err);
      return reject();
    })
  });
}

function axiosGetThermostatHolidayEventsPromise(siteId, deviceId){
  return new Promise((resolve, reject) => {
    // axios({
    //   method: 'get',
    //   headers: {
    //     Authorization: localStorage.getItem('token')
    //   },
    //   url: apiUrl + "/site/" + siteId + " /device/" + deviceId + "/holidayscheduleevents",
    //   timeout: 15000,
    //   data: {},
    //   // data: {
    //   //   thermostatIds: devicesSelected,
    //   //   commands: commands
    //   // }
    // })
    // .then((res) => {
    //   return resolve(res.data.thermostatHolidayScheduleEvents);
    // })
    // .catch ((err) => {
    //   console.log(err);
    //   return reject();
    // })
    var requestStr = apiUrl + "/site/" + siteId + "/device/" + deviceId + "/holidayscheduleevents";
    console.log("axiosGetThermostatHolidayEventsPromise requestStr: ", requestStr);
      fetch(requestStr, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      .then(res => res.json())
      .then(response => {
        console.log("response for holidayschedule events: ", response);
        if(response && response.success === true && response.data){
          
          
          /*
          this.setState({
            user: response.data.user
          }, () => {
          });
          */
         return resolve(response.data.thermostatHolidayScheduleEvents);
        }
        else {
          console.log("Couldn't get schedules.")
          return resolve();
        }
      })
      .catch ((err) => {
        console.log(err);
        return reject();
      })

    // return fetch(requestStr, {
    //   method: 'GET',
    //   headers: {
    //     Authorization: localStorage.getItem('token')
    //   },
    //   // body: JSON.stringify({
    //   //   deviceId: portalDeviceId,
    //   //   holidayScheduleId: holidayScheduleId,
    //   // })
    // })
    // .then((res) => {
    //   console.log("res is: ", res);
    //   return resolve(res.data.thermostatHolidayScheduleEvents);
    // })
    // .catch ((err) => {
    //   console.log(err);
    //   return reject();
    // })
  });
}

const api = {
  getDevice: getDevice,
  getLiveData: getLiveData,
  patchSiteMasterScheduleId: patchSiteMasterScheduleId,
  setHold: setHold,
  setFan: setFan,
  setLock: setLock,
  setEngagedTransaction: setEngagedTransaction,
  setSetpoint: setSetpoint,
  setOpMode: setOpMode,
  setSchedule: setSchedule,
  postSchedule: postSchedule,
  deleteSchedule: deleteSchedule,
  deleteHolidaySchedule: deleteHolidaySchedule,
  setMessageArea: setMessageArea,
  setMessageAreaMode: setMessageAreaMode,
  getCommand: getCommand,
  getBulkData: getBulkData,
  createSite: createSite,
  deleteSite: deleteSite,
  getSiteDevices: getSiteDevices,
  addDevicesToSite: addDevicesToSite,
  removeDevicesFromSite: removeDevicesFromSite,
  getUserPromise: getUserPromise,
  getSiteLiveData: getSiteLiveData,
  fetchUserResolvedSitesAndDevices: fetchUserResolvedSitesAndDevices,
  fetchUserPromise: fetchUserPromise,
  postAuthenticatePromise: postAuthenticatePromise,
  fetchUserDevicesPromise: fetchUserDevicesPromise,
  postThermostatClockPromise: postThermostatClockPromise,
  postThermostatScheduleFromStringPromise: postThermostatScheduleFromStringPromise,
  axiosPostThermostatBulkCommandsPromise: axiosPostThermostatBulkCommandsPromise,
  postThermostatTemperatureUnitsPromise: postThermostatTemperatureUnitsPromise,
  postHolidaySchedule: postHolidaySchedule,
  postCreateThermostatHolidayScheduleEvent: postCreateThermostatHolidayScheduleEvent,
  fetchDeviceIdFromPortalDeviceId: fetchDeviceIdFromPortalDeviceId,
  axiosGetThermostatHolidayEventsPromise: axiosGetThermostatHolidayEventsPromise,
}

export default api
