import React, { Component } from 'react';
import {ToggleButton, ToggleButtonGroup} from 'react-bootstrap';
import MyToolTip from "../../indicators/mytooltip.js"

class ScheduleWizardPeriod extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      unoccupied_heatset: 48,
      unoccupied_coolset: 90,
    };
  }

  handleTimeChange = (e) => {
    // Send target value while attaching my index.
    this.props.timeChange(e.target.value, this.props.index);
  }

  handleTempChange = (e) => {
    // Send target value while attaching my index.
    if (e.target.value > 88 || e.target.value < 50) return;
    this.props.tempChange(parseInt(e.target.value), this.props.index);
  }

  handleHeatChange = (e) => {
    if (e.target.value < 48 || this.props.cool_set - e.target.value < 4) return;
    this.props.heatSetChange(parseInt(e.target.value), this.props.index);
  }

  handleCoolChange = (e) => {
    if (e.target.value > 90 || e.target.value - this.props.heat_set < 4) return;
    this.props.coolSetChange(parseInt(e.target.value), this.props.index);
  }

  handleOccupiedChange = (e) => {
    // Send target value while attaching my index.
    this.props.occupiedChange(e, this.props.index);
    if(e === "unoccupied") {
      this.setState({
        occupied_heatset: this.props.heat_set,
        occupied_coolset: this.props.cool_set
      });
      this.props.heatSetChange(this.state.unoccupied_heatset, this.props.index);
      this.props.coolSetChange(this.state.unoccupied_coolset, this.props.index);
    }
    else if(e === "occupied") {
      this.setState({
        unoccupied_heatset: this.props.heat_set,
        unoccupied_coolset: this.props.cool_set
      });
      this.props.heatSetChange(this.state.occupied_heatset, this.props.index);
      this.props.coolSetChange(this.state.occupied_coolset, this.props.index);
    }
  }

  render() {
    let targetTempElement;
    if (this.props.occupied === "occupied") {
      if (this.props.setpointMode === true) { // Target
        targetTempElement = <div>
                              <span style={{ fontSize: "16px" }}>Target Temperature: </span>
                              <input style={{ fontSize: "16px" }} onChange={this.handleTempChange} value={this.props.temp_set} name="0" type="number"/>
                              <span style={{padding: "8px"}}>
                                <MyToolTip>
                                  <div>Cool setpoint will be this value + 2. Heat setpoint will be this value - 2.</div>
                                </MyToolTip>
                              </span>
                            </div>
      }
      else {
        targetTempElement = <div>
                              <span style={{ fontSize: "16px" }}>Heat Setpoint: </span>
                              <input style={{ fontSize: "16px" }} onChange={this.handleHeatChange} value={this.props.heat_set} name="1" type="number"/>
                              <span style={{ fontSize: "16px" }}> Cool Setpoint: </span>
                              <input style={{ fontSize: "16px" }} onChange={this.handleCoolChange} value={this.props.cool_set} name="1" type="number"/>
                            </div>
      }
    }
    else if (this.props.occupied === "unoccupied") {
      //targetTempElement = <div></div>
      targetTempElement = <div>
                            <span style={{ fontSize: "16px" }}>Heat Setpoint: </span>
                            <input style={{ fontSize: "16px" }} onChange={this.handleHeatChange} value={this.props.heat_set} name="1" type="number"/>
                            <span style={{ fontSize: "16px" }}> Cool Setpoint: </span>
                            <input style={{ fontSize: "16px" }} onChange={this.handleCoolChange} value={this.props.cool_set} name="1" type="number"/>
                          </div>
    }

    return (
      <div>
        <h2>Period {this.props.index + 1 /*Adding 1 so we don't display our 0-indexed array to the user.*/}</h2>
        <div><span style={{ fontSize: "16px" }}>Start Time: </span><input style={{ fontSize: "16px" }} onChange={this.handleTimeChange} value={this.props.start_time} name="0" type="time"/></div>
        <br/>
        <div>
          <span style={{ fontSize: "16px" }}>Occupancy: </span>
          <ToggleButtonGroup type="radio" name="options-occupancy" horizontal="true" onChange={this.handleOccupiedChange} defaultValue={this.props.occupied}>
            <ToggleButton style={{ fontSize: "16px" }} value="occupied">Occupied</ToggleButton>
            <ToggleButton style={{ fontSize: "16px" }} value="unoccupied">Unoccupied</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <br/>
        *Heat and Cool setpoints must be 4 degrees apart
        {targetTempElement}
        <br/>
      </div>
    )
  }
}

export default ScheduleWizardPeriod;
