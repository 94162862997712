import React, {Component} from 'react';
import {Row, Col, Button, Modal} from 'react-bootstrap'

//User defined components
import Spinner from '../../indicators/spinner.js';
import api from '../../util/api.js';

const { deleteSite } = api

class SiteDeletionModal extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            apiCalling: false,
            failCalling: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        console.log("sitedeletionmodal nextprop", nextProps);
        
      }
    
    resetState = () => {
        this.setState({ //Set state back to default.
          apiCalling: false,
          failCalling: false,
          show: false,
        });
        this.props.handleHideModal();
    }

    deleteSite = (e) => {
        console.log("deleting site: ", this.props.siteName, this.props.siteId);
        this.setState({
            apiCalling: true,
            failCalling: false,
        }, () => {
            deleteSite(this.props.siteId, this.props.siteName)
            .then((res) => {
                this.resetState();
                this.props.refreshSites();
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    apiCalling: false,
                    failCalling: true,
                });
            });
        });
        
    }
      


	render() {
        return (

            <Modal show={this.props.show/*true*/} onHide={this.resetState} bsSize="lg">
                <Modal.Header closeButton>
                <Modal.Title>Site Delete <Spinner active={ this.state.apiCalling } fail={this.state.failCalling}/></Modal.Title>
                </Modal.Header>
                <Modal.Body bsClass={'samModalBody'}>
                    <h1>Are you sure you want to delete the site: "{this.props.siteName}"?</h1>
                    <br></br>
                    <h3>Note: This does not delete the devices contained within the site, they can still be assigned to other sites in the future.</h3>
                </Modal.Body>
                <Modal.Footer>

                <Button onClick={this.resetState}>Cancel</Button>
                <Button onClick={this.deleteSite}>Delete</Button>
                
                </Modal.Footer>
            </Modal>
        );
    }
}

export default SiteDeletionModal;