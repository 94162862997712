import React, { Component } from 'react'
import cn from 'classnames'
import { matchPath } from 'react-router'
import childrenWithProps from '../childrenWithProps'
import SharedState from '../../components/SharedState'

//Custom class by Chase that allows for submenus within a groupmenu.
class GroupSubMenuItem extends Component {
  constructor(props) {
    super(props)
    this.state = { active: false }

    //Bind context to functions
    //this.toggleActive.bind(this);
  }

  toggleActive = () => {
    console.log()
    //this.state.active = !this.state.active;
    this.setState({
      active: !this.state.active,
    })
  }

  render() {
    const isActive = false
    const { icon, title, shared, children, to, isActiveSection, toggle } = this.props
    return (
      //CHASE EDIT: Added conditional so if no icon is provided it won't space forward the title*
      <li className={cn(this.state.active)}>
        <a onClick={this.toggleActive}>
          <i className={icon ? `fa fa-${icon}` : ''}></i> {title}
          <span className='fa fa-chevron-down'></span>
        </a>
        <ul className='nav child_menu' style={{ display: this.state.active ? 'block' : 'none' }}>
          {/* childrenWithProps(children, { isActive: isActive }) */}
          {this.props.children}
        </ul>
      </li>
    )
  }
}

export default GroupSubMenuItem
