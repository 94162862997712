import React, { Component } from 'react';
import _ from 'lodash';
import { Button, DropdownButton, MenuItem } from 'react-bootstrap';
import ScheduleVert from '../schedules/schedulevert.js'
import Spinner from '../../indicators/spinner.js'
import CommandSpinner from '../../indicators/commandspinner.js';
// import HolidaySchedulesMenuList from "../schedules/holidayschedulesmenulist.js";    
import HolidayScheduleEventsWrapperfrom from "./holidayscheduleeventswrapper.js";

import api from '../../util/api.js';

const { axiosGetThermostatHolidayEventsPromise, postCreateThermostatHolidayScheduleEvent } = api;

class ChangeHolidayScheduleEvents extends React.Component {
  constructor() {
    super();

    this.state = {
      currSelectedSchedule: null,
      thermostatHolidayScheduleEvents: [],
      activeCommandId: '',
      didApiFetch: false,
    }
  }

  componentDidMount() {
    this.getHolidayScheduleEvents();
  }

  getHolidayScheduleEvents = () => {
    this.setState({
      apiCalling: true,
    });
    console.log("holiday siteID and portalDeviceID: ", this.props.siteID, this.props.portalDeviceID);
    axiosGetThermostatHolidayEventsPromise(this.props.siteID, this.props.portalDeviceID)
    .then(thermostatHolidayScheduleEvents => {
        console.log("thermostatHolidayScheduleEvents:", thermostatHolidayScheduleEvents);
        this.setState({
          thermostatHolidayScheduleEvents: thermostatHolidayScheduleEvents,
          filteredHolidayScheduleObjs: thermostatHolidayScheduleEvents,
          apiCalling: false,
          didApiFetch: true,
        });
    })
    .catch((error) => {
      console.log("Error getting thermostat schedules events");
      console.log(error);
      this.setState({
        apiCalling: false,
      });
    });
  }

  // handleScheduleSubmit = (e) => {
  //   if(this.state.currSelectedSchedule == null){
  //     alert("You must select a schedule to submit.");
  //     return;
  //   }
  //   console.log("this.state.user: ", this.state.user);
  //   this.setState({ apiCalling: true });
    
  //   postCreateThermostatHolidayScheduleEvent(this.props.siteID, this.props.portalDeviceID)
  //   .then(res => {
  //     console.log(res);

  //     this.setState({
  //       apiCalling: false,
  //     });
  //   });
  // }

  

  clearActiveCommandId = () => {
    this.setState({
      activeCommandId: "",
    })
  }

  render() {
    return (
      <center>
        {/* <DropdownButton title="Schedules" style={{marginRight: "5px"}}>
          {scheduleMenuItemElements}
        </DropdownButton> */}
        
        {/* <CommandSpinner
          commandId={ this.state.activeCommandId } clearActiveCommandId={this.clearActiveCommandId}
          siteID={this.props.siteID} deviceID={this.props.deviceID}
          getLiveDataAfterTime={this.props.getLiveDataAfterTime}
          handleSchedulePosted={this.getSchedules}
        /> */}
        <HolidayScheduleEventsWrapperfrom 
        siteID={this.props.siteID}
        scheduleObjs={_.map(this.state.filteredHolidayScheduleObjs, 'holidaySchedule')}
        handleRefreshSchedules={this.getHolidayScheduleEvents}
        didApiFetch={this.state.didApiFetch}/>
        
      </center>
    )
  }
}

export default ChangeHolidayScheduleEvents;
