import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Switch from 'react-switch';
import MyToolTip from '../../indicators/mytooltip.js'
import Spinner from '../../indicators/spinner.js'
import CommandSpinner from '../../indicators/commandspinner.js'
import api from '../../util/api.js';

const { setFan } = api;
import constants from '../../../config/constants.js'

class ChangeFan extends React.Component {
  constructor() {
      super();
      this.state = {
        fanSwitch: false,
        apiCalling: false,
        activeCommandId: "",
        hasBeenUsed: false,
      }
  }

  componentDidUpdate(prevProps) {
    //  Three conditions:
    //    - Switch hasn't been used.
    //    - LiveData is coming down as a prop
    //    - LiveData has changed
    //  The outcome:
    //    - Switch state is decided by that live data (i.e. device state)
    if (!this.state.hasBeenUsed && this.props.deviceLiveData &&
      this.props.deviceLiveData != prevProps.deviceLiveData) {
      if (this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.FAN_MODE] === "AUTO") {
        this.setState({ fanSwitch: false });
      }
      else if (this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.FAN_MODE] === "ON") {
        this.setState({ fanSwitch: true });
      }
    }
  }

  handleFanChange = (value) => {
    this.setState({
      hasBeenUsed: true
    }, () => {
      this.setState({
        fanSwitch: !this.state.fanSwitch
      });
    });
  }

  handleFanSubmit = (e) => {
    this.setState({ hasBeenUsed: true });
    //API calll
    this.setFan(this.state.fanSwitch);
  }

  setFan = (bool) => {
    this.setState({ apiCalling: true });
    setFan(this.props.siteID, this.props.deviceID, bool)
    .then(res => {
      this.setState({
        apiCalling: false,
      })
      return res;
    })
    .then(res => {
      this.setState({
        activeCommandId: res.data.commandId
      });
    });
  }

  clearActiveCommandId = () => {
    console.log("ChangeFan: Cleared active command ID.");
    this.setState({
      activeCommandId: "",
    })
  }

  render() {
    let fanSwitchText = this.state.fanSwitch ? "ON" : "AUTO";

    return (
      <div>
      <center>
        <Switch
          onChange={this.handleFanChange}
          checked={this.state.fanSwitch}
          onColor="#86d3ff"
          onHandleColor="#2693e6"
          handleDiameter={30}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={20}
          width={48}
          className="react-switch"
          id="material-switch"
        />
        <br/>
        <h1>{fanSwitchText}</h1>
        <br/>
        <Spinner active={ this.state.apiCalling } />
        <CommandSpinner
          commandId={ this.state.activeCommandId } clearActiveCommandId={this.clearActiveCommandId}
          siteID={this.props.siteID} deviceID={this.props.deviceID}
          getLiveDataAfterTime={this.props.getLiveDataAfterTime}
        />
        <Button onClick={this.handleFanSubmit} type="button" className="btn btn-default">Submit</Button>
      </center>
      </div>
    )
  }
}

export default ChangeFan;
