import React, { Component } from 'react'

class XcContent extends Component {
  render () {
    const { shared } = this.props;
    let { children } = this.props;
    const { collapsed } = shared.getState();

    if (collapsed) {
      // clone and replace the children, giving them a prop to indicate
      //  that we are "collapsed".
      // They'll use this to hide setting elements, rather than readings.
      let additionalProps = { hideContent: true };
      children = React.Children.map(children, c => React.cloneElement(c, additionalProps))
    }

    return (
      <div className="x_content">
        { children }
      </div>
    )
  }
}

export default XcContent;
