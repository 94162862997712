import React, { Component } from 'react';
import { Row, Col, Button, Modal, ButtonToolbar, ToggleButtonGroup, ToggleButton, Tooltip, OverlayTrigger } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import Slideshow from './slideshow.js';
import ScheduleWizardDaySlide from './scheduleWizardDaySlide.js';
import Spinner from '../../indicators/spinner.js'

import api from '../../util/api.js';

const { postHolidaySchedule } = api;

class ScheduleWizardHoliday extends React.Component {

    constructor(){
        super();

        this.state = {
            show: false,
            step: 0,
            scheduleObj: {
                name: '',
                days: [],
                isHoliday: true
            },
            apiCalling: false,
            activeCommandId: '',
            totalWizardLength: 5,
            currentMoment: new Date(),
            startDatetime: undefined,
            endDatetime: undefined,
        }
    }

    componentDidMount() {
      //  Point of this is to call scheduleFactory
      //    using the parameters that were just defined in constructor()
      console.log("componentDidMount() called");
      this.setState({
        setpointMode: false, // true = target, false = specify setpoints
        scheduleObj: {
          name: "New Schedule",
          schedule_config: "7",
          periods_per_day: 2,
          schedule: this.scheduleFactory(),
        }
      });
    }

    scheduleFactory = () => {
      let numDays = 1;

      let numPeriods = 2;
      console.log("scheduleFactory called, numPeriods: ", numPeriods, " numDays: ", numDays);
      let schedule = new Array();
      for (let i = 0; i < numDays; i++) {
        schedule[i] = new Array();
        for (let j = 0; j < numPeriods; j++) {
          schedule[i][j] = { // Array of 2 periods
            start_time: "00:00",
            temp_set: 70,
            cool_set: 72,
            heat_set: 68,
            occupied: "occupied",
          }
        }
      }
      return schedule;
    }

    // In state, our schedule object is an array of arrays.
    //  The x dimension represents the day. The y dimension represents a period.
    // This function strips away the x dimension and returns an array of periods
    //  for a given day.
    scheduleObjToCoolDay = (dayIndex) => {
      let result = [];
      let schedule = this.state.scheduleObj.schedule;
      let numPeriods = schedule[dayIndex].length;
      for (let i = 0; i < numPeriods; i++) {
        let target_temp = schedule[dayIndex][i].temp_set;
        let temp_set = schedule[dayIndex][i].cool_set;
        if (schedule[dayIndex][i].occupied === "unoccupied") {
          temp_set = 90;
        }
        let tempObj = {
          start_time: schedule[dayIndex][i].start_time,
          target_temp: -1,
          temp_set: temp_set,
          occupied: schedule[dayIndex][i].occupied,
        }
        // if (schedule[dayIndex][i].occupied === "unoccupied") {
        //   schedule[dayIndex][i].cool_set = 90;
        //   schedule[dayIndex][i].heat_set = 48;
        // }
        result.push(tempObj);
      }
      return result;
    }

    scheduleObjToHeatDay = (dayIndex) => {
      let result = [];
      let schedule = this.state.scheduleObj.schedule;
      let numPeriods = schedule[dayIndex].length;
      for (let i = 0; i < numPeriods; i++) {
        let target_temp = schedule[dayIndex][i].temp_set;
        let temp_set = schedule[dayIndex][i].heat_set;
        if (schedule[dayIndex][i].occupied === "unoccupied") {
          temp_set = 48;
        }
        let tempObj = {
          start_time: schedule[dayIndex][i].start_time,
          target_temp: -1,
          temp_set: temp_set,
          occupied: schedule[dayIndex][i].occupied,
        }
        result.push(tempObj);
      }
      return result;
    }
  
    resetState = () => {
      console.log("resetState called");
      this.setState({ //Set state back to default.
        show: false,
        step: 0,
        apiCalling: false,
        activeCommandId: '',
        totalWizardLength: 4,
        currentMoment: new Date(),
        startDatetime: undefined,
        endDatetime: undefined,
        setpointMode: false, // true = target, false = specify setpoints
        scheduleObj: {
          name: "New Schedule",
          schedule_config: "7",
          periods_per_day: 2,
          schedule: this.scheduleFactory(),
        }
      }, () => {
          // this.setState({
          //   scheduleObj: {
          //     name: this.state.scheduleObj.name,
          //     schedule_config: "7",
          //     periods_per_day: 2,
          //     schedule: this.scheduleFactory(),
          //   }
          // });
      });

      // this.state = {
      //     show: false,
      //     step: 0,
      //     scheduleObj: {
      //         name: '',
      //         days: [],
      //         isHoliday: true
      //     },
      //     apiCalling: false,
      //     activeCommandId: '',
      //     totalWizardLength: 4,
      //     currentMoment: new Date(),
      //     startDatetime: new Date(),
      // }
    }

    handlePrevious = (e) => {
      let step = this.state.step;
      let increment = 1;
      this.setState({
        step: this.state.step - increment
      })
    }

    handleNext = (e) => {
      console.log("handleNext clicked");
      let step = this.state.step;
      let increment = 1;

      if(step == 4 && this.state.scheduleObj.name == ''){
        alert("You cannot use an empty schedule name");
        return;
      }
      console.log("handleNext clicked, step: ", step);
      if (step < this.state.totalWizardLength - 1) {
        console.log("Setting step to: ", this.state.step + increment);
        this.setState({
          step: this.state.step + increment
        });
      }
      else {
        this.complete();
      }
    }

    complete = () => {
      let coolSchedule = {};
      let heatSchedule = {};
      if (this.state.scheduleObj.schedule_config == "5+1+1") {
        coolSchedule["mo-fr"] = this.scheduleObjToCoolDay(0);
        coolSchedule["sa"] = this.scheduleObjToCoolDay(1);
        coolSchedule["su"] = this.scheduleObjToCoolDay(2);
        heatSchedule["mo-fr"] = this.scheduleObjToHeatDay(0);
        heatSchedule["sa"] = this.scheduleObjToHeatDay(1);
        heatSchedule["su"] = this.scheduleObjToHeatDay(2);
      }
      else if (this.state.scheduleObj.schedule_config == "7") {
        //Cool
        coolSchedule["mo"] = this.scheduleObjToCoolDay(0);
        coolSchedule["tu"] = this.scheduleObjToCoolDay(0);
        coolSchedule["we"] = this.scheduleObjToCoolDay(0);
        coolSchedule["th"] = this.scheduleObjToCoolDay(0);
        coolSchedule["fr"] = this.scheduleObjToCoolDay(0);
        coolSchedule["sa"] = this.scheduleObjToCoolDay(0);
        coolSchedule["su"] = this.scheduleObjToCoolDay(0);
        //Heat
        heatSchedule["mo"] = this.scheduleObjToHeatDay(0);
        heatSchedule["tu"] = this.scheduleObjToHeatDay(0);
        heatSchedule["we"] = this.scheduleObjToHeatDay(0);
        heatSchedule["th"] = this.scheduleObjToHeatDay(0);
        heatSchedule["fr"] = this.scheduleObjToHeatDay(0);
        heatSchedule["sa"] = this.scheduleObjToHeatDay(0);
        heatSchedule["su"] = this.scheduleObjToHeatDay(0);
      }

      let resultObj = {
        name: this.state.scheduleObj.name,
        // targetTemp: this.state.
        t_units: "F",
        periods_per_day: 2,
        schedule_config : "7",
        coolSchedule: coolSchedule,
        heatSchedule: heatSchedule,
        returnCoolSchedule: coolSchedule,
        returnHeatSchedule: heatSchedule,
        //isHoliday: true,
        startDatetime: this.state.startDatetime,
        endDatetime: this.state.endDatetime,
      };

      console.log("create holiday schedule resultObj: ", resultObj);
      
      console.log(resultObj);
       this.postSchedule(resultObj)
      .then(() => {
        this.props.hide();
        this.props.donePosting();
        this.resetState();
      });
      
    }

    postSchedule = (scheduleObj) => {
      this.setState({ apiCalling: true });
      return postHolidaySchedule(scheduleObj)
      .then(response => {
        this.setState({ apiCalling: false });
        console.log(response);
        if(response && response.success === true){
          console.log("API: New schedule submitted successfully.");
        }
        else{
          console.log("API: Failed to submit new schedule.")
        }
      })
      .catch((err) => {
        console.error(err);
      });
    }

    handleNameChange = (e) => {
      //if (e.target.value === '') return;

      this.setState({
        scheduleObj: {
            name: e.target.value,
            schedule_config: this.state.scheduleObj.schedule_config,
            periods_per_day: this.state.scheduleObj.periods_per_day,
            schedule: this.state.scheduleObj.schedule,
        }
      });
    }

    // Called in config slide when the user chooses either "5+1+1" or "7".
    handleConfigChange = (e) => {
      // The order of these statements matters. That's why there's a bit of callback hell.
      this.setState({
        scheduleObj: {
          name: this.state.scheduleObj.name,
          schedule_config: e,
          periods_per_day: this.state.scheduleObj.periods_per_day,
          schedule: this.state.scheduleObj.schedule,
        }
      }, () => {
        //  2. Update the structure of the underlying schedule obj in state.
        this.setState({
          scheduleObj: {
            name: this.state.scheduleObj.name,
            schedule_config: this.state.scheduleObj.schedule_config,
            periods_per_day: this.state.scheduleObj.periods_per_day,
            schedule: this.scheduleFactory(),
          }
        }, () => { console.log(this.state); });
      });
    }

    handlePeriodChange = (e) => {
      // The order of these statements matters. That's why there's a bit of callback hell.
      //  1. Set the new periods
      this.setState({
        scheduleObj: {
          name: this.state.scheduleObj.name,
          schedule_config: this.state.scheduleObj.schedule_config,
          periods_per_day: e,
          schedule: this.state.scheduleObj.schedule,
        }
      }, () => {
        //  2. Update the structure of the underlying schedule obj in state.
        this.setState({
          scheduleObj: {
            name: this.state.scheduleObj.name,
            schedule_config: this.state.scheduleObj.schedule_config,
            periods_per_day: this.state.scheduleObj.periods_per_day,
            schedule: this.scheduleFactory(),
          }
        }, () => { console.log(this.state); });
      });
    }

    handlePeriodTimeChange = (targetValue, periodIndex, dayIndex) => {
      let schedule = this.state.scheduleObj.schedule;
      schedule[dayIndex][periodIndex].start_time = targetValue;

      this.setState({
        scheduleObj: {
          name: this.state.scheduleObj.name,
          schedule_config: this.state.scheduleObj.schedule_config,
          periods_per_day: this.state.scheduleObj.periods_per_day,
          schedule: schedule,
        }
      });
    }

    handlePeriodTempChange = (targetValue, periodIndex, dayIndex) => {
      let schedule = this.state.scheduleObj.schedule;
      schedule[dayIndex][periodIndex].temp_set = targetValue;
      schedule[dayIndex][periodIndex].cool_set = targetValue + 2;
      schedule[dayIndex][periodIndex].heat_set = targetValue - 2;

      this.setState({
        scheduleObj: {
          name: this.state.scheduleObj.name,
          schedule_config: this.state.scheduleObj.schedule_config,
          periods_per_day: this.state.scheduleObj.periods_per_day,
          schedule: schedule,
        }
      });
    }

    handleCoolSetChange = (targetValue, periodIndex, dayIndex) => {
      let schedule = this.state.scheduleObj.schedule;
      schedule[dayIndex][periodIndex].cool_set = targetValue;

      this.setState({
        scheduleObj: {
          name: this.state.scheduleObj.name,
          schedule_config: this.state.scheduleObj.schedule_config,
          periods_per_day: this.state.scheduleObj.periods_per_day,
          schedule: schedule,
        }
      }, () => { console.log(this.state); });
    }

    handleHeatSetChange = (targetValue, periodIndex, dayIndex) => {
      let schedule = this.state.scheduleObj.schedule;
      schedule[dayIndex][periodIndex].heat_set = targetValue;

      this.setState({
        scheduleObj: {
          name: this.state.scheduleObj.name,
          schedule_config: this.state.scheduleObj.schedule_config,
          periods_per_day: this.state.scheduleObj.periods_per_day,
          schedule: schedule,
        }
      }, () => { console.log(this.state); });
    }

    handlePeriodOccupiedChange = (targetValue, periodIndex, dayIndex) => {
      let schedule = this.state.scheduleObj.schedule;
      schedule[dayIndex][periodIndex].occupied = targetValue;

      this.setState({
        scheduleObj: {
          name: this.state.scheduleObj.name,
          schedule_config: this.state.scheduleObj.schedule_config,
          periods_per_day: this.state.scheduleObj.periods_per_day,
          schedule: schedule
        }
      });
    }

    handleSetpointModeChange = (usingTargetTemp) => {
      let schedule = this.state.scheduleObj.schedule;
      for (let i = 0; i < schedule.length; i++) {
        for (let j = 0; j < schedule[i].length; j++) {
          if (usingTargetTemp) {
            schedule[i][j].temp_set = 70;
            schedule[i][j].cool_set = 72;
            schedule[i][j].heat_set = 68;
          }
          else {
            schedule[i][j].temp_set = -1;
            schedule[i][j].cool_set = 72;
            schedule[i][j].heat_set = 68;
          }
        }
      }

      this.setState({
        setpointMode: usingTargetTemp,
        scheduleObj: {
          name: this.state.scheduleObj.name,
          schedule_config: this.state.scheduleObj.schedule_config,
          periods_per_day: this.state.scheduleObj.periods_per_day,
          schedule: schedule
        }
      }, () => { console.log(this.state); });
    }
    

    render(){

        let nextButtonLabel = "Next";
        if (this.state.step === this.state.totalWizardLength - 1) { //This would indicate that our user is on the last slide
            nextButtonLabel = "Create";
        }

        let previousButtonHide = {};
        if (this.state.step === 0) {
            previousButtonHide = {
                display: "none"
            }
        }

        let scheduleSlidesList = !this.state.scheduleObj.schedule ? [] : this.state.scheduleObj.schedule.map((day, index) => {
            return (
                <ScheduleWizardDaySlide key={index} index={index} schedule={this.state.scheduleObj.schedule[index]}
                isHoliday="true"
                prevSchedules={this.state.scheduleObj.schedule.slice(0,index)}
                numPeriods={this.state.scheduleObj.periods_per_day}
                dayString="Monday"
                timeChange={this.handlePeriodTimeChange} tempChange={this.handlePeriodTempChange}
                coolSetChange={this.handleCoolSetChange} heatSetChange={this.handleHeatSetChange}
                occupiedChange={this.handlePeriodOccupiedChange} setpointMode={this.state.setpointMode}/>
            );
        });
        return(
            <Modal show={this.props.show} onHide={()=>{this.props.hide();this.resetState();}} bsSize="lg">
            <Modal.Header closeButton>
                <Modal.Title>Holiday Schedule Creator <Spinner active={ this.state.apiCalling } /></Modal.Title>
            </Modal.Header>
            <Modal.Body bsClass={'samModalBody'}>
                <Slideshow step={this.state.step}>
                  {/* WELCOME */}
                  <center className="centerTable">
                      <h1 className="tableCell">Welcome to the Holiday Schedule Creator! This wizard will allow you to create a named custom schedule to be run on specific date ranges for selected thermostats.</h1>
                  </center>
                  <center className="centerTable">
                      <div className="tableCell">
                      <h1>How about a name for this holiday schedule?</h1>
                      <br/>
                      <div>
                          <input style={{ fontSize: "16px", marginBottom: "10px", width: "40%"  }} onChange={this.handleNameChange} value={this.state.scheduleObj.name} type="text"/>
                      </div>
                      </div>
                  </center>
                  {/* Schedule */}
                  {scheduleSlidesList[0]}
                  <center className="centerTable">
                      <h1>What date range would you like this schedule to run?</h1>
                      <div style={{width: "50%", marginBottom: "10px"}}>
                        <DatePicker
                          selectsRange={true}
                          selected={undefined}
                          startDate={this.state.startDatetime}
                          endDate={this.state.endDatetime}
                          placeholderText="Click to select a date"
                          monthsShown={2}
                          maxDate={this.state.startDatetime == undefined ? undefined : ((new Moment(this.state.startDatetime)).add('days', 365)).toDate()}
                          onChange={(update) => {
                            console.log("onChange table update is: ", update);
                            //setDateRange(update);
                            
                            this.setState({
                              startDatetime: update[0],
                              ...(update[1] != null && {endDatetime: update[1]})
                            }, () => {


                              //Second item was selected, draw this date
                              // if(update[1] !== null) {
                              //   this.handleTimeChange(this.state.startDatetime, this.state.endDatetime);
                              // }
                            });
                            //console.log("update: ", update);
                          }}
                          isClearable={true}
                        />
                      </div>
                  </center>

                  {/* Return Schedule Step */}

                  {/* <center className="centerTable">
                      <h1>The thermostat will return to the site master schedule: ?</h1>
                      <div style={{width: "50%", marginBottom: "10px"}}>
                        
                      </div>
                  </center> */}
                
                  {/* Final Step */}
                  <center className="centerTable">
                      <br></br>
                      <Row>
                        <h1 className="tableCell">If you are ready to create schedule "<b>{this.state.scheduleObj.name}</b>" press the "Create" button. Afterwards you can access and assign this schedule from the 'Control' page of one of your thermostats.</h1>
                      </Row>
                      <br></br>
                      <Row>
                        <h2 className="tableCell">When a holiday schedule is finished running for any thermostat which it is assigned to the thermostat will return its site's master schedule.</h2>
                      </Row>
                      <br></br>
                      
                      
                  </center>
                </Slideshow>
            </Modal.Body>
            <Modal.Footer>
                {/*<Button onClick={this.handleClose}>Close</Button>*/}
                <Button onClick={this.handlePrevious} style={previousButtonHide}>Previous</Button>
                <Button disabled={this.state.step == 3 && (this.state.startDatetime == undefined || this.state.endDatetime == undefined)}  onClick={this.handleNext}>{nextButtonLabel}</Button>
            </Modal.Footer>
            </Modal>
        );
    }
}

export default ScheduleWizardHoliday;