import '../../../css/xcspeccustom.css'
import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Route } from 'react-router'
import { XPanel, PageTitle } from '../../components'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

//User components
import DashboardSiteTable from '../../components/Dashboard/DashboardSiteTable'
import Spinner from '../../indicators/spinner.js'
import api from '../../util/api.js'

const { fetchUserPromise, patchSiteMasterScheduleId } = api
//CSS

var apiUrl = 'https://jdpy43j0u7.execute-api.us-west-2.amazonaws.com'

//High level component that creats the row / collumn for the site tables, and creates XPanel's for each site
class Dashboard extends React.Component {
  static isPrivate = true

  constructor(props) {
    super(props)
    console.log('dashboard props: ', props)
    this.state = {
      //user: props.user
    }
    
    if (!props.user) {
       this.getUserPromise()
    } else {
      // eslint-disable-next-line
      props.user !== undefined ? this.getUserPromise(props.user._id) : null // eslint-disable-line
    }
  }

  getUserPromise(userId) {
    console.log('get urser')
    let requestStr = apiUrl + '/user'
    fetchUserPromise()
      .then((res) => res.json())
      .then((response) => {
        this.setState({ didApiFetch: true })
        console.log('called get user promise()')
        if (response && response.success === true && response.data) {
          console.log('FETCHED user!')
          //console.log(response.data.user.thermostatschedules);

          this.setState(
            {
              user: response.data.user,
            },
            () => {},
          )
        }
      })
      .catch((err) => {
        console.log('got user promise err', err)
      })
  }

  submitPatchSiteMasterScheduleId(siteId) {
    console.log('submitPatchSiteMasterScheduleId newMasterScheduleId: ', this.state.newMasterScheduleId)
    patchSiteMasterScheduleId(siteId, this.state.newMasterScheduleId)
      .then((response) => {
        //Successful post bulk commands
        console.log('bulk commands response:')
        console.log(response)
        if (response && response.success === true) {
          console.log('success')
          console.log(response)
          this.setState({
            apiCalling: false,
            failCalling: false,
          })
        }
        //Unsuccessful
        else {
          console.log('failure')
          alert('Failed to set site master schedule.')
          this.setState({
            apiCalling: false,
            failCalling: true,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        alert(err.message)
        this.setState({
          apiCalling: false,
          failCalling: true,
        })
      })
  }

  componentWillReceiveProps(nextProps) {
    //this.setState({user: nextProps.user});
    //this.getUserPromise(nextProps.user._id);
  }

  selectOption(ev) {
    console.log('selectOption: ', ev.target.value)
    this.setState({
      newMasterScheduleId: ev.target.value,
    })
  }

  render() {
    {
      console.log('state user in render is: ', this.state.user)
    }
    //Only generate tables if the user has been loaded

    if (this.state.user && this.state.user.resolvedPortalSites) {
      //First we check if this is the dashboard for a specific site, if so, we only generate / load data for the tables for that specific site
      if (this.props.match.params.siteID)
        var sites = this.state.user.resolvedPortalSites.filter(
          (resolvedPortalSite) => resolvedPortalSite._id === this.props.match.params.siteID,
        )
      //Home dashboard, show tables for all sites
      else var sites = this.state.user.resolvedPortalSites

      var thermostatschedules = this.state.user.thermostatschedules

      //Generate jsx for each site and its corresponding tables
      var siteTables = sites.map((resolvedPortalSite, i) => {
        //Create an xpanel and table for each site
        return (
          <XPanel key={this.state.user._id + i}>
            <XPanel.Title
              title={(() => {
                return (
                  <Row style={{ display: 'flex' }}>
                    <Col md={6} sm={12} xs={12} style={{ width: '100%' }}>
                      <div>
                        {resolvedPortalSite.name}
                        <Link to={`/site/${resolvedPortalSite._id}/sitemanager`}>
                          <Button className={'btn-primary'} style={{ marginLeft: '10px' }}>
                            Manage Devices
                          </Button>
                        </Link>
                      </div>
                    </Col>
                    <Col md={6} sm={12} xs={12} style={{ display: 'none', width: '100%' }}>
                      Current Master Schedule:
                      <select
                        id='schedule_button'
                        style={{ height: 'fit-content', width: 'fit-content' }}
                        className='col-sm-12 custom-select custom-select-sm'
                        onChange={(e) => {
                          this.selectOption(e)
                        }}
                      >
                        <option value='none' selected disabled hidden>
                          Select an Option
                        </option>
                        {thermostatschedules !== undefined ? (
                          thermostatschedules.map((schedule, index) => {
                            return (
                              <option
                                key={schedule._id + index}
                                value={schedule._id}
                                scheduleid={schedule._id}
                                selected={schedule._id == resolvedPortalSite.masterScheduleId}
                              >
                                {schedule.name}
                              </option>
                            )
                          })
                        ) : (
                          <option value=''>No Schedules</option>
                        )}
                        {/* <option value="">No Schedules</option> */}
                      </select>
                      <Button
                        className={'btn-primary'}
                        style={{ marginLeft: '10px' }}
                        onClick={(e) => {
                          this.submitPatchSiteMasterScheduleId(resolvedPortalSite._id)
                        }}
                      >
                        Set Master Schedule
                      </Button>
                    </Col>
                  </Row>
                )
              })()}
            ></XPanel.Title>
            <XPanel.Content>
              <Row></Row>
              <DashboardSiteTable
                thermostatschedules={this.state.user.thermostatschedules}
                resolvedPortalSite={resolvedPortalSite}
                key={resolvedPortalSite._id + 'dash-table'}
              />
            </XPanel.Content>
          </XPanel>
        )
      })
    } else var siteTables = undefined
    return (
      <section>
        <PageTitle title='' />

        <Row>
          <Col xs={12}>{siteTables}</Col>
        </Row>
      </section>
    )
  }
}

export default Dashboard
