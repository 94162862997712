import React, {Component} from 'react';
import {Row, Col, Button, Modal} from 'react-bootstrap'

//User defined components
import Spinner from '../../indicators/spinner.js';
import api from '../../util/api.js';
const { createSite } = api


class SiteCreationModal extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            apiCalling: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        console.log("sitecreationmodal nextprop", nextProps);
        
      }
    
    resetState = () => {
        this.setState({ //Set state back to default.
          apiCalling: false,
          failCalling: false,
          show: false,
          step: 0,
          setpointMode: true,
        });
        this.props.handleHideModal();
    }

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value,
        });
    }

    createSite = (e) => {
        console.log("create site, this.props: ", this.props);
        this.setState({
            apiCalling: true,
            failCalling: false,
        }, () => {
            createSite(this.state.name)
            .then(() => {
                this.resetState();
                this.props.refreshSites();
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    apiCalling: false,
                    failCalling: true,
                });
            });
        });
        
    }
      


	render() {
        return (

            <Modal show={this.props.show/*true*/} onHide={this.resetState} bsSize="lg">
                <Modal.Header closeButton>
                <Modal.Title>Site Creator</Modal.Title>
                <Spinner active={ this.state.apiCalling } fail={this.state.failCalling}></Spinner>
                </Modal.Header>
                <Modal.Body bsClass={'samModalBody'}>
                    <h3>Please enter a name for the site:</h3>
                    <span style={{ fontSize: "16px" }}>Name: </span>
                    <input style={{ fontSize: "16px" }} onChange={this.handleNameChange} name="name" type="text"/>
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={this.createSite}>Create</Button>
                
                </Modal.Footer>
            </Modal>
        );
    }
}

export default SiteCreationModal;