import React, { Component } from 'react';
import { Row, Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import {XPanel, PageTitle} from '../../components';
import MyToolTip from '../../indicators/mytooltip.js'
import Days from './days.js';
import Spinner from '../../indicators/spinner.js';
import axios from 'axios';

import api from '../../util/api.js';

var mapToFormat = require('./mapToFormat.js');

const { fetchUserPromise, deleteSchedule } = api

class ScheduleHoriz extends React.Component {
  
  constructor() {
    super();
    this.state = {
      apiCalling: false,
      failCalling: false,
    }
  }

  handleDelete = (e) => {
      this.setState({ 
        failCalling: false,
        apiCalling: true,
       });
      deleteSchedule(this.props.scheduleObj._id, this.props.scheduleObj.name)
      .then((res) => {
        console.log(res);
        this.props.handleDelete();
        this.setState({
          apiCalling: false,
          failCalling: false,
        });
      })
      .catch((err) => {
        console.log("delete schedule error");
        console.log(err);
        this.setState({
          apiCalling: false,
          failCalling: true,
        });
      });
  }

  getDeleteSiteButtonJsx = (site) => {
    return(
      <div>
        {this.props.scheduleObj.name}
        <Button className={'btn-danger'} style={{marginLeft: "10px"}} onClick={this.handleDelete}>DELETE</Button>
      </div>
    );
  }

  render() {
    return (
      <Row style={{ paddingBottom: '32px' }} md={ 12 } sm={ 12 } xs={ 12 }>
        <XPanel>
          <XPanel.Title title={this.getDeleteSiteButtonJsx()}></XPanel.Title>
          <XPanel.Content>
          
          <Col md={ 12 } sm={ 12 } xs={ 12 } style={{width: "100%"}}>
            <Days scheduleObj={this.props.scheduleObj} />
          </Col>
          <Col md={ 1 } sm={ 1 } xs={ 1 }>
            
            <Spinner active={this.state.apiCalling} fail={this.state.failCalling}/>
          </Col>

          </XPanel.Content>
        </XPanel>
        
      </Row>
    )
  }

}

export default ScheduleHoriz;
