import React, { Component } from 'react'

class Footer extends Component {
  render () {
    return (
        <footer>
          <div className="clearfix"></div>
        </footer>
    )
  }
}

export default Footer