import React, { Component } from 'react'
import { Table } from 'react-bootstrap'

class DashboardSiteDeviceCellDatum extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.children,
      classNames: '',
    }
  }

  getAlarmTooltipStringFromReactElement(reactElement) {
    try {
      return reactElement.props.children[1].props.overlay.props.children
    } catch (err) {
      // console.error(err)
    }
  }

  componentWillReceiveProps(newProps) {
    //TODO: this should probably be done a different way, but I couldn't find a better way to do it
    //it should be done another way because setState is not synchronous, so the timer's may not be 100% consistent.
    //We want these timings to be as tight as possible. Right now this appears to work fine, but a better practice can be achieved
    //This is how we check if the new value of a cell is novel.
    if (this.state.value !== newProps.children) {
      //If the value of the new data is an object, this must be the alarms value. so lets check if the alarms are novel
      if (typeof newProps.children === 'object' && typeof this.state.value === 'object') {
        if (this.state.value.type === 'i' && newProps.children.type === 'i')
          return //This means we are going from green checkmark to green checkmark, no difference so don't flash the whole cell green
        else if (this.state.value.type !== 'i' && newProps.children.type !== 'i') {
          //This means we are switching red to red, so lets check if the alarms are different
          if (
            this.getAlarmTooltipStringFromReactElement(this.state.value) ===
            this.getAlarmTooltipStringFromReactElement(newProps.children)
          )
            return //Alarm string was the same between both
        }
      }
      //Add the fade in class to fade the green background in
      this.setState({
        value: newProps.children,
        classNames: 'novel-value-fade-in',
      })
      //Add the fade out class with a timeout to fade the greenbackground out
      //TODO: might be able to accomplish this by just removing the class instead of using a separate one. this will prevent
      //the background from transitioning to white
      setTimeout(
        function () {
          this.setState({
            classNames: 'novel-value-fade-out',
          })
        }.bind(this),
        1500,
      )

      //Remove the fade out class with a timeout so that after it's faded out we remove the fade out class
      //If we don't do this then when the row is highlighted the values that have changed continue to havea  whitebackground
      //instead of the highlighted gray
      setTimeout(
        function () {
          this.setState({
            classNames: '',
          })
        }.bind(this),
        4500,
      )
    }
  }

  getCellStyle() {
    //console.log(this.props.color);
    return this.props.color !== undefined
      ? {
          color: this.props.color,
          textAlign: 'center',
        }
      : {
          textAlign: 'center',
        }
  }

  render() {
    return (
      <td className={this.state.classNames} style={this.getCellStyle()}>
        {this.state.value}
      </td>
    )
  }
}

export default DashboardSiteDeviceCellDatum
