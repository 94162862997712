import React, { Component } from 'react';
import { Row, Col, Button, Modal, ButtonToolbar, ToggleButtonGroup, ToggleButton, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Slideshow from './slideshow.js';
import ScheduleWizardDaySlide from './scheduleWizardDaySlide.js';
import Spinner from '../../indicators/spinner.js'
import ScheduleWizardHoliday from './scheduleWizardHoliday.js'

import api from '../../util/api.js';

const { postSchedule } = api;

const dayStringMap511 = ["Monday - Friday", "Saturday", "Sunday"];
const dayStringMap7 = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

function LinkWithTooltip({ id, children, href, tooltip }) {
  return (
    <OverlayTrigger
      overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
      placement="top"
      delayShow={300}
      delayHide={150}
    >
      <a href={href}>{children}</a>
    </OverlayTrigger>
  );
}

const tooltip511 = "This option allows you to program 3 schedules: one for Monday-Friday, one for Saturday, and one for Sunday.";
const tooltip7 = "This option allows you to program 7 schedules, one for each day of the week.";

class ScheduleWizard extends React.Component {
  constructor() {
    super();

    this.state = {
      apiCalling: false,
      show: false,
      showHolidayWizard: false,
      step: 0,
      setpointMode: false, // true = target, false = specify setpoints
      scheduleObj: {
        name: 'New Schedule',
        schedule_config: "7",
        periods_per_day: 2,
      },
      totalWizardLength: 10,
    }
  }

  componentDidMount() {
    //  Point of this is to call scheduleFactory
    //    using the parameters that were just defined in constructor()
    this.setState({
      setpointMode: false, // true = target, false = specify setpoints
      scheduleObj: {
        name: this.state.scheduleObj.name,
        schedule_config: "7",
        periods_per_day: 2,
        schedule: this.scheduleFactory(),
      }
    });
  }

  scheduleFactory = () => {
    let numDays;
    if (this.state.scheduleObj.schedule_config == "5+1+1") numDays = 3;
    else if (this.state.scheduleObj.schedule_config == "7") numDays = 7;

    let numPeriods = this.state.scheduleObj.periods_per_day;

    let schedule = new Array();
    for (let i = 0; i < numDays; i++) {
      schedule[i] = new Array();
      for (let j = 0; j < numPeriods; j++) {
        schedule[i][j] = { // Array of 2 periods
          start_time: "00:00",
          temp_set: 70,
          cool_set: 72,
          heat_set: 68,
          occupied: "occupied",
        }
      }
    }
    return schedule;
  }

  resetState = () => {
    console.log("resetState called");
    this.setState({ //Set state back to default.
      apiCalling: false,
      show: false,
      showHolidayWizard: false,
      step: 0,
      setpointMode: false,
      scheduleObj: {
        name: 'New Schedule',
        schedule_config: "7",
        periods_per_day: 2,
      },
      totalWizardLength: 10
    }, () => {
        this.setState({
          scheduleObj: {
            name: this.state.scheduleObj.name,
            schedule_config: "7",
            periods_per_day: 2,
            schedule: this.scheduleFactory(),
          }
        });
    });
  }

  /*
  //Skip Config Change:
    this.setState({
      setpointMode: false,
      scheduleObj: {
        schedule_config: "7"
        periods_per_day: 2,
    },
    //Skip Config Change
    this.state.scheduleObj.periods_per_day = 2;
    //Skip Day amount change

  });
  */

  // Called in config slide when the user chooses either "5+1+1" or "7".
  handleConfigChange = (e) => {
    // The order of these statements matters. That's why there's a bit of callback hell.
    this.setState({
      scheduleObj: {
        name: this.state.scheduleObj.name,
        schedule_config: e,
        periods_per_day: this.state.scheduleObj.periods_per_day,
        schedule: this.state.scheduleObj.schedule,
      }
    }, () => {
      //  2. Update the structure of the underlying schedule obj in state.
      this.setState({
        scheduleObj: {
          name: this.state.scheduleObj.name,
          schedule_config: this.state.scheduleObj.schedule_config,
          periods_per_day: this.state.scheduleObj.periods_per_day,
          schedule: this.scheduleFactory(),
        }
      }, () => { console.log(this.state); });
    });
  }

  handlePeriodChange = (e) => {
    // The order of these statements matters. That's why there's a bit of callback hell.
    //  1. Set the new periods
    this.setState({
      scheduleObj: {
        name: this.state.scheduleObj.name,
        schedule_config: this.state.scheduleObj.schedule_config,
        periods_per_day: e,
        schedule: this.state.scheduleObj.schedule,
      }
    }, () => {
      //  2. Update the structure of the underlying schedule obj in state.
      this.setState({
        scheduleObj: {
          name: this.state.scheduleObj.name,
          schedule_config: this.state.scheduleObj.schedule_config,
          periods_per_day: this.state.scheduleObj.periods_per_day,
          schedule: this.scheduleFactory(),
        }
      }, () => { console.log(this.state); });
    });
  }

  handlePeriodTimeChange = (targetValue, periodIndex, dayIndex) => {
    let schedule = this.state.scheduleObj.schedule;
    schedule[dayIndex][periodIndex].start_time = targetValue;

    this.setState({
      scheduleObj: {
        name: this.state.scheduleObj.name,
        schedule_config: this.state.scheduleObj.schedule_config,
        periods_per_day: this.state.scheduleObj.periods_per_day,
        schedule: schedule,
      }
    });
  }

  handlePeriodTempChange = (targetValue, periodIndex, dayIndex) => {
    let schedule = this.state.scheduleObj.schedule;
    schedule[dayIndex][periodIndex].temp_set = targetValue;
    schedule[dayIndex][periodIndex].cool_set = targetValue + 2;
    schedule[dayIndex][periodIndex].heat_set = targetValue - 2;

    this.setState({
      scheduleObj: {
        name: this.state.scheduleObj.name,
        schedule_config: this.state.scheduleObj.schedule_config,
        periods_per_day: this.state.scheduleObj.periods_per_day,
        schedule: schedule,
      }
    });
  }

  handleCoolSetChange = (targetValue, periodIndex, dayIndex) => {
    let schedule = this.state.scheduleObj.schedule;
    schedule[dayIndex][periodIndex].cool_set = targetValue;

    this.setState({
      scheduleObj: {
        name: this.state.scheduleObj.name,
        schedule_config: this.state.scheduleObj.schedule_config,
        periods_per_day: this.state.scheduleObj.periods_per_day,
        schedule: schedule,
      }
    }, () => { console.log(this.state); });
  }

  handleHeatSetChange = (targetValue, periodIndex, dayIndex) => {
    let schedule = this.state.scheduleObj.schedule;
    schedule[dayIndex][periodIndex].heat_set = targetValue;

    this.setState({
      scheduleObj: {
        name: this.state.scheduleObj.name,
        schedule_config: this.state.scheduleObj.schedule_config,
        periods_per_day: this.state.scheduleObj.periods_per_day,
        schedule: schedule,
      }
    }, () => { console.log(this.state); });
  }

  handlePeriodOccupiedChange = (targetValue, periodIndex, dayIndex) => {
    let schedule = this.state.scheduleObj.schedule;
    schedule[dayIndex][periodIndex].occupied = targetValue;

    this.setState({
      scheduleObj: {
        name: this.state.scheduleObj.name,
        schedule_config: this.state.scheduleObj.schedule_config,
        periods_per_day: this.state.scheduleObj.periods_per_day,
        schedule: schedule
      }
    });
  }

  handleSetpointModeChange = (usingTargetTemp) => {
    let schedule = this.state.scheduleObj.schedule;
    for (let i = 0; i < schedule.length; i++) {
      for (let j = 0; j < schedule[i].length; j++) {
        if (usingTargetTemp) {
          schedule[i][j].temp_set = 70;
          schedule[i][j].cool_set = 72;
          schedule[i][j].heat_set = 68;
        }
        else {
          schedule[i][j].temp_set = -1;
          schedule[i][j].cool_set = 72;
          schedule[i][j].heat_set = 68;
        }
      }
    }

    this.setState({
      setpointMode: usingTargetTemp,
      scheduleObj: {
        name: this.state.scheduleObj.name,
        schedule_config: this.state.scheduleObj.schedule_config,
        periods_per_day: this.state.scheduleObj.periods_per_day,
        schedule: schedule
      }
    }, () => { console.log(this.state); });
  }

  handleNameChange = (e) => {
    //if (e.target.value === '') return;

    this.setState({
      scheduleObj: {
          name: e.target.value,
          schedule_config: this.state.scheduleObj.schedule_config,
          periods_per_day: this.state.scheduleObj.periods_per_day,
          schedule: this.state.scheduleObj.schedule,
      }
    });
  }

  complete = () => {
    let coolSchedule = {};
    let heatSchedule = {};
    if (this.state.scheduleObj.schedule_config == "5+1+1") {
      coolSchedule["mo-fr"] = this.scheduleObjToCoolDay(0);
      coolSchedule["sa"] = this.scheduleObjToCoolDay(1);
      coolSchedule["su"] = this.scheduleObjToCoolDay(2);
      heatSchedule["mo-fr"] = this.scheduleObjToHeatDay(0);
      heatSchedule["sa"] = this.scheduleObjToHeatDay(1);
      heatSchedule["su"] = this.scheduleObjToHeatDay(2);
    }
    else if (this.state.scheduleObj.schedule_config == "7") {
      coolSchedule["mo"] = this.scheduleObjToCoolDay(0);
      coolSchedule["tu"] = this.scheduleObjToCoolDay(1);
      coolSchedule["we"] = this.scheduleObjToCoolDay(2);
      coolSchedule["th"] = this.scheduleObjToCoolDay(3);
      coolSchedule["fr"] = this.scheduleObjToCoolDay(4);
      coolSchedule["sa"] = this.scheduleObjToCoolDay(5);
      coolSchedule["su"] = this.scheduleObjToCoolDay(6);
      heatSchedule["mo"] = this.scheduleObjToHeatDay(0);
      heatSchedule["tu"] = this.scheduleObjToHeatDay(1);
      heatSchedule["we"] = this.scheduleObjToHeatDay(2);
      heatSchedule["th"] = this.scheduleObjToHeatDay(3);
      heatSchedule["fr"] = this.scheduleObjToHeatDay(4);
      heatSchedule["sa"] = this.scheduleObjToHeatDay(5);
      heatSchedule["su"] = this.scheduleObjToHeatDay(6);
    }

    let resultObj = {
      name: this.state.scheduleObj.name,
      // targetTemp: this.state.
      t_units: "F",
      periods_per_day: 2,
      schedule_config : "7",
      coolSchedule: coolSchedule,
      heatSchedule: heatSchedule
    }

    console.log(resultObj);
    this.postSchedule(resultObj)
    .then(() => {
      this.props.donePosting();
      this.resetState();
    });
  }

  postSchedule = (scheduleObj) => {
    this.setState({ apiCalling: true });
    return postSchedule(scheduleObj)
    .then(response => {
      this.setState({ apiCalling: false });
      console.log(response);
      if(response && response.success === true){
        console.log("API: New schedule submitted successfully.");
      }
      else{
        console.log("API: Failed to submit new schedule.")
      }
    })
    .catch((err) => {
      console.error(err);
    });
  }

  // In state, our schedule object is an array of arrays.
  //  The x dimension represents the day. The y dimension represents a period.
  // This function strips away the x dimension and returns an array of periods
  //  for a given day.
  scheduleObjToCoolDay = (dayIndex) => {
    let result = [];
    let schedule = this.state.scheduleObj.schedule;
    let numPeriods = schedule[dayIndex].length;
    for (let i = 0; i < numPeriods; i++) {
      let target_temp = schedule[dayIndex][i].temp_set;
      let temp_set = schedule[dayIndex][i].cool_set;
      if (schedule[dayIndex][i].occupied === "unoccupied") {
        temp_set = 90;
      }
      let tempObj = {
        start_time: schedule[dayIndex][i].start_time,
        target_temp: -1,
        temp_set: temp_set,
        occupied: schedule[dayIndex][i].occupied,
      }
      // if (schedule[dayIndex][i].occupied === "unoccupied") {
      //   schedule[dayIndex][i].cool_set = 90;
      //   schedule[dayIndex][i].heat_set = 48;
      // }
      result.push(tempObj);
    }
    return result;
  }

  scheduleObjToHeatDay = (dayIndex) => {
    let result = [];
    let schedule = this.state.scheduleObj.schedule;
    let numPeriods = schedule[dayIndex].length;
    for (let i = 0; i < numPeriods; i++) {
      let target_temp = schedule[dayIndex][i].temp_set;
      let temp_set = schedule[dayIndex][i].heat_set;
      if (schedule[dayIndex][i].occupied === "unoccupied") {
        temp_set = 48;
      }
      let tempObj = {
        start_time: schedule[dayIndex][i].start_time,
        target_temp: -1,
        temp_set: temp_set,
        occupied: schedule[dayIndex][i].occupied,
      }
      result.push(tempObj);
    }
    return result;
  }

  handleShow = (e) => {
    this.setState({show: true});
  }

  handleShowHolidayWizard = (e) => {
    this.setState({
      showHolidayWizard: true,
    });
  }

  handlePrevious = (e) => {
    let step = this.state.step;
    let increment = 1;
    if (this.state.scheduleObj.schedule_config === "5+1+1" && step === 12) increment = 5;
    this.setState({
      step: this.state.step - increment
    })
  }

  handleNext = (e) => {
    let step = this.state.step;
    let increment = 1;

    if(step == 4 && this.state.scheduleObj.name == ''){
      alert("You cannot use an empty schedule name");
      return;
    }
    if (this.state.scheduleObj.schedule_config === "5+1+1" && step === 7) increment = 5;
    if (step < this.state.totalWizardLength - 1) {
      this.setState({
        step: this.state.step + increment
      });
    }
    else {
      this.complete();
    }
  }

  render() {
    let nextButtonLabel = "Next";
    if (this.state.step === this.state.totalWizardLength - 1) { //This would indicate that our user is on the last slide
      nextButtonLabel = "Create";
    }

    let previousButtonHide = {};
    if (this.state.step === 0) {
      previousButtonHide = {
        display: "none"
      }
    }

    let scheduleSlidesList = !this.state.scheduleObj.schedule ? [] : this.state.scheduleObj.schedule.map((day, index) => {
      
      return (
        <ScheduleWizardDaySlide key={index} index={index} schedule={this.state.scheduleObj.schedule[index]}
          prevSchedules={this.state.scheduleObj.schedule.slice(0,index)}
          numPeriods={this.state.scheduleObj.periods_per_day}
          dayString={this.state.scheduleObj.schedule_config === "5+1+1" ? dayStringMap511[index] : dayStringMap7[index]}
          timeChange={this.handlePeriodTimeChange} tempChange={this.handlePeriodTempChange}
          coolSetChange={this.handleCoolSetChange} heatSetChange={this.handleHeatSetChange}
          occupiedChange={this.handlePeriodOccupiedChange} setpointMode={this.state.setpointMode}/>
      );
    });

    return (
        <div>
          <Button className={'btn-primary'} style={{ fontSize: "16px" }} onClick={this.handleShow}>
            + New Schedule
          </Button>

          <Button className={'btn-primary'} style={{ fontSize: "16px" }} onClick={this.handleShowHolidayWizard}>
            + New Holiday Schedule
          </Button>

          <Modal show={this.state.show} onHide={this.resetState} bsSize="lg">
            <Modal.Header closeButton>
              <Modal.Title>Schedule Creator <Spinner active={ this.state.apiCalling } /></Modal.Title>
            </Modal.Header>
            <Modal.Body bsClass={'samModalBody'}>
              <Slideshow step={this.state.step}>
                {/* WELCOME */}
                <center className="centerTable">
                  <h1 className="tableCell">Welcome to the Schedule Creator! This wizard will allow you to create and name daily schedules for your thermostats.</h1>
                </center>
                <center className="centerTable">
                  <div className="tableCell">
                    <h1>How about a name for this schedule?</h1>
                    <br/>
                    <div>
                      <input style={{ fontSize: "16px", marginBottom: "10px", width: "40%" }} onChange={this.handleNameChange} value={this.state.scheduleObj.name} type="text"/>
                    </div>
                  </div>
                </center>
                {/* Schedule */}
                {scheduleSlidesList[0]}
                {scheduleSlidesList[1]}
                {scheduleSlidesList[2]}
                {scheduleSlidesList[3]}
                {scheduleSlidesList[4]}
                {scheduleSlidesList[5]}
                {scheduleSlidesList[6]}
                <center className="centerTable">
                  <h1 className="tableCell">If you are ready to create schedule <b>{this.state.scheduleObj.name}</b> press "Create". Afterwards you can access and assign this schedule from the 'Control' page of one of your thermostats.</h1>
                </center>
              </Slideshow>
            </Modal.Body>
            <Modal.Footer>
              {/*<Button onClick={this.handleClose}>Close</Button>*/}
              <Button onClick={this.handlePrevious} style={previousButtonHide}>Previous</Button>
              <Button onClick={this.handleNext}>{nextButtonLabel}</Button>
            </Modal.Footer>
          </Modal>

          {/* Holiday Schedule Wizard */}
          <ScheduleWizardHoliday donePosting={this.props.donePosting} show={this.state.showHolidayWizard} hide={()=>{this.setState({showHolidayWizard: false})}} onHide={this.resetState} />

        </div>
    )
  }
}

export default ScheduleWizard;
