import React, { Component } from 'react'
import { Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { XPanel, PageTitle } from '../../components'
import { Link } from 'react-router-dom'

import Knob from './knob.js'
import ViewThermoSchedule from './viewthermoschedule.js'

//  Indicators.
import MyToolTip from '../../indicators/mytooltip.js'
import Spinner from '../../indicators/spinner.js'
import CommandSpinner from '../../indicators/commandspinner.js'

import ExternalSensor from './externalsensor.js'
import ChangeSetpoint from './changesetpoint.js'
import ChangeFan from './changefan.js'
import ChangeHold from './changehold.js'
import ChangeOpMode from './changeopmode.js'
import ChangeLock from './changelock.js'
import ChangeSchedule from './changeschedule.js'
import ChangeHolidaySchedule from './changeholidayschedule.js'
import ChangeHolidayScheduleEvents from './changeholidayscheduleevents.js'
import ChangeClock from './changeclock.js'
import ChangeTemperatureUnits from './changetemperatureunits.js'
import NewMessageArea from './newmessagearea.js'
import EngagedTransaction from './engagedtransaction.js'
import Datetime from 'react-datetime'
import Switch from 'react-switchery'
import classnames from 'classnames'
import * as moment from 'moment'

//Custom CSS
import './simpleview.css'
import api from '../../util/api.js'

const { getDevice, getLiveData } = api
import constants from '../../../config/constants.js'

const dayMap = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

const noDragStyle = {
  userDrag: 'none',
  userSelect: 'none',
}

const displayFont = {
  fontFamily: 'Digital Dream',
  fontSize: 'medium',
  fontWeight: 600,
  textAlign: 'center',
  padding: '0px',
}

// Component: Displays information on a given thermostat.
class Control extends React.Component {
  constructor() {
    super()

    this.state = {
      apiCalling: false,
      device: null,
      deviceLiveData: null,
      device: null,
      engagedTransactionInterval: 10, // in seconds. This also lives in deviceConfig.
      schedules: null,
      temp_units: 'F',
      view: 'simple',
      scheduleObjs: [],
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.hasOwnProperty('deviceID') && nextProps.deviceID !== null) {
      this.setState(nextProps, () => {
        this.getAllData()
        this.beginUpdating()
      })
    } else {
      if (this.props !== nextProps) {
        this.setState(nextProps)
      }
    }
  }

  // componentDidMount() {
  //   this.getAllData();
  //   this.beginUpdating();
  // }

  componentWillUnmount() {
    if (this.state.regGetData) {
      clearInterval(this.state.regGetData)
    }
  }

  beginUpdating = () => {
    let regGetData = setInterval(this.getAllData, this.state.engagedTransactionInterval * 1000)
    this.setState({
      regGetData: regGetData,
    })
  }

  replaceUpdateFrequency = (newEt) => {
    this.setState(
      {
        engagedTransactionInterval: newEt ? newEt : 30, // if ET is undefined, use 30
      },
      () => {
        clearInterval(this.state.regGetData)
        this.beginUpdating()
      },
    )
  }

  clearCoolKnobActiveCommandId = () => {
    this.setState({
      coolKnobActiveCommandId: '',
    })
  }

  clearHeatKnobActiveCommandId = () => {
    this.setState({
      heatKnobActiveCommandId: '',
    })
  }

  getAllDataAfterTime = (milliseconds) => {
    setTimeout(this.getAllData, milliseconds)
  }

  getAllData = () => {
    console.log('getting all data')
    this.setState({ apiCalling: true })
    this.getConfigData()
      .then(this.getLiveData())
      .then(() => {
        this.setState({ apiCalling: false })
      })
  }

  convertFahrenheihtStringToCelsiusStringToTwoDecimalPlaces = (fahrenheitString) => {
    var fahrenheit = parseFloat(fahrenheitString)
    var celsius = (fahrenheit - 32) * (5 / 9)
    return celsius.toFixed(2)
  }

  getTemperatureUnitsCompliantZoneTempString = () => {
    console.log('getTemperatureUnitsCompliantZoneTempString', this.state)
    // <div className="count">{this.state.deviceLiveData ? this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.ZONE_TEMPERATURE] : "..."}</div>
    if (
      this.state.deviceLiveData &&
      this.state.deviceLiveData.hasOwnProperty(constants.THERMOSTAT_COMMAND_KEYS_OBJ.ZONE_TEMPERATURE)
    ) {
      var zoneTempString = this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.ZONE_TEMPERATURE]
      if (this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.TEMPERATURE_DISPLAY_UNITS] === 'F') {
        return zoneTempString + '°F'
      } else if (this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.TEMPERATURE_DISPLAY_UNITS] === 'C')
        return this.convertFahrenheihtStringToCelsiusStringToTwoDecimalPlaces(zoneTempString) + '°C'
      else {
        console.log("API (thermo.js): Couldn't get temperature units.")
        return zoneTempString
      }
    } else return '...'
  }

  getTemperatureUnitsSetpointTempString = (setpointTempString) => {
    // <div className="count">{this.state.deviceLiveData ? this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.ZONE_TEMPERATURE] : "..."}</div>
    if (this.state.deviceLiveData && setpointTempString !== undefined) {
      if (this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.TEMPERATURE_DISPLAY_UNITS] === 'F') {
        return setpointTempString + '°F'
      } else if (this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.TEMPERATURE_DISPLAY_UNITS] === 'C')
        return this.convertFahrenheihtStringToCelsiusStringToTwoDecimalPlaces(setpointTempString) + '°C'
      else {
        console.log("API (thermo.js): Couldn't get temperature units.")
        return setpointTempString
      }
    } else return '...'
  }

  getConfigData = () => {
    return getDevice(this.props.siteID, this.props.deviceID)
      .then((response) => {
        if (response && response.success === true && response.data) {
          if (
            response.data.device.hasOwnProperty('configuration') &&
            response.data.device.configuration.et !== this.state.engagedTransactionInterval
          ) {
            this.replaceUpdateFrequency(response.data.device.configuration.et)
          } else {
            //  Right here, something drastic has to happen. Prompt the user
            //    to get this badboy set up.
            console.log('This is a brand new thermostat.')
          }
          this.setState(
            {
              device: response.data.device,
            },
            () => {
              console.log(this.state.device)
            },
          )
        } else {
          console.log("API (thermo.js): Couldn't get device.")
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  getLiveData = () => {
    const that = this
    return getLiveData(this.props.siteID, this.props.deviceID)
      .then((response) => {
        if (response && response.success === true && response.data) {
          console.log('response', response)
          var thermostatData = response.data.liveData
          that.setState(
            {
              deviceLiveData: thermostatData,
              device: response.data.device,
            },
            () => {
              console.log('set data', this.state.deviceLiveData)
            },
          )
        } else {
          console.log("API (thermo.js): Couldn't get live data.")
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  convertClockToHumanReadableString = (clockString) => {
    try {
      console.log('clockString: ', clockString)

      var clockStringArray = clockString.split(':')

      var dayInt = parseInt(clockStringArray[0])

      var hourInt = parseInt(clockStringArray[1])

      var minuteInt = parseInt(clockStringArray[2])

      var dayString = dayMap[dayInt].substring(0, 3)

      return dayString + ' ' + hourInt + ':' + minuteInt
    } catch (err) {
      return '...'
    }
  }

  render() {
    let engagedTransactionElement = (
      <EngagedTransaction
        deviceID={this.props.deviceID}
        siteID={this.props.siteID}
        lastUpdateTime={this.state.deviceLiveData ? this.state.deviceLiveData.datetime : ''}
        currentEngagedTransactionInterval={
          this.state.device && this.state.device.configuration ? this.state.device.configuration.et : 'loading...'
        }
        getLiveDataAfterTime={this.getAllDataAfterTime}
      />
    )
    let showEngagedTransactionElement = this.state.device ? engagedTransactionElement : <span></span>

    let opStateClass = classnames({
      blue:
        this.state.deviceLiveData &&
        this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.OPERATING_STATE] === 'COOL',
      red:
        this.state.deviceLiveData &&
        this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.OPERATING_STATE] === 'HEAT',
      count_bottom: true,
    })

    let opModeClass = classnames({
      blue:
        this.state.deviceLiveData && this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE] === 'COOL',
      red:
        this.state.deviceLiveData && this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE] === 'HEAT',
      count_bottom: true,
    })

    let fanClass = classnames({
      fa: true,
      'fa-sync': true,
      'fa-3x': true,
      'Fan-icon':
        this.state.deviceLiveData && this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.FAN_STATE] != 'I',
    })

    let lockClass = classnames({
      fa: true,
      'fa-lock':
        this.state.deviceLiveData && this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.LOCK] != 'DISABLE',
      'fa-unlock':
        this.state.deviceLiveData && this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.LOCK] == 'DISABLE',
    })

    let opModeVerbage = '...'
    console.log('deviceLiveData:')
    console.log(this.state.deviceLiveData)
    if (this.state.deviceLiveData && this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]) {
      switch (this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]) {
        case 'A':
          opModeVerbage = 'AUTO'
          break
        case 'H':
          opModeVerbage = 'HEAT'
          break
        case 'C':
          opModeVerbage = 'COOL'
          break
        case 'OFF':
          opModeVerbage = 'OFF'
          break
      }
    }
    console.log("this.statethis.statethis.statethis.statethis.state", this.state)
    return (
      <section>
        {/*Options at top of page*/}
        <Row>
          <Col md={4} sm={6} xs={6}>
            <span style={{ fontSize: '2em', marginRight: '8px' }}>
              {this.state.device ? this.state.device.name : 'loading...'}
            </span>
            {showEngagedTransactionElement}
            <Spinner active={this.state.apiCalling} />
          </Col>
          <Col md={2} sm={2} xs={2}>
            <ExternalSensor
              style={{ float: 'right' }}
              configuration={this.state.device ? this.state.device.configuration : null}
              deviceLiveData={this.state.deviceLiveData}
            />
          </Col>
          <Col md={6} sm={4} xs={4}></Col>
        </Row>
        <div>
          <b>Mac Address: {this.state.device ? this.state.device.mac_address : 'loading...'}</b>
        </div>
        <div>
          <b>Thermostat Control</b>
        </div>
        <div style={{ marginBottom: '15px' }}>
          Last update:{' '}
          {this.state.deviceLiveData
            ? moment(this.state.deviceLiveData.datetime).format('dddd, MMMM Do YYYY, h:mm:ss a')
            : 'loading...'}
        </div>
        <div>
          {/* Data Row */}
          <XPanel>
            <XPanel.Content>
              <Row>
                <div className='tile_count'>
                  <Col md={2} sm={4} xs={6}>
                    <div className='tile_stats_count'>
                      <span className='count_top'>
                        <i className='fa fa-thermometer-half'></i> Zone Temperature
                      </span>

                      {/* <div className="count">{this.state.deviceLiveData ? this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.ZONE_TEMPERATURE] : "..."}</div> */}
                      <div className='count'>{this.getTemperatureUnitsCompliantZoneTempString()}</div>
                      <span className='count_bottom'>Fahrenheit</span>
                    </div>
                  </Col>
                  <Col md={2} sm={4} xs={6} style={{ padding: '0px' }}>
                    <div className='tile_stats_count' style={{ padding: '0px' }}>
                      <span className='count_top'>
                        <i className='fa fa-calendar'></i> Clock
                      </span>
                      <div className='count' style={{ padding: '0px' }}>
                        {this.state.deviceLiveData
                          ? this.convertClockToHumanReadableString(
                              this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CLOCK],
                            )
                          : '...'}
                      </div>
                      <span className='count_bottom'>
                        {this.state.deviceLiveData
                          ? this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CLOCK]
                          : '...'}
                      </span>
                    </div>
                  </Col>
                  <Col md={2} sm={4} xs={6}>
                    <div className='tile_stats_count'>
                      <span className='count_top'>
                        <i className='fa fa-snowflake'></i> Cool Set Point
                      </span>
                      <div className='count'>
                        {this.state.deviceLiveData
                          ? this.getTemperatureUnitsSetpointTempString(
                              this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.COOL_SETPOINT],
                            )
                          : '...'}
                      </div>
                    </div>
                  </Col>
                  <Col md={2} sm={4} xs={6}>
                    <div className='tile_stats_count'>
                      <span className='count_top'>
                        <i className='fa fa-fire'></i> Heat Set Point
                      </span>
                      <div className='count'>
                        {this.state.deviceLiveData
                          ? this.getTemperatureUnitsSetpointTempString(
                              this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.HEAT_SETPOINT],
                            )
                          : '...'}
                      </div>
                    </div>
                  </Col>
                  <Col md={2} sm={4} xs={6}>
                    <div className='tile_stats_count'>
                      <span className='count_top'>
                        <i className='fa fa-cog'></i> Operating State
                      </span>
                      <div className='count'>
                        {this.state.deviceLiveData
                          ? this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.OPERATING_STATE]
                          : '...'}
                      </div>
                      <span className={opStateClass}>
                        <i className='fa fa-circle fa-lg'></i>
                      </span>
                    </div>
                  </Col>
                  <Col md={2} sm={4} xs={6}>
                    <div className='tile_stats_count'>
                      <span className='count_top'>
                        <i className='fa fa-sync'></i> Fan State
                      </span>
                      <div className='count'>
                        {this.state.deviceLiveData
                          ? this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.FAN_STATE]
                          : '...'}
                      </div>
                      <span>
                        <i className={fanClass}></i>
                      </span>
                    </div>
                  </Col>
                </div>
              </Row>

              <Row>
                <div className='tile_count'>
                  <Col md={2} sm={4} xs={6} className='full-height-xpanel-col'>
                    <div className='tile_stats_count'>
                      <span className='count_top'>
                        <i className='fa fa-hand-point-down'></i> Hold
                      </span>
                      <div className='count-verbose'>
                        {this.state.deviceLiveData
                          ? this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.HOLD]
                          : '...'}
                      </div>
                    </div>
                  </Col>
                  <Col md={2} sm={4} xs={6} className='full-height-xpanel-col'>
                    <div className='tile_stats_count'>
                      <span className='count_top'>
                        <i className={lockClass}></i> Lock
                      </span>
                      <div className='count-verbose'>
                        {this.state.deviceLiveData
                          ? this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.LOCK]
                          : '...'}
                      </div>
                    </div>
                  </Col>
                  <Col md={2} sm={4} xs={6}>
                    <div className='tile_stats_count'>
                      <span className='count_top'>
                        <i className='fa fa-calendar'></i> Schedule
                      </span>
                      <div className='count'>
                        <ViewThermoSchedule
                          siteID={this.props.siteID}
                          deviceID={this.props.deviceID}
                          getLiveDataAfterTime={this.getAllDataAfterTime}
                          configuration={this.state.deviceLiveData ? this.state.deviceLiveData : null}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={2} sm={4} xs={6}>
                    <div className='tile_stats_count'>
                      <span className='count_top'>
                        <i className='fa fa-cog'></i> Operating Mode
                      </span>
                      <div className='count-verbose'>{opModeVerbage}</div>
                      <span className={opModeClass}>
                        <i className='fa fa-circle fa-lg'></i>
                      </span>
                    </div>
                  </Col>
                  <Col md={2} sm={4} xs={6} style={{ padding: '0px' }}>
                    <div className='tile_stats_count' style={{ padding: '0px' }}>
                      <span className='count_top'>
                        <i className='fa fa-comment-o'></i> Message Area
                      </span>
                      <div style={displayFont}>
                        {this.state.deviceLiveData
                          ? this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MESSAGE_TEXT]
                          : ''}
                      </div>
                      <NewMessageArea
                        deviceID={this.props.deviceID}
                        siteID={this.props.siteID}
                        deviceLiveData={this.state.deviceLiveData}
                        getLiveDataAfterTime={this.getAllDataAfterTime}
                        getAllData={this.getAllData}
                      />
                    </div>
                  </Col>
                  <Col md={2} sm={4} xs={6}>
                    <div className='tile_stats_count'>
                      <span className='count_top'>
                        <i className='fa fa-superpowers'></i> CO2
                      </span>
                      <div className='count'>
                        {this.state.deviceLiveData &&
                        this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_CO2]
                          ? this.state.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_CO2] + ' PPM'
                          : '...'}
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
            </XPanel.Content>
          </XPanel>

          {/*GRAPH WILL GO HERE*/}
          <Row></Row>

          {/*Setters 1*/}
          <Row style={{ height: '350px' }}>
            <Col md={3} sm={3} xs={12} className='full-height-xpanel-col'>
              <XPanel>
                <XPanel.Title
                  title={(function () {
                    return (
                      <div>
                        Change Fan
                        <MyToolTip marginleft>
                          <div>AUTO turns the fan on when either cooling or heating. ON just keeps the fan on.</div>
                        </MyToolTip>
                      </div>
                    )
                  })()}
                ></XPanel.Title>
                <XPanel.Content>
                  <ChangeFan
                    siteID={this.props.siteID}
                    deviceID={this.props.deviceID}
                    deviceLiveData={this.state.deviceLiveData}
                    getLiveDataAfterTime={this.getAllDataAfterTime}
                  />
                </XPanel.Content>
              </XPanel>
            </Col>
            <Col md={3} sm={3} xs={12} className='full-height-xpanel-col'>
              <XPanel>
                <XPanel.Title title='Change Hold'></XPanel.Title>
                <XPanel.Content>
                  <ChangeHold
                    siteID={this.props.siteID}
                    deviceID={this.props.deviceID}
                    deviceLiveData={this.state.deviceLiveData}
                    getLiveDataAfterTime={this.getAllDataAfterTime}
                  />
                </XPanel.Content>
              </XPanel>
            </Col>
            <Col md={3} sm={3} xs={12} className='full-height-xpanel-col'>
              <XPanel>
                <XPanel.Title title='Change Op Mode'></XPanel.Title>
                <XPanel.Content>
                  <ChangeOpMode
                    siteID={this.props.siteID}
                    deviceID={this.props.deviceID}
                    deviceLiveData={this.state.deviceLiveData}
                    getLiveDataAfterTime={this.getAllDataAfterTime}
                  />
                </XPanel.Content>
              </XPanel>
            </Col>
            <Col md={3} sm={3} xs={12} className='full-height-xpanel-col'>
              <XPanel>
                <XPanel.Title
                  title={(function () {
                    return (
                      <div>
                        Change Setpoint
                        <MyToolTip marginleft={true}>
                          <div>
                            It is mandatory to ensure that the cool setpoint is always at least 4 degrees higher than
                            the heat setpoint.
                          </div>
                        </MyToolTip>
                      </div>
                    )
                  })()}
                ></XPanel.Title>
                <XPanel.Content>
                  <ChangeSetpoint
                    siteID={this.props.siteID}
                    deviceID={this.props.deviceID}
                    deviceLiveData={this.state.deviceLiveData}
                    getLiveDataAfterTime={this.getAllDataAfterTime}
                  />
                </XPanel.Content>
              </XPanel>
            </Col>
          </Row>

          {/*Setters 2*/}
          <Row style={{ height: '350px' }}>
            <Col md={3} sm={3} xs={12} className='full-height-xpanel-col'>
              <XPanel>
                <XPanel.Title title='Change Lock'></XPanel.Title>
                <XPanel.Content>
                  <ChangeLock
                    siteID={this.props.siteID}
                    deviceID={this.props.deviceID}
                    deviceConfig={this.state.deviceLiveData ? this.state.deviceLiveData : null}
                    getLiveDataAfterTime={this.getAllDataAfterTime}
                  />
                </XPanel.Content>
              </XPanel>
            </Col>
            <Col md={3} sm={3} xs={12} className='full-height-xpanel-col'>
              <XPanel showScroll={true}>
                <XPanel.Title
                  title={(function () {
                    return (
                      <div>
                        Change Schedule
                        <Link to={`/schedulemanager`}>
                          <Button className={'btn-primary'} style={{ marginLeft: '10px' }}>
                            Create
                          </Button>
                        </Link>
                      </div>
                    )
                  })()}
                ></XPanel.Title>
                <XPanel.Content style={{ overflow: 'auto', height: '80%' }}>
                  <ChangeSchedule
                    siteID={this.props.siteID}
                    deviceID={this.props.deviceID}
                    getLiveDataAfterTime={this.getAllDataAfterTime}
                  />
                </XPanel.Content>
              </XPanel>
            </Col>
            <Col md={3} sm={3} xs={12} className='full-height-xpanel-col'>
              <XPanel>
                <XPanel.Title title='Change Clock'></XPanel.Title>
                <XPanel.Content>
                  <ChangeClock
                    siteID={this.props.siteID}
                    deviceID={this.props.deviceID}
                    deviceLiveData={this.state.deviceLiveData}
                    deviceConfig={this.state.deviceLiveData ? this.state.deviceLiveData : null}
                    getLiveDataAfterTime={this.getAllDataAfterTime}
                  />
                </XPanel.Content>
              </XPanel>
            </Col>
            <Col md={3} sm={3} xs={12} className='full-height-xpanel-col'>
              <XPanel>
                <XPanel.Title title='Change Temp Units'></XPanel.Title>
                <XPanel.Content style={{ height: '80%' }}>
                  <ChangeTemperatureUnits
                    siteID={this.props.siteID}
                    deviceID={this.props.deviceID}
                    deviceLiveData={this.state.deviceLiveData}
                    deviceConfig={this.state.deviceLiveData ? this.state.deviceLiveData : null}
                    getLiveDataAfterTime={this.getAllDataAfterTime}
                  />
                </XPanel.Content>
              </XPanel>
            </Col>
          </Row>
          <Row>
            {/* <Col md={ 3 } sm={ 3 } xs={ 12 } className="full-height-xpanel-col"></Col> */}
            {/* Holiday Schedules */}
            <Col md={6} sm={6} xs={12} className='full-height-xpanel-col'>
              <XPanel showScroll={true}>
                <XPanel.Title
                  title={(function () {
                    return (
                      <div>
                        Assign Holiday Schedules
                        <Link to={`/schedulemanager`}>
                          <Button className={'btn-primary'} style={{ marginLeft: '10px' }}>
                            Create
                          </Button>
                        </Link>
                      </div>
                    )
                  })()}
                ></XPanel.Title>
                <XPanel.Content style={{ overflow: 'auto', height: '80%' }}>
                  <ChangeHolidaySchedule
                    siteID={this.props.siteID}
                    deviceID={this.props.deviceID}
                    portalDeviceID={this.props.portalDeviceID}
                    getLiveDataAfterTime={this.getAllDataAfterTime}
                  />
                </XPanel.Content>
              </XPanel>
            </Col>

            <Col md={6} sm={6} xs={12} className='full-height-xpanel-col'>
              <XPanel showScroll={true}>
                <XPanel.Title
                  title={(function () {
                    return <div>Upcoming Holiday Schedule Events</div>
                  })()}
                ></XPanel.Title>
                <XPanel.Content style={{ overflow: 'auto', height: '80%' }}>
                  <ChangeHolidayScheduleEvents
                    siteID={this.props.siteID}
                    deviceID={this.props.deviceID}
                    portalDeviceID={this.props.portalDeviceID}
                  />
                  {/* <ChangeHolidaySchedule
                  siteID={this.props.siteID}
                  deviceID={this.props.deviceID}
                  portalDeviceID={this.props.portalDeviceID}
                  getLiveDataAfterTime={this.getAllDataAfterTime}/> */}
                </XPanel.Content>
              </XPanel>
            </Col>
            {/* <Col md={ 3 } sm={ 3 } xs={ 12 } className="full-height-xpanel-col"></Col> */}
          </Row>
        </div>
      </section>
    )
  }
}

export default Control
