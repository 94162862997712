import React, { Component } from 'react';
import { Row, ButtonToolbar, ToggleButtonGroup, ToggleButton, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Spinner from '../../indicators/spinner.js'
import CommandSpinner from '../../indicators/commandspinner.js'

import api from '../../util/api.js';

const { setHold } = api;
import constants from '../../../config/constants.js'

function LinkWithTooltip({ id, children, tooltip }) {
  return (
    <OverlayTrigger
      overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
      placement="top"
      delayShow={300}
      delayHide={150}
    >
    <div>
      {children}
    </div>
    </OverlayTrigger>
  );
}

class ChangeHold extends React.Component {
  constructor() {
      super();
      this.state = {
        holdSwitch: false,
        apiCalling: false,
        activeCommandId: '',
        hasBeenUsed: false
      }
  }

  

  componentDidUpdate(prevProps) {
    //  Three conditions:
    //    - Switch hasn't been used.
    //    - LiveData is coming down as a prop
    //    - LiveData has changed
    //  The outcome:
    //    - Switch state is decided by that live data (i.e. device state)

    
    if (!this.state.hasBeenUsed && this.props.deviceLiveData &&
      this.props.deviceLiveData != prevProps.deviceLiveData) {
        console.log("hold is: ", this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.HOLD]);
      if (this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.HOLD] != undefined) {
        this.setState({ holdSwitch: this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.HOLD] });
      }
    }
    
  }

  handleHoldChange = (value) => {
    this.setState({ holdSwitch: value });
  }

  handleHoldSubmit = (e) => {
    console.log("hold is", this.state.holdSwitch);
    //API call
    this.setState({ apiCalling: true });
    setHold(this.props.siteID, this.props.deviceID, this.state.holdSwitch)
    .then(res => {
      this.setState({
        apiCalling: false,
      })
      return res;
    })
    .then(res => {
      console.log(res);
      this.setState({
        activeCommandId: res.data.commandId
      });
    });
  }

  clearActiveCommandId = () => {
    console.log("ChangeHold: Cleared active command ID.");
    this.setState({
      activeCommandId: "",
    })
  }

  render() {
    //'SCHDL', 'TEMPR','PERM','SMR'
    return (
      <center>
        <ButtonToolbar>
          <ToggleButtonGroup type="radio" name="options" vertical="true" onChange={this.handleHoldChange} value={this.state.holdSwitch}>
              <ToggleButton value={"SCHDL"} style={{marginLeft: "0px"}}>
                <LinkWithTooltip href="#" id="tooltip-disable">
                  Schedule
                </LinkWithTooltip>
              </ToggleButton>
              <ToggleButton value={"TEMPR"}>
                <LinkWithTooltip href="#" id="tooltip-basic">
                  Temperature Hold
                </LinkWithTooltip>
              </ToggleButton>
              <ToggleButton value={"PERM"}>
                <LinkWithTooltip href="#" id="tooltip-partiallock">
                  Permanent Hold
                </LinkWithTooltip>
              </ToggleButton>
              <ToggleButton value={"SMR"}>
                <LinkWithTooltip id="tooltip-fulllock">
                  Summer
                </LinkWithTooltip>
              </ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>
        
        
        <Button onClick={this.handleHoldSubmit} type="button" className="btn btn-default">Submit</Button>

        <Row>
          <Spinner active={ this.state.apiCalling } />
          <CommandSpinner
            commandId={ this.state.activeCommandId } clearActiveCommandId={this.clearActiveCommandId}
            siteID={this.props.siteID} deviceID={this.props.deviceID}
            getLiveDataAfterTime={this.props.getLiveDataAfterTime}
          />
        </Row>
      </center>
    )
  }
}

export default ChangeHold;
