import React, { Component } from 'react';
import { Row, ButtonToolbar, ToggleButtonGroup, ToggleButton, Button } from 'react-bootstrap';
import Spinner from '../../indicators/spinner.js'
import CommandSpinner from '../../indicators/commandspinner.js'
import api from '../../util/api.js';

const { setOpMode } = api;
import constants from '../../../config/constants.js'

class ChangeOpMode extends React.Component {
  constructor() {
    super();

    this.state = {
      opMode: "AUTO",
      apiCalling: false,
      activeCommandId: '',
      hasBeenUsed: false,
    }
  }

  //'OFF','H','C','A','AC','AH'
  componentDidUpdate(prevProps) {
    //  Three conditions:
    //    - Switch hasn't been used.
    //    - LiveData is coming down as a prop
    //    - LiveData has changed
    //  The outcome:
    //    - Switch state is decided by that live data (i.e. device state)
    if (!this.state.hasBeenUsed && this.props.deviceLiveData &&
      this.props.deviceLiveData != prevProps.deviceLiveData) {
        switch(this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]) {
          case "OFF":
            this.setState({ opMode: "OFF" });
            break;
          case "A":
            this.setState({ opMode: "A" });
            break;
          case "H":
            this.setState({ opMode: "H" });
            break;
          case "C":
            this.setState({ opMode: "C" });
            break;
        }
    }
  }

  handleOpModeChange = (value) => {
    this.setState({
      hasBeenUsed: true
    }, () => {
      this.setState({
        opMode: value
      });
    });
  }

  handleOpModeSubmit = (e) => {
      this.setState({ apiCalling: true });
      setOpMode(this.props.siteID, this.props.deviceID, this.state.opMode)
      .then(res => {
        this.setState({
          apiCalling: false,
        })
        return res;
      })
      .then(res => {
        console.log(res);
        this.setState({
          activeCommandId: res.data.commandId
        });
      });
  }

  clearActiveCommandId = () => {
    console.log("ChangeOpMode: Cleared active command ID.");
    this.setState({
      activeCommandId: "",
    })
  }

  render() {
    return (
      <center>
        <ButtonToolbar>
          <ToggleButtonGroup type="radio" name="options" vertical="true" onChange={this.handleOpModeChange} value={this.state.opMode}>
            <ToggleButton value={"A"} style={{marginLeft: "0px"}}>Heat & Cool</ToggleButton>
            <ToggleButton value={"H"}>Heat Only</ToggleButton>
            <ToggleButton value={"C"}>Cool Only</ToggleButton>
            <ToggleButton value={"OFF"}>Off</ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>
        
        <Button style={{"marginTop": "8px"}} type="button" className="btn btn-default" onClick={this.handleOpModeSubmit}>Submit</Button>
        <Row>
      <Spinner active={ this.state.apiCalling } />
        <CommandSpinner
          commandId={ this.state.activeCommandId } clearActiveCommandId={this.clearActiveCommandId}
          siteID={this.props.siteID} deviceID={this.props.deviceID}
          getLiveDataAfterTime={this.props.getLiveDataAfterTime}
        />
        </Row>
      </center>
    )
  }
}

export default ChangeOpMode;
