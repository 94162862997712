import React from 'react'
import ReactDOM from 'react-dom'

//User components
import { Left, Top, Footer } from '../navigation'
import SideBar from '../components/SideBar'
import SideNavSitesAndDevices from '../navigation/SideNavSitesAndDevices.js'
import api from '../util/api.js'
const childrenWithProps = (children, props) => React.Children.map(children, (c) => React.cloneElement(c, props))

const { fetchUserResolvedSitesAndDevices } = api
const Menu = (user) => {
  return (
    <SideBar>
      <SideBar.MenuSection title='General'>
        <SideNavSitesAndDevices user={user} />
      </SideBar.MenuSection>
    </SideBar>
  )
}

class PortalLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillReceiveProps(nextProps) {
    //this.setState({user: nextProps.user});
    //this.getUserPromise(nextProps.user._id);
    console.log('portallayout componentWillReceiveProps()')
    this.getUserResolvedSitesAndDevices()
  }

  componentWillMount() {
    console.log('portallayout componentWillMount()')
    this.getUserResolvedSitesAndDevices()
  }

  getUserResolvedSitesAndDevices() {
    var that = this
    fetchUserResolvedSitesAndDevices()
      .then((res) => {
        console.log('getUserResolvedSitesAndDevices res')
        console.log(res)
        return res.json()
      })
      .then((response) => {
        //Successful login
        console.log('getUserResolvedSitesAndDevices response:')
        console.log(response)
        if (response && response.success === true && response.data && response.data.user) {
          that.setState({
            user: response.data.user,
          })
        } else {
          //Unsuccessful login
          //TODO: handle missing resolvedPortalSites
        }
      })
      .catch((err) => {
        console.log("Couldn't get user info") //TODO: handle this with a redirect probably
        console.log(err)
        console.log(this.props)
      })
  }

  render() {
    console.log('this.state.user', this.state.user)
    return (
      <div className='container body'>
        <div className='main_container'>
          <Left>
            {/*<SideNavSitesAndDevices user={this.state.user}/>*/}
            <SideBar>
              <SideBar.MenuSection title='General'>
                <SideNavSitesAndDevices user={this.state.user} />
              </SideBar.MenuSection>
            </SideBar>
          </Left>
          <Top />
          <div className='right_col' role='main'>
            {/*Pass the user to the body component in case it needs it*/}
            {childrenWithProps(this.props.children, {
              user: this.state.user,
              test: 'yoyoyoyoyoyo',
              refreshSideNav: this.getUserResolvedSitesAndDevices,
            })}
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default PortalLayout
