import React, { Component } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import classnames from 'classnames';

const styleClass = {
  'fa': true,
  'fa-question-circle': true
}

class MyToolTip extends React.Component {

  render() {
    let tooltip = (
      <Tooltip id="tooltip">
        {this.props.children}
      </Tooltip>
    );

    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        <i className={classnames(styleClass)} style={{...(this.props.marginleft && { marginLeft: "5px" }),}}/>
      </OverlayTrigger>
    )
  }

}

export default MyToolTip;
