import React, { Component } from 'react';
import { Row, ButtonToolbar, ToggleButtonGroup, ToggleButton, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Spinner from '../../indicators/spinner.js'
import CommandSpinner from '../../indicators/commandspinner.js'

import api from '../../util/api.js';

const { setLock } = api;
import constants from '../../../config/constants.js'

function LinkWithTooltip({ id, children, tooltip }) {
  return (
    <OverlayTrigger
      overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
      placement="top"
      delayShow={300}
      delayHide={150}
    >
      <p>{children}</p>
    </OverlayTrigger>
  );
}

const tooltipDisable = "Thermostat can be operated from the front panel.";
const tooltipBasic = "";
const tooltipPartial = "Thermostat up/down buttons can be operated--all other buttons are locked.";
const tooltipFull = "Thermostat cannot be operated from the front panel.";

class ChangeLock extends React.Component {
  constructor() {
    super();

    this.state = {
      lockSwitch: "PARTIAL",
      apiCalling: false,
      activeCommandId: "",
      hasBeenUsed: false,
    }
  }

  componentDidUpdate(prevProps) {
    //  Three conditions:
    //    - Switch hasn't been used.
    //    - LiveData is coming down as a prop
    //    - LiveData has changed
    //  The outcome:
    //    - Switch state is decided by that live data (i.e. device state)
    if (!this.state.hasBeenUsed && this.props.deviceConfig &&
      this.props.deviceConfig != prevProps.deviceConfig) {
        console.log(this.props.deviceConfig);
        switch(this.props.deviceConfig[constants.THERMOSTAT_COMMAND_KEYS_OBJ.LOCK]) {
          //"OFF","BSC","PRT","FULL"
          case "OFF":
            this.setState({ lockSwitch: "OFF" });
            break;
          case "BSC":
            this.setState({ lockSwitch: "BSC" });
            break;
          case "PRT":
            this.setState({ lockSwitch: "PRT" });
            break;
          case "FULL":
            this.setState({ lockSwitch: "FULL" });
            break;
        }
    }
  }

  handleLockChange = (value) => {
    this.setState({
      lockSwitch: value,
      hasBeenUsed: true
    });
  }

  handleLockSubmit = (e) => {
    console.log("lock is", this.state.lockSwitch);

    this.setState({ apiCalling: true });

    setLock(this.props.siteID, this.props.deviceID, this.state.lockSwitch)
    .then(res => {
      console.log(res);
      this.setState({ apiCalling: false });
      if (res && res.success === true) {
        console.log("API (thermo.js): Lock set success");
      }
      else {
        console.log("API (thermo.js): Lock set failed.");
      }
      return res;
    })
    .then(res => {
      this.setState({ activeCommandId: res.data.commandId });
    })
  }

  clearActiveCommandId = () => {
    console.log("ChangeOpMode: Cleared active command ID.");
    this.setState({
      activeCommandId: "",
    })
  }

  //"OFF","BSC","PRT","FULL"
  render() {
    return (
      <center>
        <div>
        <ButtonToolbar>
        <ToggleButtonGroup type="radio" name="options" vertical="true" onChange={this.handleLockChange} value={this.state.lockSwitch}>
            <ToggleButton value={"OFF"} style={{marginLeft: "0px"}}>
              <LinkWithTooltip tooltip={tooltipDisable} href="#" id="tooltip-disable">
                No Lockout
              </LinkWithTooltip>
            </ToggleButton>
            <ToggleButton value={"BSC"}>
              <LinkWithTooltip tooltip={tooltipDisable} href="#" id="tooltip-basic">
                Basic Lockout
              </LinkWithTooltip>
            </ToggleButton>
            <ToggleButton value={"PRT"}>
              <LinkWithTooltip tooltip={tooltipPartial} href="#" id="tooltip-partiallock">
                Partial Lockout
              </LinkWithTooltip>
            </ToggleButton>
            <ToggleButton value={"FULL"}>
              <LinkWithTooltip tooltip={tooltipFull} id="tooltip-fulllock">
                Full Lockout
              </LinkWithTooltip>
            </ToggleButton>
        </ToggleButtonGroup>
        </ButtonToolbar>
        <Button style={{"marginTop": "8px"}} type="button" className="btn btn-default" onClick={this.handleLockSubmit}>Submit</Button>
        
        <Row>
          <Spinner active={ this.state.apiCalling } />
          <CommandSpinner
            commandId={ this.state.activeCommandId } clearActiveCommandId={this.clearActiveCommandId}
            siteID={this.props.siteID} deviceID={this.props.deviceID}
            getLiveDataAfterTime={this.props.getLiveDataAfterTime}
          />
        </Row>
        </div>
      </center>
    )
  }
}

export default ChangeLock;
