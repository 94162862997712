import React, { Component } from 'react';
import { Row, Col, Input } from 'react-bootstrap';
import SchedulesMenuList from './schedulesmenulist.js';
import HolidaySchedulesMenuList from './holidayschedulesmenulist.js'
import ScheduleWizard from '../buildschedule/scheduleWizard.js';
import SearchBar from './searchbar'

import api from '../../util/api.js';

const { fetchUserPromise } = api

class SchedulesMenu extends React.Component {
  constructor() {
    super();

    this.state = {
      scheduleObjs: [],
      holidayScheduleObjs: [],
      filteredHolidayScheduleObjs: [],
      filteredScheduleObjs: [],
      didApiFetch: false,
    }
  }

  apiUrl = "https://jdpy43j0u7.execute-api.us-west-2.amazonaws.com";

  componentDidMount() {
    this.getSchedules();
    this.getHolidaySchedules();
  }

  getSchedules = () => {
    fetchUserPromise()
    .then(res => res.json())
    .then(response => {
      this.setState({ didApiFetch: true });
      if(response && response.success === true && response.data){
        console.log("FETCHED SCHEDULES!");
        console.log(response.data.user.thermostatschedules);
        this.setState({
          scheduleObjs: response.data.user.thermostatschedules,
          filteredScheduleObjs: response.data.user.thermostatschedules,
          holidayScheduleObjs: response.data.user.thermostatholidayschedules,
          filteredHolidayScheduleObjs: response.data.user.thermostatholidayschedules,
        }, () => { console.log(this.state.scheduleObjs); });
      }
      else {
        console.log("Couldn't get schedules.")
      }
    })
  }

  setHolidaySearchResults = (filteredPosts) => {
    this.setState({
      filteredHolidayScheduleObjs: filteredPosts
    });
  }

  setScheduleSearchResults = (filteredPosts) => {
    this.setState({
      filteredScheduleObjs: filteredPosts
    });
  }

  getHolidaySchedules = () => {
    fetchUserPromise()
    .then(res => res.json())
    .then(response => {
      this.setState({ didApiFetch: true });
      if(response && response.success === true && response.data){
        console.log("FETCHED SCHEDULES!");
        console.log(response.data.user.thermostatschedules);
        this.setState({
          scheduleObjs: response.data.user.thermostatschedules,
          filteredScheduleObjs: response.data.user.thermostatschedules,
          holidayScheduleObjs: response.data.user.thermostatholidayschedules,
          filteredHolidayScheduleObjs: response.data.user.thermostatholidayschedules
        }, () => { console.log(this.state.scheduleObjs); });
      }
      else {
        console.log("Couldn't get schedules.")
      }
    })
  }


  handleSubmitSchedule = (scheduleObj) => {
    let scheduleObjs = this.state.scheduleObjs;
    scheduleObjs.push(scheduleObj);
    this.setState({
      scheduleObjs: scheduleObjs
    }, function() {
      //console.log("Schedules component received", this.state.schedules);
    });
  }

  render() {
    let { siteID } = this.props.match.params;
    return(
      <div>
        <ScheduleWizard donePosting={this.getSchedules}/>
        <Col md={ 6 } sm={ 6 } xs={ 12 }>
          <Row style={{"display": "flex","alignItems":"center"}}>
            <h1>Schedules</h1>
            <SearchBar posts={this.state.scheduleObjs} setSearchResults={this.setScheduleSearchResults} />
          </Row>
          <SchedulesMenuList siteID={siteID}
            scheduleObjs={this.state.filteredScheduleObjs}
            handleDelete={this.getSchedules}
            didApiFetch={this.state.didApiFetch}/>
        </Col>
        <Col md={ 6 } sm={ 6 } xs={ 12 }>
          <Row style={{"display": "flex","alignItems":"center"}}>
            <h1 style={{float: "left"}}>Holiday Schedules</h1>
            <SearchBar posts={this.state.holidayScheduleObjs} setSearchResults={this.setHolidaySearchResults} />
          </Row>
          <HolidaySchedulesMenuList siteID={siteID}
            scheduleObjs={this.state.filteredHolidayScheduleObjs}
            handleRefreshSchedules={this.getSchedules}
            didApiFetch={this.state.didApiFetch}/>
        </Col>
      </div>
    )
  }

}

export default SchedulesMenu;
