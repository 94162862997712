import React, { Component } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { XPanel, PageTitle } from '../../components'
//User components
import Spinner from '../../indicators/spinner.js'
import SiteCreationModal from './sitecreationmodal.js'
import SiteDeletionModal from './sitedeletionmodal.js'
import SiteAddDevicesModal from './siteadddevicesmodal.js'
import SiteCurrentDevicesTable from './sitecurrentdevicestable.js'
import api from '../../util/api.js'

const { fetchUserPromise, deleteSchedule } = api

var apiUrl = 'https://jdpy43j0u7.execute-api.us-west-2.amazonaws.com'

//High level component that creats the row / collumn for the site tables, and creates XPanel's for each site
class SiteManager extends React.Component {
  static isPrivate = true

  constructor(props) {
    super(props)
    console.log('sitemanager constructor props: ', props)
    this.state = {
      user: props.user,
      showCreateModalBool: false,
      showDeleteModalBool: false,
      apiCalling: false,
      failCalling: false,
      siteToDelete: {},
      siteToAddDevices: {},
      siteToModify: {},
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log('componentWillReceiveProps sitemanager nextprops: ', nextProps)
    this.getSites()
    this.setState({ user: nextProps.user })
  }

  componentWillMount() {
    console.log('sitemanager componentWillMount()')
    this.getSites()
    //this.getUserResolvedSitesAndDevices();
  }

  handleCreateSiteButton = (e) => {
    this.setState({
      showCreateModalBool: true,
    })
  }

  getSites = () => {
    fetchUserPromise()
      .then((res) => res.json())
      .then((response) => {
        console.log('user typeof ' + typeof response.data.user + 'is: ', response.data.user)
        this.setState({ didApiFetch: true, user: response.data.user })
        if (response && response.success === true && response.data) {
          console.log('FETCHED SCHEDULES!')
          console.log(response)
          console.log(response.data.user.thermostatschedules)
          // this.setState({
          //   user: response.data.user
          // });
        } else {
          console.log("Couldn't get schedules.")
        }
      })
      .catch((err) => {
        console.log('got schedules err', err)
      })

    //Lets also refresh the sidenav, we probably need to whenever getSites is called
    this.props.refreshSideNav()
  }

  handleDeleteSiteButton = (e) => {
    this.setState({
      siteToDelete: {
        _id: e.target.getAttribute('siteid'),
        name: e.target.getAttribute('sitename'),
      },
      showDeleteModalBool: true,
    })
  }

  handleHideModal = (e) => {
    this.setState({ showCreateModalBool: false })
  }

  handleHideDeletionModal = (e) => {
    this.setState({ showDeleteModalBool: false })
  }

  handleShowAddDevicesModal = (e) => {
    this.setState({
      siteToAddDevices: {
        _id: e.target.getAttribute('siteid'),
        name: e.target.getAttribute('sitename'),
      },
      showAddDevicesModalBool: true,
    })
  }

  handleHideAddDevicesModal = (e) => {
    this.setState({ showAddDevicesModalBool: false })
  }

  handleCreateButton = (e) => {
    /*
    this.setState({ 
      failCalling: false,
      apiCalling: true,
     });
    deleteSchedule(this.props.scheduleObj._id, this.props.scheduleObj.name)
    .then((res) => {
      console.log(res);
      this.props.handleDelete();
      this.setState({
        apiCalling: false,
        failCalling: false,
      });
    })
    .catch((err) => {
      console.log("delete schedule error");
      console.log(err);
      this.setState({
        apiCalling: false,
        failCalling: true,
      });
    });
    */
  }

  getDeleteSiteButtonJsx = (site) => {
    return (
      <div>
        {site.name}
        <Button
          className={'btn-danger'}
          style={{ marginLeft: '10px' }}
          siteid={site._id}
          sitename={site.name}
          site={{ site }}
          onClick={this.handleDeleteSiteButton}
        >
          Delete Site
        </Button>
      </div>
    )
  }

  render() {
    //Only generate tables if the user has been loaded

    if (this.state.user && Array.isArray(this.state.user.resolvedPortalSites)) {
      if (this.props.match.params.siteID)
        var sites = this.state.user.resolvedPortalSites.filter(
          (resolvedPortalSite) => resolvedPortalSite._id === this.props.match.params.siteID,
        )
      //Home dashboard, show tables for all sites
      else var sites = this.state.user.resolvedPortalSites
    } else var sites = []

    return (
      <React.Fragment>
        <Row style={{ display: 'flex' }}>
          <h1 style={{paddingLeft: 12}}>Site Manager</h1>{' '}
          <Button style={{ marginLeft: '10px' }} className={'btn-primary'} onClick={this.handleCreateSiteButton}>
            New Site
          </Button>
          <SiteCreationModal
            show={this.state.showCreateModalBool}
            handleHideModal={this.handleHideModal}
            refreshSites={this.getSites}
          ></SiteCreationModal>
          <SiteDeletionModal
            show={this.state.showDeleteModalBool}
            handleHideModal={this.handleHideDeletionModal}
            refreshSites={this.getSites}
            siteId={this.state.siteToDelete._id}
            siteName={this.state.siteToDelete.name}
          ></SiteDeletionModal>
        </Row>
        {this.state.user ? (
          sites
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((site) => {
              return (
                <XPanel>
                  <XPanel.Title id={site._id} title={this.getDeleteSiteButtonJsx(site)}></XPanel.Title>
                  <XPanel.Content>
                    <SiteCurrentDevicesTable
                      siteId={site._id}
                      siteName={site.name}
                      refreshSites={this.getSites}
                      key={site._id}
                    ></SiteCurrentDevicesTable>
                  </XPanel.Content>
                </XPanel>
              )
            }, this)
        ) : (
          <div></div>
        )}
      </React.Fragment>
    )
  }
}

export default SiteManager
