import React, { Component } from 'react';
import { Row, Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import MyToolTip from '../../indicators/mytooltip.js'

var mapToFormat = require('./mapToFormat.js');

import api from '../../util/api.js';

const { deleteSchedule } = api;

class ScheduleVert extends React.Component {
  handleDelete = (e) => {
    deleteSchedule(this.props.scheduleObj)
    .then((res) => {
      this.props.handleDelete();
    })
  }

  // The argument 'day' is an array of periods.
  // A period consists of a 'start_time' and a 'temp_set'.
  createDayElement = (dayHeatArr, dayCoolArr, dayLabel) => {
    //  Map periods onto elements.
    let periodElements = dayHeatArr.map((period, index) => {
      if(this.props.isLoadedSchedule){
        var heat = (parseInt(period.temp_set)/2).toString();
        var cool = (parseInt(dayCoolArr[index].temp_set)/2).toString();
        var setpoint = (parseInt(period.target_temp)/2).toString();
      }
      else{
        var heat = period.temp_set;
        var cool = dayCoolArr[index].temp_set;
        var setpoint = (((parseInt(heat)) + (parseInt(cool)))/2).toString();
      }
      let tooltip = (
        <Tooltip id="tooltip">
          <div style={{ fontSize: "14px" }}>Period {index + 1}</div>
          <span style={{ color: "#EF9A9A", fontSize: "16px" }}>{heat}&deg; </span>
          <span style={{ color: "#81D4FA", fontSize: "16px" }}>{cool}&deg;</span> {/* Grab setpoint from other schedule*/}
        </Tooltip>
      );
      return (
        <Col md={ 3 } sm={ 3 } xs={ 3 } style={{paddingTop:'16px'}}>
          <center style={{ fontSize: "14px", fontWeight: "600" }}>{period.start_time}</center>
          <div>
            <OverlayTrigger placement="top" overlay={tooltip}>
              <center style={{ color: "#39AF39", fontSize: "16px", textDecoration: "underline" }} >{setpoint}&deg; </center>
            </OverlayTrigger>
          </div>
        </Col>
      )
    });

    //  If it's a 2-period schedule, throw some more columns on either side to center things.
    if (dayHeatArr.length === 2) {
      periodElements.unshift( <Col md={ 3 } sm={ 3 } xs={ 12 }></Col> );
      periodElements.push( <Col md={ 3 } sm={ 3 } xs={ 12 }></Col> );
    }

    //  Return a table element with our day name and period elements.
    return (
      <Row style={{marginBottom: '16px'}}>
        <h5>{dayLabel}</h5>
        <Row>{periodElements}</Row>
      </Row>
    )
  }

  render() {
    let scheduleObj = this.props.scheduleObj;
    if (!scheduleObj) return (
      <center>
        <b>{"No Schedule Selected"}</b>
      </center>
    );

    let dayElements = [];
    if (scheduleObj.schedule_config == "7") {
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["mo"],
                                              scheduleObj.coolSchedule["mo"],
                                              "Monday"));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["tu"],
                                              scheduleObj.coolSchedule["tu"],
                                              "Tuesday"));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["we"],
                                              scheduleObj.coolSchedule["we"],
                                              "Wednesday"));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["th"],
                                              scheduleObj.coolSchedule["th"],
                                              "Thursday"));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["fr"],
                                              scheduleObj.coolSchedule["fr"],
                                              "Friday"));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["sa"],
                                              scheduleObj.coolSchedule["sa"],
                                              "Saturday"));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["su"],
                                              scheduleObj.coolSchedule["su"],
                                              "Sunday"));
    }
    else if (scheduleObj.schedule_config == "5+1+1") {
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["mo-fr"],
                                              scheduleObj.coolSchedule["mo-fr"],
                                              "Monday - Friday"));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["sa"],
                                              scheduleObj.coolSchedule["sa"],
                                              "Saturday"));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["su"],
                                              scheduleObj.coolSchedule["su"],
                                              "Sunday"));
    }

    return (
      <Col md={ 12 } sm={ 12 } xs={ 12 }>
        {/* Name and Activate Button */}
        <Row>
          <h2>{this.props.scheduleObj.name}</h2>
        </Row>
        {/* Type and Temperature Units */}
        <Row>
          <center>
            <div>
              <b>Type:</b> {this.props.scheduleObj.schedule_config}
            </div>
            <div>
              <b>Temperature Units:</b> {this.props.scheduleObj.t_units}&deg;
            </div>
          </center>
        </Row>
        {/* Schedule Contents */}
        <Row style={{ marginTop: '16px' }}>
          <center>
            {dayElements}
          </center>
        </Row>
      </Col>
    )
  }
}

export default ScheduleVert;
