import React, { Component } from 'react';
import { Row, Col, Button, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import Knob from './knob.js';
import Spinner from '../../indicators/spinner.js'
import CommandSpinner from '../../indicators/commandspinner.js'

import constants from '../../../config/constants.js'
import api from '../../util/api.js';

const { setSetpoint } = api;

class ChangeSetpoint extends React.Component {
  constructor() {
    super();

    this.state = {
      knobValue: 70,
      heatSetValue: 64,//disabled={this.props.deviceLiveData ? false : true}
      coolSetValue: 76,
      apiCalling: false,
      activeCommandId: '',
      mode: '',
      hasBeenUsed: false,
    }
  }

  handleModeChange = (value) => {
    this.setState({ mode: value });
  }

  convertFahrenheitToCelsius = (f) => {
    return Math.round((f - 32) * 5 / 9);
  }

  convertCelsiusToFahrenheit = (c) => {
    return Math.round(c * 9 / 5 + 32);
  }

  componentDidUpdate(prevProps) {
    //  Three conditions:
    //    - Switch hasn't been used.
    //    - LiveData is coming down as a prop
    //    - LiveData has changed
    //  The outcome:
    //    - Switch state is decided by that live data (i.e. device state)
    if (!this.state.hasBeenUsed && this.props.deviceLiveData &&
      this.props.deviceLiveData != prevProps.deviceLiveData) {
      this.setState({
        heatSetValue: Math.floor(this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.HEAT_SETPOINT]),
        coolSetValue: Math.floor(this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.COOL_SETPOINT]),
      });
        // if (this.props.deviceLiveData.tstat_read_data.fan_mode === "AUTO") {
      //   this.setState({ fanSwitch: false });
      // }
      // else if (this.props.deviceLiveData.tstat_read_data.fan_mode === "ON") {
      //   this.setState({ fanSwitch: true });
      // }
    }
  }

  // handleChangeKnobValue = (v) => {

  //   console.log("handleChangeKnobValue called with value: " + v.target.value);
  //   let value = parseFloat(v.target.value); 

  //   let max = 100.0;
  //   let min = 0.0;

  //   if (this.props.deviceLiveData) {
  //     //tstat_mode is now op_mode, aka constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]
  //     console.log("this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]]: ", this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]]);
  //     if (this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]] == "H") {
  //       //cool_setpoint is constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.COOL_SETPOINT]
        
  //       console.log("it is in heat mode, checking what cool setpoint is: " + this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.COOL_SETPOINT]]);

  //       max = parseFloat(this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.COOL_SETPOINT]]) - 4.0;

  //       console.log("it is in heat mode, checking what max is: ", max);

  //       min = 48.0;

  //       console.log("it is in heat mode, checking what min is: ", min);
  //     }
  //     else if (this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]] == "C") {
  //       min = parseFloat(this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.HEAT_SETPOINT]]) + 4.0;
  //       max = 90.0;
  //     }
  //   }

  //   if (value > max) value = max;
  //   if (value < min) value = min;

  //   this.setState({
  //     knobValue: value
  //   })
  // }

  handleChangeHeatSet = (v) => {
    let value = parseFloat(v.target.value);
    let max = 100.0;
    let min = 0.0;

    // if (this.props.deviceLiveData) {
    //   //tstat_mode is now op_mode, aka constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]
    //   console.log("this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]]: ", this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]]);
    //   if (this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]] == "H") {
    //     //cool_setpoint is constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.COOL_SETPOINT]
        
    //     console.log("it is in heat mode, checking what cool setpoint is: " + this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.COOL_SETPOINT]]);

    //     max = parseFloat(this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.COOL_SETPOINT]]) - 4.0;

    //     console.log("it is in heat mode, checking what max is: ", max);

    //     min = 48.0;

    //     console.log("it is in heat mode, checking what min is: ", min);
    //   }
    //   else if (this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]] == "C") {
    //     min = parseFloat(this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.HEAT_SETPOINT]]) + 4.0;
    //     max = 90.0;
    //   }
    // }

    if (value > max) value = max;
    if (value < min) value = min;

    this.setState({
      heatSetValue: value,
      hasBeenUsed: true,
    })
  }

  handleChangeCoolSet = (v) => {
    let value = parseFloat(v.target.value);
    let max = 100.0;
    let min = 0.0;

    // if (this.props.deviceLiveData) {
    //   //tstat_mode is now op_mode, aka constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]
    //   console.log("this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]]: ", this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]]);
    //   if (this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]] == "H") {
    //     //cool_setpoint is constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.COOL_SETPOINT]

    //     console.log("it is in heat mode, checking what cool setpoint is: " + this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.COOL_SETPOINT]]);

    //     max = parseFloat(this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.COOL_SETPOINT]]) - 4.0;

    //     console.log("it is in heat mode, checking what max is: ", max);

    //     min = 48.0;

    //     console.log("it is in heat mode, checking what min is: ", min);
    //   }
    //   else if (this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE]] == "C") {
    //     min = parseFloat(this.props.deviceLiveData.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.HEAT_SETPOINT]]) + 4.0;
    //     max = 90.0;
    //   }
    // }

    if (value > max) value = max;
    if (value < min) value = min;

    this.setState({
      coolSetValue: value,
      hasBeenUsed: true
    })
  }

  handleSubmit = () => {
    if(Math.abs(this.state.heatSetValue - this.state.coolSetValue) < 4){
      // console.log("You do not have a 4 degree differential between your heat and cool setpoints. Please adjust your setpoints and try again.");
      alert("You do not have a 4 degree differential between your heat and cool setpoints. Please adjust your setpoints and try again.");
      return;
    }
    this.setState({ apiCalling: true });
    setSetpoint(this.props.siteID, this.props.deviceID,
      "H", this.state.heatSetValue)
    .then(res => {
      console.log("and then....");
      return setSetpoint(this.props.siteID, this.props.deviceID,
        "C", this.state.coolSetValue);
    })
    .then(res => {
      console.log(res);
      this.setState({
        apiCalling: false,
        activeCommandId: res.data.commandId,
      });
    })
    .catch((e) => {
      console.log(e);
      this.setState({ apiCalling: false });
    });
  }

  clearActiveCommandId = () => {
    console.log("ChangeSetpoint: Cleared active command ID.");
    this.setState({
      activeCommandId: "",
    })
  }

  render() {
    //  Determine knob color.
    let knobColor = "#000000";
    if (this.state.mode == "HEAT") knobColor = "#E74C3C";
    else if (this.state.mode == "COOL") knobColor = "#3498DB";
    else if (this.props.deviceLiveData) { // If no radio button chosen, defer to the device for mode.
      if (this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE] == "HEAT") {
        knobColor = "#E74C3C";
      }
      else if (this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE] == "COOL") knobColor = "#3498DB";
    }

    //  Determine default value of knob.
    let defaultValue = 70;
    if(this.props.deviceLiveData && this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.TEMPERATURE_DISPLAY_UNITS] == "C")
      defaultValue = this.convertFahrenheitToCelsius(defaultValue);

    if (this.props.deviceLiveData) {
      if (this.state.mode == "HEAT") {
        defaultValue = this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE];
      }
      else if (this.state.mode == "COOL") {
        defaultValue = this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE];
      }
      else { // If no radio button chosen, defer to the device for setpoint.
        if (this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE] == "HEAT")
          defaultValue = this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE];
        else if (this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE] == "COOL")
          defaultValue = this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MODE];
      }
    }

    return (
      <center>
        {/*
        <Knob min="0" max="100" size="150" program="COOL"
          value={this.state.knobValue}
          changeValue={this.handleChangeKnobValue}
          color={knobColor}
          defaultValue={defaultValue}
          mode={this.props.deviceLiveData ? this.props.deviceLiveData.tstat_read_data.tstat_mode: "..."}
        />
        */}
        <Row>
          <Col md={ 6 } sm={ 6 } xs={ 12 }>
            <div>
              <h1 style={{color: "red"}}>HEAT</h1>
              {/* <ToggleButtonGroup type="radio" name="options" vertical={false} onChange={this.handleModeChange} defaultValue={this.state.opMode}>
                <ToggleButton value={"HEAT"}>Heat</ToggleButton>
                <ToggleButton value={"COOL"}>Cool</ToggleButton>
              </ToggleButtonGroup> */}
            </div>
            <Row>
              <input type="number" onChange={this.handleChangeHeatSet} value={this.state.heatSetValue} style={{textAlign: "center", marginTop: "8px"}}/> {/*°F*/}
            </Row>
            
          </Col>
          <Col md={ 6 } sm={ 6 } xs={ 12 }>
            <div>
              <h1 style={{color: "rgb(38, 147, 230)"}}>COOL</h1>
            </div>
            <Row>
              <input type="number" onChange={this.handleChangeCoolSet} value={this.state.coolSetValue} style={{textAlign: "center", marginTop: "8px"}}/> {/*°F*/}
            </Row>
          </Col>
        </Row>
        <Row>
          <Button style={{"marginTop": "8px"}} type="button" className="btn btn-default" onClick={this.handleSubmit}>Submit</Button>
        </Row>
        <Row>
          <Spinner active={this.state.apiCalling}/>
            <CommandSpinner
            
            commandId={ this.state.activeCommandId } clearActiveCommandId={this.clearActiveCommandId}
            siteID={this.props.siteID} deviceID={this.props.deviceID}
            getLiveDataAfterTime={this.props.getLiveDataAfterTime}/>
        </Row>
      </center>
    )
  }
}

export default ChangeSetpoint;
