import React, { Component } from 'react';
import Gauge from './gauge.js';//'react-radial-gauge';
import ReactDOM from 'react-dom';

class Knob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseX: 0,
      mouseY: 0,
      dragging: false,
      hasBeenDragged: false,
    }
  }

  /*componentWillReceiveProps(nextProps) {
    if (nextProps === this.props) return;
    else {
      this.setState({
        value: this.props.defaultValue
      })
    }
  }*/

  evaluateInput = () => {
    this.setState({
      hasBeenDragged: true
    });

    const position = ReactDOM.findDOMNode(this.refs.gaugeWrapper).getBoundingClientRect();

    let centerPoint = {
      x : position.width/2, // 75
      y : position.height/2 // 75
    }

    let currMousePoint = {
      x : this.state.mouseX,
      y : this.state.mouseY
    }

    let buildTriangle = (x1, x2, y1, y2) => {
      let adjacentLength = Math.abs(x2 - x1);
      let oppositeLength = Math.abs(y2 - y1);
      let hypotenuseLength = Math.hypot(adjacentLength, oppositeLength);
      let angle = Math.acos(adjacentLength/hypotenuseLength)
      return {
        adjacentLength: adjacentLength,
        oppositeLength: oppositeLength,
        hypotenuseLength: hypotenuseLength,
        angle: angle * 57.2958// degrees
      }
    }

    let currTriangle = buildTriangle(this.state.mouseX, centerPoint.x, this.state.mouseY, centerPoint.y);

    // A fraction of how far we are in the X direction relative to widget size
    let widgetRatioX = this.state.mouseX/position.width;
    // A fraction of how far we are in the Y direction relative to widget size.
    let widgetRatioY = this.state.mouseY/position.height;

    let value;
    // If we're on the upper right half of the unit circle...
    if (widgetRatioX > .5 && widgetRatioY <= .5) {
      value = 0 + (90 - currTriangle.angle);
    }
    // Lower right
    else if (widgetRatioX > .5 && widgetRatioY > .5) {
      value = 90 + currTriangle.angle;
    }
    // lower left
    else if (widgetRatioX <= .5 && widgetRatioY > .5) {
      value = 180 + (90 - currTriangle.angle);
    }
    // Upper left
    else if (widgetRatioX <= .5 && widgetRatioY <= .5) {
      value = 270 + currTriangle.angle;
    }
    value = value / 360 * this.props.max;
    this.props.changeValue(value.toFixed(0));
  }

  _onMouseMove = (e) => {

    this.setState({
      mouseX: e.nativeEvent.offsetX,
      mouseY: e.nativeEvent.offsetY
    }, function() {
      if (this.state.dragging) {
        this.evaluateInput();
      }
    });
  }

  _onMouseDown = (e) => {
    this.setState({
      dragging: true
    });
    this.evaluateInput();
  }

  _onMouseUp = (e) => {
    this.setState({dragging: false});
  }

  _onMouseLeave = (e) => {
    this.setState({dragging: false});
  }

  render() {
    let currentValue;
    if (this.state.hasBeenDragged) {
      currentValue = this.props.value;
    }
    else currentValue = this.props.defaultValue;

    let opts = {
      currentValue: currentValue,
      size: this.props.size,
      progressColor: this.props.color,
      needleWidth: 0,
      progressWidth: 6
    }

    let divStyle = {
      height: opts.size + "px",
      width: opts.size + "px",
      userDrag: "none"
    }

    const { x, y } = this.state;
    return(
      <div ref="gaugeWrapper" style={divStyle} onMouseMove={this._onMouseMove}
        onMouseDown={this._onMouseDown}
        onMouseUp={this._onMouseUp}
        onMouseLeave={this._onMouseLeave} >
        <Gauge {...opts}/>
      </div>
    )
  }
}

export default Knob;
