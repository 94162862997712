import React, { Component } from 'react';
// import { Row, Col, Button } from 'react-bootstrap';
import { Row, Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import HolidayScheduleHoriz from '../schedules/holidayschedulehoriz.js';
import {XPanel, PageTitle} from '../../components';
import DatePicker from "react-datepicker";
import Spinner from '../../indicators/spinner.js';
import * as moment from 'moment'

import HolidayScheduleDetails from '../schedules/holidayscheduledetails.js';

import api from '../../util/api.js';

const { deleteHolidaySchedule } = api;

class HolidayScheduleEventsWrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      apiCalling: false,
      failCalling: false,
    }
  }

  handleDelete = (e) => {
      this.setState({ 
        failCalling: false,
        apiCalling: true,
       });
      deleteHolidaySchedule(this.props.scheduleObj._id, this.props.scheduleObj.name)
      .then((res) => {
        console.log(res);
        this.props.handleRefreshSchedules();
        this.setState({
          apiCalling: false,
          failCalling: false,
        });
      })
      .catch((err) => {
        console.log("delete schedule error");
        console.log(err);
        this.setState({
          apiCalling: false,
          failCalling: true,
        });
      });
  }

  getDeleteSiteButtonJsx = (siteId, scheduleObj) => {
    return(
      <div>
        <h1>{scheduleObj.name}<Button className={'btn-danger'} style={{marginLeft: "10px"}} onClick={this.handleDelete}>DELETE</Button></h1>
        
      </div>
    );
  }

  render() {
    let scheduleElements;
    if (this.props.didApiFetch === false) {
      scheduleElements = <div style={{ fontSize: "16px" }} ><br/></div>
    }
    else if (this.props.scheduleObjs == undefined || this.props.scheduleObjs.length === 0) {
      scheduleElements = <div style={{ fontSize: "16px" }} ><br/>No holiday schedules found.</div>
    }
    else {
      scheduleElements = this.props.scheduleObjs.map((scheduleObj, index) => {
        return (
          <Row style={{ paddingBottom: '32px' }} md={ 12 } sm={ 12 } xs={ 12 }>
            <XPanel>
              {/* <XPanel.Title title={this.getDeleteSiteButtonJsx(this.props.siteID, scheduleObj)}></XPanel.Title> */}
              <XPanel.Content>
                {this.getDeleteSiteButtonJsx(this.props.siteID, scheduleObj)}
              <Row>
                <Col md={ 12 } sm={ 12 } xs={ 12 } style={{width: "100%"}}>
                  {/* <Row>
                    <b>Schedule Start Datetime: </b>{this.props.scheduleObj.startDatetime}
                  </Row>
                  <Row>
                    <b>Schedule End Datetime: </b>{this.props.scheduleObj.endDatetime}
                  </Row> */}
                  <Row>
                    <center>
                      Date range for this holiday schedule:

                      <h3><b>{moment(scheduleObj.startDatetime).format("YYYY/MM/DD")}</b> - <b>{moment(scheduleObj.endDatetime).format("YYYY/MM/DD")}</b></h3>
                    </center>
                  </Row>
                </Col>
              </Row>
              <Row>
                <center>
                  <DatePicker
                    selectsRange={true}
                    startDate={new Date(scheduleObj.startDatetime)}
                    endDate={new Date(scheduleObj.endDatetime)}
                    placeholderText="Click to select a date"
                    monthsShown={moment(scheduleObj.endDatetime).month() - moment(scheduleObj.startDatetime).month() + 1}
                    disabled
                    disabledKeyboardNavigation
                    selectsDisabledDaysInRange
                    onChange={(update) => {
                      //setDateRange(update);
                      // this.setState({
                      //   startDatetime: update[0],
                      //   endDatetime: update[1]
                      // }, () => {

                      //   //Second item was selected, draw this date
                      //   // if(update[1] !== null) {
                      //   //   this.handleTimeChange(this.state.startDatetime, this.state.endDatetime);
                      //   // }
                      // });
                      //console.log("update: ", update);
                    }}
                    isClearable={true}
                    inline
                  />
                </center>
              </Row>
              <Col md={ 12 } sm={ 12 } xs={ 12 } style={{width: "100%"}}>
                <HolidayScheduleDetails scheduleObj={scheduleObj} />
              </Col>
              <Col md={ 1 } sm={ 1 } xs={ 1 }>

                <Spinner active={this.state.apiCalling} fail={this.state.failCalling}/>
              </Col>

              </XPanel.Content>
            </XPanel>

          </Row>
        )
      })
    }

    return (
      <Col>
        {scheduleElements}
      </Col>
    )
  }
}

export default HolidayScheduleEventsWrapper;
