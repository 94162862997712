import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import classnames from 'classnames';
import Spinner from '../../indicators/spinner.js';
import CommandSpinner from '../../indicators/commandspinner.js';

import api from '../../util/api.js';

const { setEngagedTransaction } = api;

class EngagedTransaction extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      initialFetch: false,
      etInterval: 60,
      lastUpdateTimeUI: new Date(),
      currDate: new Date(),
      apiCalling: false,
      activeCommandId: '',
    }
  }

  componentDidMount() {
    let regCheckDate = setInterval(this.getDate, 1000);
    this.setState({
      regCheckDate: regCheckDate
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lastUpdateTime != this.props.lastUpdateTime) {
      this.setState({
        lastUpdateTimeUI: new Date(),
        lastUpdateTime: nextProps.lastUpdateTime,
        initialFetch: true
      })
    }
  }

  handleShow = (e) => {
    this.setState({show: true});
  }

  handleClose = (e) => {
    this.setState({show: false});
  }

  handleSubmitEt = (e) => {
    this.setEngagedTransaction(this.state.etInterval);
  }

  handleEtChange = (e) => {
    this.setState({
      etInterval: e.target.value
    })
  }

  getDate = () => {
    this.setState({
      currDate: Date.now() // This is milliseconds in UTC as per MDN web docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/now
    })
  }

  clearActiveCommandId = () => {
    console.log("ChangeOpMode: Cleared active command ID.");
    this.setState({
      activeCommandId: "",
    })
  }

  setEngagedTransaction = (seconds) => {
    this.setState({ apiCalling: true });
    setEngagedTransaction(this.props.siteID, this.props.deviceID, seconds)
    .then(res => {
      this.setState({
        apiCalling: false,
      })
      return res;
    })
    .then(res => {
      console.log(res);
      this.setState({
        activeCommandId: res.data.commandId
      });
    });
  }

  clearActiveCommandId = () => {
    console.log("ChangeOpMode: Cleared active command ID.");
    this.setState({
      activeCommandId: "",
    })
  }

  render() {
    //console.log("Current date:");
    //console.log(this.state.currDate);
    //console.log("Last update:");
    //console.log(Date.parse(this.props.lastUpdateTime));
    
    let msSinceUpdate = this.state.currDate - Date.parse(this.state.lastUpdateTime);
    let sSinceUpdate = (msSinceUpdate/1000).toFixed(0);
    let isOnline;
    //  If we have an etValue, grab it. Otherwise, use 120.
    let etValue = this.props.currentEngagedTransactionInterval ? this.props.currentEngagedTransactionInterval : 120;
    isOnline = sSinceUpdate < 5 * etValue ? true : false;
    //console.log("isOnline: " + isOnline);
    //console.log("etValue: " + etValue);
    //console.log("sSinceUpdate: " + sSinceUpdate);

    let indicatorColor = isOnline ? "green" : "red";

    return (
      <span>
        {
            this.state.initialFetch ?
            <i className="fa fa-circle"
            style={{color: indicatorColor, fontSize: "2em", margin: "4px"}}/>
            :
            <i className="fa fa-circle"
              style={{color: indicatorColor, fontSize: "2em", margin: "4px", visibility: "hidden"}}/>
        }
        <Button onClick={this.handleShow}>Set ET</Button>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Engaged Transaction Interval</Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass={'samModalBody'}>
            <center className="centerTable">
              <div className="tableCell">
                <h4>This device is { isOnline ? "online" : "offline" } and we last heard from it {sSinceUpdate} seconds ago.</h4>
                <h4>Update your check-in interval below. This is how often your thermostat checks in with the cloud to report data and receive commands.</h4>
                <h4>Current check-in interval is <b>{this.props.currentEngagedTransactionInterval} seconds.</b></h4>
                <br/>
                <input type="number" onChange={this.handleEtChange} value={this.state.etInterval}/> seconds
                <br/><br/>
                <Spinner active={ this.state.apiCalling } />
                <CommandSpinner
                  commandId={ this.state.activeCommandId } clearActiveCommandId={this.clearActiveCommandId}
                  siteID={this.props.siteID} deviceID={this.props.deviceID}
                  getLiveDataAfterTime={this.props.getLiveDataAfterTime}
                />
                <Button bsStyle="primary" bsSize="small" onClick={this.handleSubmitEt}>Ok</Button>
              </div>
            </center>
          </Modal.Body>
        </Modal>
      </span>
    )
  }
}

export default EngagedTransaction;
