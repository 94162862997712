import React from 'react'
import { GroupMenuItem, MenuItem } from '../components/SideBar'
import SideNavSiteUnorderedList from './sidenav/SideNavSiteUnorderedList.js'

class SideNavSitesAndDevices extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      user: nextProps.user,
    })
  }

  render() {
    var siteListItems = <div></div>
    if (this.state.user && this.state.user.resolvedPortalSites) {
      siteListItems = this.state.user.resolvedPortalSites.map((resolvedPortalSite, i) => {
        {
          /*TODO: find a better way to pass these properties to SideNavSiteUnorderedList children, potentially via function like they did
						with childrenWithProps*/
        }
        return (
          <SideNavSiteUnorderedList
            isActive={this.props.isActive}
            isActiveSection={this.props.isActiveSection}
            toggle={this.props.toggle}
            shared={this.props.shared}
            key={resolvedPortalSite._id}
            title={resolvedPortalSite.name}
            resolvedPortalSite={resolvedPortalSite}
            icon='home'
            to='/home'
          />
        )
      })
    }
    return (
      <React.Fragment>
        <MenuItem
          isActive={this.props.isActive}
          isActiveSection={this.props.isActiveSection}
          toggle={this.props.toggle}
          shared={this.props.shared}
          title={'Main Dashboard'}
          to={`/dashboard`}
        />
        <MenuItem
          isActive={this.props.isActive}
          isActiveSection={this.props.isActiveSection}
          toggle={this.props.toggle}
          shared={this.props.shared}
          title={'Site Manager'}
          to={`/sitemanager`}
        />
        <MenuItem
          isActive={this.props.isActive}
          isActiveSection={this.props.isActiveSection}
          toggle={this.props.toggle}
          shared={this.props.shared}
          title={'Schedule Manager'}
          to={`/schedulemanager`}
        />
        {siteListItems}
      </React.Fragment>
    )
  }
}

export default SideNavSitesAndDevices
