import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

class MenuItem extends Component {
  render () {
    const { title, isActive, isActiveSection, toggle, ...rest } = this.props
    const { to } = rest

    return (
      <li><Link {...rest}>{title}</Link></li>
    )
  }
}

export default MenuItem
