import React, { Component } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import classnames from 'classnames';

const saveStyle = {
  fontSize: "2em",
  margin: "4px",
}

const saveClass = {
  'fa': true,
  'fa-floppy-o': true
}

class Save extends React.Component {
  render() {
    let tooltip = (
      <Tooltip id="tooltip">
        {this.props.tooltip}
      </Tooltip>
    );

    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        <Button onClick={ this.props.onSave }>
          <i style={saveStyle} className={classnames(saveClass)}></i>
        </Button>
      </OverlayTrigger>
    )
  }
}

export default Save;
