import React, { Component } from 'react';
import Slider from 'react-slick';

class SlidesTest extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <Slider {...settings}>
        <div><h3>HELLO</h3><h3>HELLO</h3><h3>HELLO</h3><h3>HELLO</h3><h3>HELLO</h3></div>
        <div><h3>HELLO</h3></div>
        <div><h3>HELLO</h3></div>
        <div><h3>HELLO</h3></div>
        <div><h3>HELLO</h3></div>
        <div><h3>HELLOs</h3></div>
      </Slider>
    );
  }
}

export default SlidesTest;
