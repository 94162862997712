import React, { Component } from 'react'
import { Row, Col, Button } from 'react-bootstrap'

//import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
//User CSS
import './Dashboard.css'

import constants from '../../../config/constants.js'

//User components
//import DashboardSiteDeviceCellDatum from './DashboardSiteDeviceCellDatum';

class DasboardBulkInputs extends React.Component {
  constructor(props) {
    super(props)
    this.setpoint_options = [
      '45',
      '46',
      '47',
      '48',
      '49',
      '50',
      '51',
      '52',
      '53',
      '54',
      '55',
      '56',
      '57',
      '58',
      '59',
      '60',
      '61',
      '62',
      '63',
      '64',
      '65',
      '66',
      '67',
      '68',
      '69',
      '70',
      '71',
      '72',
      '73',
      '74',
      '75',
      '76',
      '77',
      '78',
      '79',
      '80',
      '81',
      '82',
      '83',
      '84',
      '85',
      '86',
      '87',
      '88',
      '89',
      '90',
      '91',
      '92',
      '93',
      '94',
      '95',
    ]
    //'HEAT' && mode !== 'COOL' && mode !== 'AUTO' && mode !== 'OFF')
    this.mode_options = ['HEAT', 'COOL', 'AUTO']
    this.fan_mode_options = ['AUTO', 'ON']

    this.state = {
      mode: this.mode_options[0],
      fan_mode: this.fan_mode_options[0],
      heat_setpoint: props.averageHeatSet || this.setpoint_options[0],
      cool_setpoint:  props.averageColdSet || this.setpoint_options[0],
      schedule: this.props.thermostatschedules[0],
      heat_setpoint_enable: false,
      cool_setpoint_enable: false,
      mode_enable: false,
      fan_mode_enable: false,
      schedule_enable: false,
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.averageHeatSet !== this.state.heat_setpoint) {
  //     this.setState({
  //       heat_setpoint: nextProps.averageHeatSet,
  //     })
  //   }
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log('componentDidUpdate')
  // }

  convertHumanModeValueToThermostatCommandValue(mode_state) {
    switch (mode_state) {
      case 'HEAT':
        return 'H'
        break
      case 'COOL':
        return 'C'
        break
      case 'AUTO':
        return 'A'
        break
    }
  }

  convertHumanFanModeValueToThermostatCommandValue(fan_mode_state) {
    switch (fan_mode_state) {
      case 'AUTO':
        return 'A'
        break
      case 'ON':
        return 'ON'
        break
    }
  }

  handleEnableChange = (ev) => {
    console.log('checkbox chexked:')
    console.log(ev.target.value)
    console.log(ev.target.id)

    switch (ev.target.id) {
      case 'heat_setpoint_enable':
        this.setState({ heat_setpoint_enable: ev.target.checked })
        break
      case 'cool_setpoint_enable':
        this.setState({ cool_setpoint_enable: ev.target.checked })
        break
      case 'mode_enable':
        this.setState({ mode_enable: ev.target.checked })
        break
      case 'fan_mode_enable':
        this.setState({ fan_mode_enable: ev.target.checked })
        break
      case 'schedule_enable':
        this.setState({ schedule_enable: ev.target.checked })
        break
    }
  }

  selectOption = (ev) => {
    console.log('selectOption: ', ev.target.id)
    switch (ev.target.id) {
      case 'heat_setpoint_button':
        this.setState({ heat_setpoint: ev.target.value })
        break
      case 'cool_setpoint_button':
        this.setState({ cool_setpoint: ev.target.value })
        break
      case 'mode_button':
        this.setState({ mode: ev.target.value })
        break
      case 'fan_mode_button':
        this.setState({ fan_mode: ev.target.value })
        break
      case 'schedule_button':
        this.setState({ schedule: ev.target.value })
        break
    }

    //this.setState({mode: ev.target.value});
  }

  render() {
    return (
      <section>
        <form>
        <div>
          <Row>
            <Col md={2} sm={2} xs={2}>
              <div className='form-group'>
                <Row>
                  <Col md={4} sm={4} xs={4}>
                    <label htmlFor='ex4' className='col-form-label'>
                      Heat Setpoint
                    </label>
                  </Col>
                  <Col md={8} sm={8} xs={8}>
                    <form autoComplete='off' autoCorrect='none'>
                      {/*<input id="heat_setpoint_input" type="text" onChange={(e) => {this.selectOption(e)}} value={this.state.heat_setpoint} className="col-sm-6 custom-select custom-select-sm"/>*/}

                      <select
                        id='heat_setpoint_button'
                        onChange={(e) => {
                          this.selectOption(e)
                        }}
                        value={this.state.heat_setpoint}
                        className='col-sm-12 custom-select custom-select-sm'
                        disabled={!this.state.heat_setpoint_enable}
                      >
                        {this.setpoint_options.map((optionString, index) => (
                          <option key={index} value={optionString}>
                            {optionString}° F
                          </option>
                        ))}
                      </select>
                    </form>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={2} sm={2} xs={2}>
              <div className='form-group'>
                <Row>
                  <Col md={4} sm={4} xs={4}>
                    <label htmlFor='ex4' className='col-form-label'>
                      Cool Setpoint
                    </label>
                  </Col>
                  <Col md={8} sm={8} xs={8}>
                    <select
                      id='cool_setpoint_button'
                      onChange={(e) => {
                        this.selectOption(e)
                      }}
                      value={this.state.cold_setpoint}
                      className='col-sm-12 custom-select custom-select-sm'
                      disabled={!this.state.cool_setpoint_enable}
                    >
                      {this.setpoint_options.map((optionString, index) => (
                        <option key={index} value={optionString}>
                          {optionString}° F
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={2} sm={2} xs={2}>
              <div className='form-group'>
                <Row>
                  <Col md={4} sm={4} xs={4}>
                    <label htmlFor='ex4' className='col-form-label'>
                      Mode
                    </label>
                  </Col>
                  <Col md={8} sm={8} xs={8}>
                    <select
                      id='mode_button'
                      onChange={(e) => {
                        this.selectOption(e)
                      }}
                      className='col-sm-12 custom-select custom-select-sm'
                      disabled={!this.state.mode_enable}
                    >
                      {this.mode_options.map((optionString, index) => (
                        <option key={index} value={optionString}>
                          {optionString}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={2} sm={2} xs={2}>
              <div className='form-group'>
                <Row>
                  <Col md={4} sm={4} xs={4}>
                    <label htmlFor='ex4' className='col-form-label'>
                      Fan Mode
                    </label>
                  </Col>
                  <Col md={8} sm={8} xs={8}>
                    <select
                      id='fan_mode_button'
                      onChange={(e) => {
                        this.selectOption(e)
                      }}
                      className='col-sm-12 custom-select custom-select-sm'
                      disabled={!this.state.fan_mode_enable}
                    >
                      {this.fan_mode_options.map((optionString, index) => (
                        <option key={index} value={optionString}>
                          {optionString}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={2} sm={2} xs={2}>
              <div className='form-group'>
                <Row>
                  <Col md={4} sm={4} xs={4}>
                    <label htmlFor='ex4' className='col-form-label'>
                      Schedule
                    </label>
                  </Col>
                  <Col md={8} sm={8} xs={8}>
                    <select
                      id='schedule_button'
                      onChange={(e) => {
                        this.selectOption(e)
                      }}
                      className='col-sm-12 custom-select custom-select-sm'
                      disabled={!this.state.schedule_enable}
                    >
                      {this.props !== undefined && this.props.thermostatschedules !== undefined ? (
                        this.props.thermostatschedules.map((schedule, index) => {
                          return (
                            <option key={index} value={schedule.name}>
                              {schedule.name}
                            </option>
                          )
                        })
                      ) : (
                        <option value=''>No Schedules</option>
                      )}
                    </select>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={2} sm={2} xs={2}>
              <Button
                onClick={(e) => {
                  this.props.handleClick(e, {
                    ...(this.state.heat_setpoint_enable && { heat_setpoint: this.state.heat_setpoint }),
                    ...(this.state.cool_setpoint_enable && { cool_setpoint: this.state.cool_setpoint }),
                    ...(this.state.mode_enable && {
                      mode: this.convertHumanModeValueToThermostatCommandValue(this.state.mode),
                    }),
                    ...(this.state.fan_mode_enable && {
                      fan_mode: this.convertHumanFanModeValueToThermostatCommandValue(this.state.fan_mode),
                    }),
                    ...(this.state.schedule_enable && { schedule: this.state.schedule._id }),
                  })
                }}
              >
                Submit
              </Button>

              {}

              {this.props.children}
            </Col>
          </Row>

          <Row>
            <Col md={2} sm={2} xs={2}>
              <Col md={2} sm={2} xs={2} style={{ alignItems: 'flex-end' }}>
                <input
                  type='checkbox'
                  id='heat_setpoint_enable'
                  name='heat_setpoint_enable'
                  onClick={(e) => this.handleEnableChange(e)}
                  style={{ marginLeft: '1em' }}
                />
              </Col>
              <Col md={10} sm={10} xs={10}>
                <label htmlFor='heat_setpoint_enable'>Enable</label>
              </Col>
            </Col>

            <Col md={2} sm={2} xs={2}>
              <Col md={2} sm={2} xs={2} style={{ alignItems: 'flex-end' }}>
                <input
                  type='checkbox'
                  id='cool_setpoint_enable'
                  name='cool_setpoint_enable'
                  onClick={(e) => this.handleEnableChange(e)}
                  style={{ marginLeft: '1em' }}
                />
              </Col>
              <Col md={10} sm={10} xs={10}>
                <label htmlFor='cool_setpoint_enable'>Enable</label>
              </Col>
            </Col>

            <Col md={2} sm={2} xs={2}>
              <Col md={2} sm={2} xs={2} style={{ alignItems: 'flex-end' }}>
                <input
                  type='checkbox'
                  id='mode_enable'
                  name='mode_enable'
                  onClick={(e) => this.handleEnableChange(e)}
                  style={{ marginLeft: '1em' }}
                />
              </Col>
              <Col md={10} sm={10} xs={10}>
                <label htmlFor='mode_enable'>Enable</label>
              </Col>
            </Col>

            <Col md={2} sm={2} xs={2}>
              <Col md={2} sm={2} xs={2} style={{ alignItems: 'flex-end' }}>
                <input
                  type='checkbox'
                  id='fan_mode_enable'
                  name='fan_mode_enable'
                  onClick={(e) => this.handleEnableChange(e)}
                  style={{ marginLeft: '1em' }}
                />
              </Col>
              <Col md={10} sm={10} xs={10}>
                <label htmlFor='fan_mode_enable'>Enable</label>
              </Col>
            </Col>

            <Col md={2} sm={2} xs={2}>
              <Col md={2} sm={2} xs={2} style={{ alignItems: 'flex-end' }}>
                <input
                  type='checkbox'
                  id='schedule_enable'
                  name='schedule_enable'
                  onClick={(e) => this.handleEnableChange(e)}
                  style={{ marginLeft: '1em' }}
                />
              </Col>
              <Col md={10} sm={10} xs={10}>
                <label htmlFor='schedule_enable'>Enable</label>
              </Col>
            </Col>

            <Col md={2} sm={2} xs={2}></Col>
          </Row>

          
        </div>
        </form>
      </section>
    )
  }
}

export default DasboardBulkInputs
