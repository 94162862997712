import React, { Component } from 'react'
import DataAnalysis from './dataanalysis'

class DataAnalysisWrapper extends React.Component {
  render() {
    let { siteID, deviceID } = this.props.match.params
    const user = this.props.user || {}
    let deviceInfo = {}
    let portalInfo = {}
    if (user && user.resolvedPortalSites) {
      portalInfo = user.resolvedPortalSites.find((site) => site._id === siteID)
      console.log('portalInfo', portalInfo)
      if (portalInfo && portalInfo.resolvedSiteDevices) {
        deviceInfo = portalInfo.resolvedSiteDevices.find((device) => device._id === deviceID)
        console.log('deviceInfo', deviceInfo)
      }
    }

    if (!user.resolvedPortalSites) {
      return (
        <div>Loading...</div>
      )
    }

    return (
      <DataAnalysis 
        siteID={siteID} 
        deviceID={deviceID} 
        key={deviceID} 
        user={this.props.user}
        portalInfo={portalInfo}
        deviceInfo={deviceInfo}
      />
    )
  }
}

export default DataAnalysisWrapper
