import React, { Component } from 'react';
import {  Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
// import {Row, Col} from 'react-bootstrap-two';

class Days extends React.Component {

  //  If we have a 511 schedule, each of the 3 day elements will take
  //    2 column-width, and we get a total of 6.
  //  This funciton spits out some elements of column-width 1 to place
  //    on either side.
  createFillerElement = (size) => {
    return (
      <Col md={ size } sm={ size } xs={ size }>
      </Col>
    )
  }

  // The argument 'day' is an array of periods.
  // A period consists of a 'start_time' and a 'temp_set'.
  createDayElement = (dayHeatArr, dayCoolArr, dayLabel, schedule_config) => {
    //  Map periods onto elements.
    let periodElements = dayHeatArr.map((period, index) => {
      let tooltip;
      if (period.target_temp != -1) {
        tooltip = (
          <Tooltip id="tooltip">
            <div style={{ fontSize: "14px" }}>Period {index + 1}</div>
            <span style={{ color: "#EF9A9A", fontSize: "16px" }}>{period.temp_set}&deg; </span>
            <span style={{ color: "#81D4FA", fontSize: "16px" }}>{parseInt(dayCoolArr[index].temp_set).toFixed(0)}&deg;</span> {/* Grab setpoint from other schedule*/}
          </Tooltip>
        );
        return (
          <Col md={ 12 } sm={ 12 } xs={ 12 } style={{paddingTop:'16px'}}>
            <center style={{ fontSize: "14px", fontWeight: "600" }}>{period.start_time}</center>
            <div>
              <OverlayTrigger placement="top" overlay={tooltip}>
                <center style={{ color: "#39AF39", fontSize: "16px", textDecoration: "underline" }} >{parseInt(period.target_temp).toFixed(0)}&deg; </center>
              </OverlayTrigger>
            </div>
          </Col>
        )
      }
      else {
        tooltip = (
          <Tooltip id="tooltip">
            <div style={{ fontSize: "14px" }} >Period {index + 1}</div>
          </Tooltip>
        );
        return(
          <center>
            <div>
              <center style={{ fontSize: "14px", fontWeight: "600" }}>{period.start_time}</center>
              <div>
                <OverlayTrigger placement="top" overlay={tooltip}>
                  <center>
                    <span style={{ color: "#DD1515", fontSize: "16px" }}>{period.temp_set}&deg; </span>
                    <span style={{ color: "#2B5FD8", fontSize: "16px" }}>{dayCoolArr[index].temp_set}&deg;</span>
                  </center>
                </OverlayTrigger>
              </div>
            </div>
          </center>
        )
      }
    });

    let dayColumnSize = 1;
    if (schedule_config === "5+1+1") dayColumnSize = 2;

    //  Return a table element with our day name and period elements.
    return (
      <div md={ dayColumnSize } sm={ dayColumnSize } xs={ dayColumnSize } style={{backgroundColor: 'white', margin: '4px', padding: '12px'}}>
        <center><h4>{dayLabel}</h4></center>
        {periodElements}
        {/* {periodElements.map((periodElement, index) => {
          return (
            <div>
              {periodElement}
              <br/>
            </div>
          )
        })} */}
      </div>
    )
  }

  render() {
    let scheduleObj = this.props.scheduleObj;

    let dayElements = [];
    if (scheduleObj.schedule_config == "7") {
      //dayElements.push(this.createFillerElement(1));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["mo"],
                                              scheduleObj.coolSchedule["mo"],
                                              "Monday",
                                              scheduleObj.schedule_config));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["tu"],
                                              scheduleObj.coolSchedule["tu"],
                                              "Tuesday",
                                              scheduleObj.schedule_config));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["we"],
                                              scheduleObj.coolSchedule["we"],
                                              "Wednesday",
                                              scheduleObj.schedule_config));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["th"],
                                              scheduleObj.coolSchedule["th"],
                                              "Thursday",
                                              scheduleObj.schedule_config));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["fr"],
                                              scheduleObj.coolSchedule["fr"],
                                              "Friday",
                                              scheduleObj.schedule_config));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["sa"],
                                              scheduleObj.coolSchedule["sa"],
                                              "Saturday",
                                              scheduleObj.schedule_config));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["su"],
                                              scheduleObj.coolSchedule["su"],
                                              "Sunday",
                                              scheduleObj.schedule_config));
      
    }
    else if (scheduleObj.schedule_config == "5+1+1") {
      console.log(scheduleObj.heatSchedule['mo-fr']);
      dayElements.push(this.createFillerElement(2));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["mo-fr"],
                                              scheduleObj.coolSchedule["mo-fr"],
                                              "Monday - Friday",
                                              scheduleObj.schedule_config));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["sa"],
                                              scheduleObj.coolSchedule["sa"],
                                              "Saturday",
                                              scheduleObj.schedule_config));
      dayElements.push(this.createDayElement(scheduleObj.heatSchedule["su"],
                                              scheduleObj.coolSchedule["su"],
                                              "Sunday",
                                              scheduleObj.schedule_config));
      
    }

    return (
      <div>
        <Row>
          <div className="dayelementswrapper">
            {/* {dayElements} */}
            
            {this.createDayElement(scheduleObj.heatSchedule["mo"],
                                                    scheduleObj.coolSchedule["mo"],
                                                    "Monday",
                                                    scheduleObj.schedule_config)}
            {this.createDayElement(scheduleObj.heatSchedule["tu"],
                                                    scheduleObj.coolSchedule["tu"],
                                                    "Tuesday",
                                                    scheduleObj.schedule_config)}
            {this.createDayElement(scheduleObj.heatSchedule["we"],
                                                    scheduleObj.coolSchedule["we"],
                                                    "Wednesday",
                                                    scheduleObj.schedule_config)}
            {this.createDayElement(scheduleObj.heatSchedule["th"],
                                                    scheduleObj.coolSchedule["th"],
                                                    "Thursday",
                                                    scheduleObj.schedule_config)}
            {/* {this.createDayElement(scheduleObj.heatSchedule["fr"],
                                                    scheduleObj.coolSchedule["fr"],
                                                    "Friday",
                                                    scheduleObj.schedule_config)} */}
            {/* {this.createDayElement(scheduleObj.heatSchedule["sa"],
                                                    scheduleObj.coolSchedule["sa"],
                                                    "Saturday",
                                                    scheduleObj.schedule_config)} */}
            {/* {this.createDayElement(scheduleObj.heatSchedule["su"],
                                                    scheduleObj.coolSchedule["su"],
                                                    "Sunday",
                                                    scheduleObj.schedule_config)} */}
          </div>
        </Row>
        <Row>
      <div className="dayelementswrapper">
            {/* {dayElements} */}
            {/* {this.createDayElement(scheduleObj.heatSchedule["mo"],
                                                    scheduleObj.coolSchedule["mo"],
                                                    "Monday",
                                                    scheduleObj.schedule_config)} */}
            {/* {this.createDayElement(scheduleObj.heatSchedule["tu"],
                                                    scheduleObj.coolSchedule["tu"],
                                                    "Tuesday",
                                                    scheduleObj.schedule_config)} */}
            {/* {this.createDayElement(scheduleObj.heatSchedule["we"],
                                                    scheduleObj.coolSchedule["we"],
                                                    "Wednesday",
                                                    scheduleObj.schedule_config)} */}
            {/* {this.createDayElement(scheduleObj.heatSchedule["th"],
                                                    scheduleObj.coolSchedule["th"],
                                                    "Thursday",
                                                    scheduleObj.schedule_config)} */}
            {this.createDayElement(scheduleObj.heatSchedule["fr"],
                                                    scheduleObj.coolSchedule["fr"],
                                                    "Friday",
                                                    scheduleObj.schedule_config)}
            {this.createDayElement(scheduleObj.heatSchedule["sa"],
                                                    scheduleObj.coolSchedule["sa"],
                                                    "Saturday",
                                                    scheduleObj.schedule_config)}
            {this.createDayElement(scheduleObj.heatSchedule["su"],
                                                    scheduleObj.coolSchedule["su"],
                                                    "Sunday",
                                                    scheduleObj.schedule_config)}

          </div>
        </Row>
        {/* <Row>
          <div className="container">
            <div className="row seven-cols">
              <div className="col-md-1">Col 1</div>
              <div className="col-md-1">Col 2</div>
              <div className="col-md-1">Col 3</div>
              <div className="col-md-1">Col 4</div>
              <div className="col-md-1">Col 5</div>
              <div className="col-md-1">Col 6</div>
              <div className="col-md-1">Col 7</div>
            </div>
          </div>
        </Row> */}
      </div>
    );
  }
}

export default Days;
