import React, { Component } from 'react';
import { Button, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import ExternalSensorSingle from './externalsensorsingle.js';

class ExternalSensor extends React.Component {
  constructor() {
    super();

    this.state = {
      show: false
    }
  }

  handleShow = (e) => {
    this.setState({ show: true })
  }

  handleClose = (e) => {
    this.setState({ show: false })
  }

  render() {
    let tooltip = (
      <Tooltip id="tooltip">
        Configure external sensors for this thermostat.
      </Tooltip>
    );

    let co2ConfigMacKeys = ["co2_mac1", "co2_mac2", "co2_mac3", "co2_mac4"];
    let co2ConfigLabelKeys = ["co2_lbl1", "co2_lbl2", "co2_lbl3", "co2_lbl4"];
    let co2LiveDataKeys = ["co2_1", "co2_2", "co2_3", "co2_4"];
    let co2SensorElements = [];

    let tempConfigMacKeys = ["rt_mac1", "rt_mac2", "rt_mac3", "rt_mac4"];
    let tempConfigLabelKeys = ["rt_lbl1", "rt_lbl2", "rt_lbl3", "rt_lbl4"];
    let tempSensorElements = [];

    //  Wait to populate these sensors until Config and LiveData are loaded.
    if (this.props.configuration && this.props.deviceLiveData) {
      // console.log(this.props.configuration);
      co2SensorElements = co2ConfigMacKeys.map((key, index) => {
        if (this.props.configuration.hasOwnProperty(key) && this.props.configuration[key] !== "000000000000"
            && this.props.deviceLiveData.tstat_read_data[co2LiveDataKeys[index]] !== "-") { // indicates no sensor whitelisted.
          return (
            <ExternalSensorSingle
            key={index}
            label={ this.props.configuration[co2ConfigLabelKeys[index]] }
            mac={ this.props.configuration[key] }
            data={this.props.deviceLiveData.tstat_read_data[co2LiveDataKeys[index]] + " ppm"}/>
          )
        }
        else {
          return (
            <ExternalSensorSingle
            key={index}
            label={"Empty"}
            mac={""}
            data={""}/>
          )
        }
      });

      tempSensorElements = tempConfigMacKeys.map((key, index) => {
        if (this.props.configuration.hasOwnProperty(key) && this.props.configuration[key] !== "000000000000") {
          return (
            <ExternalSensorSingle
            key={index}
            label={ this.props.configuration[tempConfigLabelKeys[index]] }
            mac={ this.props.configuration[key] }
            data={"data"}/>
          )
        }
        else {
          return (
            <ExternalSensorSingle
            key={index}
            label={"Empty"}
            mac={""}
            data={""}/>
          )
        }
      });
    }

    return (
      <div>
        <OverlayTrigger placement="top" overlay={tooltip}>
          <Button onClick={this.handleShow}>
          Peripherals <i className="fa fa-cog"></i>
          </Button>
        </OverlayTrigger>

        <Modal show={this.state.show} onHide={this.handleClose} bsSize="lg">
          <Modal.Header closeButton>
            <Modal.Title>External Sensor Configuration</Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass={'samModalBody2'}>
            <center className="centerTable">
              <h1>CO2 Sensors</h1>
              {co2SensorElements}
              <h1>Temperature / Humidity Sensors</h1>
              {tempSensorElements}
            </center>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default ExternalSensor;
