import React, { Component, useState, useEffect } from 'react'
import { Route } from 'react-router'
import { Switch, BrowserRouter, Redirect, HashRouter } from 'react-router-dom'

//Packages
import decode from 'jwt-decode'

//Route components
import { Left, Top, Footer } from './navigation'
import { Menu } from './routes'
import PortalLayout from './layouts/portallayout.js'
import Login from './routes/login/Login.js'
import Dashboard from './routes/dashboard/Dashboard.js'
import SlidesTest from './routes/thermo/slidestest.js'
import ControlWrapper from './routes/thermo/controlwrapper.js'
import ScheduleWizard from './routes/buildschedule/scheduleWizard.js'
import SchedulesMenu from './routes/schedules/schedulesmenu.js'
import SideNavSitesAndDevices from './navigation/SideNavSitesAndDevices.js'
import DataAnalysisWrapper from './routes/dataanalysis/dataanalysiswrapper.js'
import SiteManager from './routes/sitemanager/sitemanager.js'
import PageNotFound from './routes/pagenotfound/pagenotfound.js'
import api from './util/api.js'

import constants from '../config/constants.js'
console.log('constants', constants)

const { getUserPromise } = api
//Constants
const PUBLIC_ROOT = '/'

var apiUrl = 'https://jdpy43j0u7.execute-api.us-west-2.amazonaws.com'

//Check if token is stored
const checkAuth = () => {
  const token = localStorage.getItem('token')
  //console.log(token);
  //If no token, return false
  var user = {
    authorized: false,
  }
  if (!token) {
    console.log('Token not found in localStorage')
    user.authorized = false
    return user
    return false
  }
  //If token is present, let's try to verify it
  else {
    try {
      //Grab expiration date from jwt payload
      //console.log(decode(token));
      const decodedInfo = decode(token)
      user = decodedInfo
      var exp = decodedInfo.exp
      //console.log("decodedInfo: ", decodedInfo);
      //Not authorized if the expiration date has passed
      if (exp < new Date().getTime() / 1000) {
        user.authorized = false
        return user
        //return false;
      }
      //If expiration date hasn't passed then they are authorized
      else {
        user.authorized = true
        return user
      }
    } catch (err) {
      console.log(err)
      user.authorized = false
      return user
      return false
    }
  }
}

async function getUserSync(userId) {
  let user = await getUserPromise(userId)
  return user
}

//This seems to work, but going back to the dashboard doesn't regrab the user? maybe because render is using old values
/*
const AuthRoute = ({component, ...rest}) => {
  
  //Track the state of your app instead. Start with a "loading" state
  const [state, setState] = useState(undefined);

  useEffect(() => {
    (async function() {
      try {
        // Update effect logic to track correct state
        //const isUserLogged = await isValidToken();
        var user = await getUserSync();
        console.log("got user from await: ", user);
        setState(user ? user : undefined);
      }
      catch(err){
        setState(undefined);
      }
    })();
  }, []);

  //If in loading state, return loading message while waiting for 
  //isValidToken to complete
  if(state === undefined) {
    return <div></div>
  }
  console.log("checkAuth(): ", checkAuth(), " state: ", state);
  if (checkAuth()) {
    return (
      <Route {...rest} render={props => {
          return React.createElement(component, Object.assign({}, props, {user: state}, ...rest))
        }
      }/>
    );
  }
  else {
    return <Redirect to={PUBLIC_ROOT} />
  }
  
};
*/

const AuthRoute = ({ component, ...rest }) => {
  var checkAuthResults = checkAuth()
  if (checkAuthResults.authorized) {
    return (
      //Create the route, and pass the resolved user
      <Route
        {...rest}
        render={(props) => {
          return React.createElement(
            component,
            Object.assign({}, props, { user: checkAuthResults, refreshSideNav: rest.refreshSideNav }, rest),
          )
        }}
      />
    )
  } else {
    //User is not Authenticated
    return <Redirect to={PUBLIC_ROOT} />
  }
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <HashRouter>
        <div>
          <Switch>
            {/*Used to group the routes together*/}

            {/*Login route and static html goes here*/}
            <Route exact={true} path='/' component={Login} />
            <Route exact={true} path='/index.html' component={Login} />

            <PortalLayout title='XCSpec Maintenance Portal'>
              {/*All AuthRoute's go here*/}

              {/*Dashboard Route*/}

              <AuthRoute exact={true} path='/dashboard' component={Dashboard} />
              <AuthRoute exact={true} path='/sitemanager' component={SiteManager} />
              <AuthRoute exact={true} path='/schedulemanager' component={SchedulesMenu} />
              <AuthRoute exact={true} path='/site/:siteID/dashboard' component={Dashboard} />
              <AuthRoute exact={true} path='/site/:siteID/device/:portalDeviceID/control' component={ControlWrapper} />
              <AuthRoute exact={true} path='/site/:siteID/device/:deviceID/analysis' component={DataAnalysisWrapper} />
              <AuthRoute exact={true} path='/site/:siteID/sitemanager' component={SiteManager} />
              <AuthRoute exact={true} path='/site/:siteID/schedules' component={SchedulesMenu} />

              {/* 404 Route */}

              {/* <PortalLayout title="XCSpec Maintenance Portal">
                  <Switch>

                    <AuthRoute exact={true} path="/dashboard" component={Dashboard}/>
                    <AuthRoute exact={true} path="/sitemanager" component={SiteManager}/>
                    <AuthRoute exact={true} path="/schedulemanager" component={SchedulesMenu}/>
                    <AuthRoute exact={true} path="/site/:siteID/dashboard" component={Dashboard}/>
                    <AuthRoute exact={true} path="/site/:siteID/device/:deviceID/control" component={ControlWrapper}/>
                    <AuthRoute exact={true} path="/site/:siteID/device/:deviceID/analysis" component={DataAnalysisWrapper}/>
                    <AuthRoute exact={true} path="/site/:siteID/sitemanager" component={SiteManager}/>
                    <AuthRoute exact={true} path="/site/:siteID/schedules" component={SchedulesMenu}/>

                    <Route path="*" component={PageNotFound} />

                    </Switch>
                  </PortalLayout> */}
            </PortalLayout>
            {/* <Route path="*" component={PageNotFound} /> */}
          </Switch>

          <Switch></Switch>
        </div>
      </HashRouter>
    )
  }
}

export default App
