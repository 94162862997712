import React, { Component } from 'react';
import Control from './control.js';
import api from '../../util/api.js';

const { fetchDeviceIdFromPortalDeviceId } = api
//  Component: Strictly a wrapper for the Thermo class.
class ControlWrapper extends React.Component {
  
  constructor(props) {
    super(props);
    console.log("ControlWrapper props: ", props);
    this.getDeviceIdFromPortalDeviceId(this.props.portalDeviceID);
    this.state = {
      deviceId: null
    };
  }

  getDeviceIdFromPortalDeviceId = (portalDeviceId) => {
    //console.log("siteID from getDeviceIdFromPortalDeviceId: ", this.props.match.params.siteID);
    fetchDeviceIdFromPortalDeviceId(this.props.match.params.siteID, this.props.match.params.portalDeviceID)
    .then((deviceId) => {
      console.log("getting deviceId: ", deviceId);
      this.setState({
        deviceId: deviceId
      });
    })
    .catch((err) => {
      console.log("error getting deviceId from portal device ID");
      console.log(err);
    })
  }

  // getDeviceFromPortalDeviceId = () => {
  //   let { portalDeviceID, siteID } = this.props.match.params;
  //   let requestStr = apiUrl + "/device/portalDeviceId/" + portalDeviceID;
  //   fetch(requestStr)
  //     .then(res => res.json())
  //     .then(response => {
  //       console.log("response: ", response);
  //       if(response && response.success === true && response.data){
  //         //console.log("FETCHED device!");
  //         console.log(response.data.device);
  //         this.setState({
  //           device: response.data.device
  //         }, () => {
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("got device promise err", err);
  //     })
  // }

  render() {
    let { portalDeviceID, siteID } = this.props.match.params;

    return (
      <Control portalDeviceID={portalDeviceID} deviceID={this.state.deviceId} siteID={siteID} key={this.state.portalDeviceID} />
    )
  }
}

export default ControlWrapper;
