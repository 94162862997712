import React, { Component } from 'react';
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import ScheduleWizardPeriod from './scheduleWizardPeriod.js';

const dayStringMap7 = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

class ScheduleWizardDaySlide extends React.Component {

  handlePeriodTimeChange = (targetValue, periodIndex) => {
    // Attach my index.
    this.props.timeChange(targetValue, periodIndex, this.props.index);
  }

  handlePeriodTempChange = (targetValue, periodIndex) => {
    // Attach my index.
    this.props.tempChange(targetValue, periodIndex, this.props.index);
  }

  handleHeatSetChange = (targetValue, periodIndex) => {
    this.props.heatSetChange(targetValue, periodIndex, this.props.index);
  }

  handleCoolSetChange = (targetValue, periodIndex) => {
    this.props.coolSetChange(targetValue, periodIndex, this.props.index);
  }

  handleOccupiedChange = (targetValue, periodIndex) => {
    // Attach my index.
    this.props.occupiedChange(targetValue, periodIndex, this.props.index);
  }

  onClickCopyDaySchedule = (e, dayScheduleElement) => {
    // handlePeriodTimeChange = (targetValue, periodIndex) => {
    //   // Attach my index.
    //   this.props.timeChange(targetValue, periodIndex, this.props.index);
    // }

    // handlePeriodTempChange = (targetValue, periodIndex) => {
    //   // Attach my index.
    //   this.props.tempChange(targetValue, periodIndex, this.props.index);
    // }

    // handleHeatSetChange = (targetValue, periodIndex) => {
    //   this.props.heatSetChange(targetValue, periodIndex, this.props.index);
    // }

    // handleCoolSetChange = (targetValue, periodIndex) => {
    //   this.props.coolSetChange(targetValue, periodIndex, this.props.index);
    // }

    // handleOccupiedChange = (targetValue, periodIndex) => {
    //   // Attach my index.
    //   this.props.occupiedChange(targetValue, periodIndex, this.props.index);
    // }

    
    console.log("e is: ", e);
    console.log("dayScheduleElement is: ", dayScheduleElement);

    this.handlePeriodTimeChange(dayScheduleElement[0].start_time, 0);
    this.handlePeriodTimeChange(dayScheduleElement[1].start_time, 1);

    this.handleHeatSetChange(dayScheduleElement[0].heat_set, 0);
    this.handleHeatSetChange(dayScheduleElement[1].heat_set, 1);

    this.handleCoolSetChange(dayScheduleElement[0].cool_set, 0);
    this.handleCoolSetChange(dayScheduleElement[1].cool_set, 1);

    this.handleOccupiedChange(dayScheduleElement[0].occupied, 0);
    this.handleOccupiedChange(dayScheduleElement[1].occupied, 1);


  }

  render() {
    let periodElements = [];
    for (let i = 0; i < this.props.numPeriods; i++) {
      periodElements.push(
                           <ScheduleWizardPeriod
                             index={i}
                             start_time={this.props.schedule[i].start_time}
                             temp_set={this.props.schedule[i].temp_set}
                             cool_set={this.props.schedule[i].cool_set}
                             heat_set={this.props.schedule[i].heat_set}
                             occupied={this.props.schedule[i].occupied}
                             timeChange={this.handlePeriodTimeChange}
                             tempChange={this.handlePeriodTempChange}
                             heatSetChange={this.handleHeatSetChange}
                             coolSetChange={this.handleCoolSetChange}
                             occupiedChange={this.handleOccupiedChange}
                             setpointMode={this.props.setpointMode}
                           />
                         );
    }

  
    return (
      <center className="centerTable">
        <div className="tableCell">
          <h1>
            {this.props.isHoliday ? "Holiday Schedule Setpoints" : this.props.dayString + " Setpoints"} 
          </h1>
          {/* <br/> */}
          <div style={{textAlign: "center"}}>
          {this.props.prevSchedules.map((dayScheduleElement, index) => {
            console.log("prev schedule: " + dayScheduleElement);
            var tooltip = (
              <Tooltip style={{ wordBreak:'break-all' }}>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Start Time</th>
                      {/* <th>Temperature</th> */}
                      <th>Heat Set</th>
                      <th>Cool Set</th>
                      <th>Occupied</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dayScheduleElement.map((period, index) => {
                      return (
                        <tr>
                          <td>{period.start_time}</td>
                          {/* <td>{period.temp_set}</td> */}
                          <td>{period.heat_set}</td>

                          <td>{period.cool_set}</td>
                          <td>{period.occupied}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Tooltip>
            );
            return (
              
              
                <OverlayTrigger placement="bottom" overlay={tooltip} style={{display: "inlineBlock"}}>
                  <Button onClick={((e) => {
                    this.onClickCopyDaySchedule(e, dayScheduleElement);
                  })}>
                    {/* <h3>Copy {dayStringMap7[index]}</h3> */}
                    Copy {dayStringMap7[index]}
                  </Button>
                </OverlayTrigger>
                
            );
          })}
          </div>
          {periodElements}
        </div>
      </center>
    )
  }
}

export default ScheduleWizardDaySlide;
