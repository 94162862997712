import React from 'react'
import { Row, Col } from 'react-bootstrap';
import api from '../../util/api.js'
//import './css/custom.css';

const {postAuthenticatePromise} = api

class Login extends React.Component {

	static isPrivate = false;

	constructor(props){
		super(props);
		this.state = {username: '', password: ''};

		//Bind our event handlers to our component
		this.handleLogin = this.handleLogin.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	//Update username and password state variables as they are changed
	onChange(e){
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	//Login using username and password variables from state
	handleLogin(event){
		event.preventDefault();

		//console.log(this.state);
		postAuthenticatePromise(this.state.username, this.state.password)
	    .then(res => res.json())
	    .then(response => {
	    	//Successful login
	    	if(response && response.success === true && response.token){
	    		localStorage.setItem('token', response.token);

	    		//Navigate to the dashboard with the authentication
	    		const {history} = this.props; //short-hand for 'const history = this.props.history;'
	    		history.push('/dashboard'); // redirect
	    	}
	    	//Unsuccessful login
	    	else{
				console.log(response);
	    		if(response && response.message)
	    			alert(response.message);
	    		else
	    			alert("There was a problem with your request");
	    	}
	    })
	    .catch((err) => {
	    	console.log("handled error");
	    	console.log(err);
	    });
	}

	render(){
		return(
			<div>
			   <head>
			      {/* META */}
			      <meta charSet="utf-8"/>
			      <meta name="viewport" content="width=device-width,initial-scale=1"/>
			      {/* Optimize mobile viewport */}
			      <title>XCSpec Login</title>
			      {/* SCROLLS */}
			      <link rel="stylesheet" href="//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css"/>
		

			      {/* SPELLS */}<script src="//ajax.googleapis.com/ajax/libs/jquery/2.0.3/jquery.min.js"></script>{/* load jquery */}<script src="//ajax.googleapis.com/ajax/libs/angularjs/1.0.8/angular.min.js"></script>{/* load angular */}
			   </head>
			   <body>
			      {/* navbar */}
			      <div className="navbar navbar-inverse navbar-static-top">
			         <a className="" style={{"paddingLeft": "15px !important", "float": "left", "marginTop": "1px", "marginLeft": "5px"}}><img className="brand-image logo img-responsive" src="/images/ic_launcher.png"/></a>
			         <p className="navbar-text" style={{"marginLeft": "10px"}}>XCSpec</p>
			      </div>
			      <div className="container">
			         <div className="panel panel-primary">
			            <div className="panel-heading">
			               <h1 className="panel-title">Sign In</h1>
			            </div>
			            <div className="panel-body">
			               <div id="formwrapper" className="center-block" style={{"display": "block"}}>
			                  <form className="signin-form" onSubmit={this.handleLogin}>
								<div className="input-group center-block">
									<Row>
										<label htmlFor="emailForm">Email address</label>
									</Row>
									<Row>
										<input type="text" className="form-control" id="emailForm" placeholder="youremail@example.com" ref="username" name="username" type="text" onChange={this.onChange} required style={{width: "300px"}}/>
									</Row>
								</div>
								<div className="input-group center-block">
									<Row>
										<label htmlFor="passwordForm">Password</label>
									</Row>
									<Row>
										<input type="password" className="form-control" id="passwordForm" placeholder="*******" ref="password" name="password" onChange={this.onChange} style={{width: "300px"}} required/>
									</Row>
								</div>
								<div className="center-block"><button className="btn btn-primary btn-lg center-block">Sign In</button></div>
			                  </form>
			               </div>
			            </div>
			         </div>
			      </div>
			      <div className="navbar navbar-default navbar-fixed-bottom">
			         <div className="container">
			            <p className="navbar-text text-center navbar-right">Copyright 2023 XCSpec Inc. Larkspur, CA. All rights reserved</p>
			         </div>
			      </div>
			   </body>
			</div>
		);
	}
}

export default Login;
