import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import ScheduleHoriz from './schedulehoriz.js';

class SchedulesMenuList extends React.Component {
  constructor() {
    super();
  }

  render() {
    let scheduleElements;
    if (this.props.didApiFetch === false) {
      scheduleElements = <div style={{ fontSize: "16px" }} ><br/></div>
    }
    else if (this.props.scheduleObjs.length === 0) {
      scheduleElements = <div style={{ fontSize: "16px" }} ><br/>You have not created any schedules yet!</div>
    }
    else {
      scheduleElements = this.props.scheduleObjs.map((scheduleObj, index) => {
        return (
          <ScheduleHoriz scheduleObj={scheduleObj} siteID={ this.props.siteID }
            index={index} key={index} handleDelete={this.props.handleDelete}/>
        )
      })
    }

    return (
      <Col>
        {scheduleElements}
      </Col>
    )
  }
}

export default SchedulesMenuList;
