//var Joi = require("joi");

// var thermostatLiveDataRelayRegex = /^W1:([01]), W2:([01]), Y1:([01]), Y2:([01]), O:([01]), B:([01]), G:([01]), H:([01]), D:([01])$/;
// var thermostatLiveDataClockRegex = /^([0-6]):([0-5][0-9]):([0-5][0-9])$/;
// //var thermostatScheduleRegex = /^(\[[[0-9]+, [0-9]+,  [0-9]+,  [0-9]+,  [0-9]+\], )+$/;
// var thermostatScheduleRegex = /^(\[([0-6]),([0-3]),([01]?[0-9][0-9]?|2[0-4][0-9]|25[0-5]),([4-9][0-9][0-9]|980),[01]\], )+$/;
// var thermostatApRssiRegex = /^(-?[0-9]?[0-9])$/;

const THERMOSTAT_COMMAND_KEYS_OBJ = {
  FIRMWARE_FILE: "fw",
  FILESYSTEM_FILE: "fs",
  HEAT_SETPOINT: "hs",
  COOL_SETPOINT: "cs",
  MODE: "om",
  OPERATING_STATE: "os",
  FAN_MODE: "fm",
  FAN_STATE: "fs",
  HOLD: "h",
  HOLD_HOURS: "hhr",
  CLOCK: "cl",
  CLOCK_CONFIG: "clconf",
  OPERATING_MODES_ENABLED: "ome",
  MESSAGE_TEXT: "mt",
  LOCK: "lk",
  LOCK_ENABLE: "lke",
  SCHEDULE_MODE: "sm",
  SCHEDULE_HEAT: "prH",
  SCHEDULE_COOL: "prC",
  TEMPERATURE_CALIBRATION:  "tc",
  TEMPERATURE_DISPLAY_UNITS: "tu",
  ENGAGED_TRANSACTION_INTERVAL: "et",
  PPM_HIGH: "high",
  PPM_LOW: "low",
  ZERO_TO_TEN_VOLT_OUTPUT: "en",
  OUTPUT_VOLTAGE_CONFIGURATION: "vdc",
  LABEL: 'lbl',
  GOOD_BAND: 'good',
  BAD_BAND: 'bad',
  ALERT_BAND: 'alert',
  BACKGROUND_CALIBRATION_ENABLED: 'scd_asc',
  FORCE_CALIBRATION: 'scd_fsc',
  CLEAR_RESET_COUNTER: 'crst',
  ZONE_TEMPERATURE: 'zt',
  ZONE_HUMIDITY: 'zh',
  RELAY_STATES: 'rs',
  RELAY_LOGS_ENABLED: 'rl',
  CO2_SENSOR: 'scd',
  CO2_SENSOR_TEMPERATURE: 'temp',
  CO2_SENSOR_RELATIVE_HUMIDITY: 'rh',
  CO2_SENSOR_CO2: 'ppm',
  PRESSURE: 'pressure',
  AP_RSSI: 'ssv',
  FIRMWARE: 'fw',
  THERMOSTAT_VERSION: 'thrmVer',
  TRANSMITTER_TEMP: "rtv",
  RESET_COUNT: "CntRstCnt",
  DEVICE_TYPE: "dt",
  DUTY: "duty",
  AWS_ENABLE: "is_aws",
}

const THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES = {
  [THERMOSTAT_COMMAND_KEYS_OBJ.FIRMWARE_FILE]: '',
  [THERMOSTAT_COMMAND_KEYS_OBJ.FILESYSTEM_FILE]: '',
  [THERMOSTAT_COMMAND_KEYS_OBJ.HEAT_SETPOINT]: 'heat_set',
  [THERMOSTAT_COMMAND_KEYS_OBJ.COOL_SETPOINT]: 'cool_set',
  [THERMOSTAT_COMMAND_KEYS_OBJ.MODE]: 'op_mode',
  [THERMOSTAT_COMMAND_KEYS_OBJ.OPERATING_STATE]: 'op_state',
  [THERMOSTAT_COMMAND_KEYS_OBJ.FAN_MODE]: 'fan_mode',
  [THERMOSTAT_COMMAND_KEYS_OBJ.FAN_STATE]: 'fan_state',
  [THERMOSTAT_COMMAND_KEYS_OBJ.HOLD]: 'temp_hold',
  [THERMOSTAT_COMMAND_KEYS_OBJ.HOLD_HOURS]: 'hold_hours',
  [THERMOSTAT_COMMAND_KEYS_OBJ.CLOCK]: 'current_time',
  [THERMOSTAT_COMMAND_KEYS_OBJ.CLOCK_CONFIG]: 'clock_config',
  [THERMOSTAT_COMMAND_KEYS_OBJ.OPERATING_MODES_ENABLED]: 'op_mode_enable',
  [THERMOSTAT_COMMAND_KEYS_OBJ.MESSAGE_TEXT]: '',
  [THERMOSTAT_COMMAND_KEYS_OBJ.LOCK]: 'lockout',
  [THERMOSTAT_COMMAND_KEYS_OBJ.LOCK_ENABLE]: 'lockout_enable',
  [THERMOSTAT_COMMAND_KEYS_OBJ.SCHEDULE_MODE]: 'schedule_mode',
  [THERMOSTAT_COMMAND_KEYS_OBJ.SCHEDULE_HEAT]: 'heat_schedule',
  [THERMOSTAT_COMMAND_KEYS_OBJ.SCHEDULE_COOL]: 'cool_schedule',
  [THERMOSTAT_COMMAND_KEYS_OBJ.TEMPERATURE_CALIBRATION]:  'calibration',
  [THERMOSTAT_COMMAND_KEYS_OBJ.TEMPERATURE_DISPLAY_UNITS]: 'display_units',
  [THERMOSTAT_COMMAND_KEYS_OBJ.ENGAGED_TRANSACTION_INTERVAL]: 'et',
  [THERMOSTAT_COMMAND_KEYS_OBJ.PPM_HIGH]: 'ppm_high',
  [THERMOSTAT_COMMAND_KEYS_OBJ.PPM_LOW]: 'ppm_low',
  [THERMOSTAT_COMMAND_KEYS_OBJ.ZERO_TO_TEN_VOLT_OUTPUT]: 'duty',
  [THERMOSTAT_COMMAND_KEYS_OBJ.OUTPUT_VOLTAGE_CONFIGURATION]: 'voltage_conf',
  [THERMOSTAT_COMMAND_KEYS_OBJ.LABEL]: 'lbl',
  [THERMOSTAT_COMMAND_KEYS_OBJ.GOOD_BAND]: 'alow',
  //[THERMOSTAT_COMMAND_KEYS_OBJ.BAD_BAND]: 'ahigh',
  [THERMOSTAT_COMMAND_KEYS_OBJ.ALERT_BAND]: 'ahigh',
  [THERMOSTAT_COMMAND_KEYS_OBJ.BACKGROUND_CALIBRATION_ENABLED]: 'background_calibration_enabled',
  [THERMOSTAT_COMMAND_KEYS_OBJ.FORCE_CALIBRATION]: 'co2_force_calibration',
  [THERMOSTAT_COMMAND_KEYS_OBJ.CLEAR_RESET_COUNTER]: 'crst',
  [THERMOSTAT_COMMAND_KEYS_OBJ.ZONE_TEMPERATURE]: 'zone_temp',
  [THERMOSTAT_COMMAND_KEYS_OBJ.ZONE_HUMIDITY]: 'zone_rh',
  [THERMOSTAT_COMMAND_KEYS_OBJ.RELAY_STATES]: 'relay_state',
  [THERMOSTAT_COMMAND_KEYS_OBJ.RELAY_LOGS_ENABLED]: 'relay_log_enable',
  [THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR]: 'co2_sensor',
  [THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_TEMPERATURE]: 'co2_sensor_temp',
  [THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_RELATIVE_HUMIDITY]: 'co2_sensor_rh',
  [THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_CO2]: 'co2_sensor_ppm',
  [THERMOSTAT_COMMAND_KEYS_OBJ.PRESSURE]: 'pressure',
  [THERMOSTAT_COMMAND_KEYS_OBJ.AP_RSSI]: 'ap_rssi',
  [THERMOSTAT_COMMAND_KEYS_OBJ.FIRMWARE]: 'wifi_fw',
  [THERMOSTAT_COMMAND_KEYS_OBJ.THERMOSTAT_VERSION]: 'chassis_fw',
  [THERMOSTAT_COMMAND_KEYS_OBJ.TRANSMITTER_TEMP]: '',
  [THERMOSTAT_COMMAND_KEYS_OBJ.RESET_COUNT]: 'CntRstCnt',
  [THERMOSTAT_COMMAND_KEYS_OBJ.DEVICE_TYPE]: 'device_type',
  [THERMOSTAT_COMMAND_KEYS_OBJ.DUTY]: 'duty',
  [THERMOSTAT_COMMAND_KEYS_OBJ.AWS_ENABLE]: 'is_aws',
};


const THERMOSTAT_COMMAND_KEYS = {
  "fw": '',
  "fs": '',
  "hs": 'heat_set',
  "cs": 'cool_set',
  "om": 'op_mode',
  "os": 'op_state',
  "fm": 'fan_mode',
  "fs": 'fan_state',
  "h": 'temp_hold',
  "hhr": 'hold_hours',
  "cl": 'current_time',
  "clconf": 'clock_config',
  "ome": 'op_mode_enable',
  "mt": '',
  "lk": 'lockout',
  "lke": 'lockout_enable',
  "sm": 'schedule_mode',
  "prH": 'heat_schedule',
  "prC": 'cool_schedule',
  "tc": 'calibration',
  "tu": 'display_units',
  "et": 'et',
  "high": 'ppm_high',
  "low": 'ppm_low',
  "en": 'duty',
  "vdc": 'voltage_conf',
  "lbl": 'lbl',
  "good": 'alow',
  "alert": 'ahigh',
  "scd_asc": 'background_calibration_enabled',
  "scd_fsc": 'co2_force_calibration',
  "crst": 'crst',
  "zt": 'zone_temp',
  "zh": 'zone_rh',
  "rs": 'relay_state',
  "rl": 'relay_log_enable',
  "scd": 'co2_sensor',
  "temp": 'co2_sensor_temp',
  "rh": 'co2_sensor_rh',
  "ppm": 'co2_sensor_ppm',
  "pressure": 'pressure',
  "ssv": 'ap_rssi',
  "fw": 'wifi_fw',
  "thrmVer": 'chassis_fw',
  "rtv": '',
  "CntRstCnt": 'CntRstCnt',
  "dt": 'device_type',
  "duty": 'duty',
  "is_aws": 'is_aws',
}

// var thermostatConfigSchema = {
//   //new protocol:
//   //DEVICE_TYPE
//   [THERMOSTAT_COMMAND_KEYS_OBJ.DEVICE_TYPE]: Joi.string().max(30),//device type
//   [THERMOSTAT_COMMAND_KEYS_OBJ.FIRMWARE]: Joi.string().max(200),//firmware
//   [THERMOSTAT_COMMAND_KEYS_OBJ.THERMOSTAT_VERSION]: Joi.string().max(200),//thermostat version THERMOSTAT_VERSION
//   [THERMOSTAT_COMMAND_KEYS_OBJ.CLOCK_CONFIG]: Joi.string().valid("12H","24H"),//clock format
//   [THERMOSTAT_COMMAND_KEYS_OBJ.OPERATING_MODES_ENABLED]: Joi.string().valid('HOFFC','HOFF','“COFF”','HOFFCA','HOFFCE').strict(),//operating modes enabled
//   [THERMOSTAT_COMMAND_KEYS_OBJ.SCHEDULE_MODE]: Joi.string().valid("OFF","5D","7D"),//schedule mode
//   [THERMOSTAT_COMMAND_KEYS_OBJ.SCHEDULE_HEAT]: Joi.string().regex(thermostatScheduleRegex).max(300),//heat schedule
//   [THERMOSTAT_COMMAND_KEYS_OBJ.SCHEDULE_COOL]: Joi.string().regex(thermostatScheduleRegex).max(300),//cool schedule
//   [THERMOSTAT_COMMAND_KEYS_OBJ.RELAY_LOGS_ENABLED]: Joi.string().valid("OFF","ON"),//relay logs enable
//   [THERMOSTAT_COMMAND_KEYS_OBJ.TEMPERATURE_CALIBRATION]: Joi.string().valid("-4","-3","-2","-1","0","1","2","3","4"),//temperature calibration
//   [THERMOSTAT_COMMAND_KEYS_OBJ.TEMPERATURE_DISPLAY_UNITS]: Joi.string().valid("F","C"),//temperature display units
//   [THERMOSTAT_COMMAND_KEYS_OBJ.ENGAGED_TRANSACTION_INTERVAL]: Joi.number().integer().min(60).max(1440),
//   [THERMOSTAT_COMMAND_KEYS_OBJ.PPM_HIGH]: Joi.number().integer().min(1000).max(3500),//ppm  high
//   [THERMOSTAT_COMMAND_KEYS_OBJ.PPM_LOW]: Joi.number().integer().min(0).max(5000),//ppm low
//   [THERMOSTAT_COMMAND_KEYS_OBJ.ZERO_TO_TEN_VOLT_OUTPUT]: Joi.string().valid('true', 'false').strict(),//zero to ten volt output
//   [THERMOSTAT_COMMAND_KEYS_OBJ.OUTPUT_VOLTAGE_CONFIGURATION]: Joi.string().valid('true', 'false').strict(),//output voltage configuration
//   [THERMOSTAT_COMMAND_KEYS_OBJ.TRANSMITTER_TEMP]: Joi.string().regex(/^(?:[0-1]?[0-9]?[0-9]\.[0-9]?[0-9])$/).strict(),//TRANSMITTER_TEMP: "ppm",
//   [THERMOSTAT_COMMAND_KEYS_OBJ.RESET_COUNT]: Joi.string().regex(/^(?:[0-9]{1,6}:[0-9]{1,6}:[0-9]{1,6}:[0-9]{1,6}:[0-9]{1,6})$/).strict(),//CntRstCnt: "0:0:0:0:0",RESET_COUNT
//   [THERMOSTAT_COMMAND_KEYS_OBJ.LABEL]: Joi.string().max(100),//human readable device label
//   [THERMOSTAT_COMMAND_KEYS_OBJ.GOOD_BAND]: Joi.number().integer().min(0).max(3500),//good band co2 value
//   [THERMOSTAT_COMMAND_KEYS_OBJ.ALERT_BAND]: Joi.number().integer().min(1000).max(3500),//alert band co2 value
//   [THERMOSTAT_COMMAND_KEYS_OBJ.BACKGROUND_CALIBRATION_ENABLED]: Joi.string().valid('true', 'false').strict(),//Background calibration enabled scd_asc
//   [THERMOSTAT_COMMAND_KEYS_OBJ.FORCE_CALIBRATION]: Joi.number().integer().min(1000).max(3500),//Force Calibration Value scd_fsc
//   [THERMOSTAT_COMMAND_KEYS_OBJ.AWS_ENABLE]: Joi.boolean().strict(),//Force Calibration Value scd_fsc
// };

// var thermostatLiveDataSchemaNewProtocol = {
//   //update_time: Joi.string().strict(),//TODO: regex this. should be HH:DD:MM I believe
//   [THERMOSTAT_COMMAND_KEYS_OBJ.ZONE_TEMPERATURE]: Joi.string().regex(/^(?:[0-1]?[0-9]?[0-9]\.[0-9])$/).strict(),//Zone /ambient Temperature
//   [THERMOSTAT_COMMAND_KEYS_OBJ.HEAT_SETPOINT]: Joi.string().regex(/^(?:[0-1]?[0-9]?[0-9]\.[0-9]?[0-9])$/).strict(),//heat set
//   [THERMOSTAT_COMMAND_KEYS_OBJ.COOL_SETPOINT]: Joi.string().regex(/^(?:[0-1]?[0-9]?[0-9]\.[0-9]?[0-9])$/).strict(),//cool set
//   [THERMOSTAT_COMMAND_KEYS_OBJ.PRESSURE]: Joi.string().max(20).regex(/^[0-9]{1,15}\.[0-9]{1,4}$/),//pressure
//   [THERMOSTAT_COMMAND_KEYS_OBJ.FAN_STATE]: Joi.string().valid("I","O").strict(),//FAN_STATE fan state fs
//   [THERMOSTAT_COMMAND_KEYS_OBJ.FAN_MODE]: Joi.string().valid("ON","A").strict(),//fan mode
//   [THERMOSTAT_COMMAND_KEYS_OBJ.HOLD]: Joi.string().valid("SCHDL","TEMPR","PERM","SMR").strict(),//hold
//   [THERMOSTAT_COMMAND_KEYS_OBJ.HOLD_HOURS]: Joi.string().valid('0','1','2','3','4','5','6').strict(),
//   [THERMOSTAT_COMMAND_KEYS_OBJ.RELAY_STATES]: Joi.string().regex(thermostatLiveDataRelayRegex).strict(),//“tRs”:”W1:0, W2:0, Y1:0, Y2:0, O:0, B:0, G:1, H:0, D:0” relay state
//   [THERMOSTAT_COMMAND_KEYS_OBJ.CLOCK]: Joi.string().regex(thermostatLiveDataClockRegex).strict(),//clock
//   [THERMOSTAT_COMMAND_KEYS_OBJ.MODE]: Joi.string().valid("H","C","A","AC","AH").strict(),//OPERATING MODE“OFF”
//   [THERMOSTAT_COMMAND_KEYS_OBJ.OPERATING_STATE]: Joi.string().valid("H","C","OFF").strict(),//OPERATING_STATE modes current operating state
//   [THERMOSTAT_COMMAND_KEYS_OBJ.MESSAGE_TEXT]: Joi.string().regex(/^(?:[\x20-\x7F]{0,13})$/).strict(),
//   [THERMOSTAT_COMMAND_KEYS_OBJ.LOCK]: Joi.string().valid("OFF","BSC","PRT","FULL").strict(),//lock
//   [THERMOSTAT_COMMAND_KEYS_OBJ.LOCK_ENABLE]: Joi.string().valid("OFF","ON").strict(),//lock enable
//   [THERMOSTAT_COMMAND_KEYS_OBJ.AP_RSSI]: Joi.string().regex(thermostatApRssiRegex).strict(),//clock//RSSI
//   [THERMOSTAT_COMMAND_KEYS_OBJ.DUTY]: Joi.number().integer().strict(),//DUTY
//   [THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR]: Joi.object({//scd sensor
//     [THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_TEMPERATURE]: Joi.number().strict(),//temperature
//     [THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_RELATIVE_HUMIDITY]: Joi.number().strict(),//relative humidity
//     [THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_CO2]: Joi.number().strict(),//co2
//   }).strict(),
// };

// var allThermostatSchemas = {
//   ...thermostatConfigSchema,
//   ...thermostatLiveDataSchemaNewProtocol  
// };

const constants = {
  THERMOSTAT_COMMAND_KEYS_OBJ: THERMOSTAT_COMMAND_KEYS_OBJ,
  THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES: THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES,
  THERMOSTAT_COMMAND_KEYS: THERMOSTAT_COMMAND_KEYS,
  //thermostatConfigSchema: thermostatConfigSchema,
  //thermostatLiveDataSchemaNewProtocol: thermostatLiveDataSchemaNewProtocol,
  //allThermostatSchemas: allThermostatSchemas,
}

export default constants