import React from 'react';
import ReactDOM from 'react-dom';
import './css/custom.css';
import registerServiceWorker from './registerServiceWorker';

import { Left, Top, Footer } from './js/navigation';
import history from './js/history';
import { Router } from 'react-router';
import PageContent, { Menu } from './js/routes';

//import AppFactory from './js/App';
//const App = AppFactory(React, Router, history, Left, Top, Footer, PageContent, Menu);
import App from './js/App';

ReactDOM.render(<App/>, document.getElementById('root'));
//registerServiceWorker();




/*import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
//My Components 
import Index from './routes/index';
import PortalHeader from './components/portalheader.js';
//CSS
import './App.css';

class App extends Component {

  render() {
    return (
        <Index />
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();*/
