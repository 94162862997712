import React from 'react'
import { GroupMenuItem, MenuItem, GroupSubMenuItem } from '../../components/SideBar'

class SideNavSiteUnorderedList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {
    this.setState({
      resolvedPortalSite: this.props.resolvedPortalSite,
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      resolvedPortalSite: nextProps.resolvedPortalSite,
    })
  }

  generateSiteDevicesHtml = (resolvedSiteDevices) => {
    var thermostatHtml = []
    var wimeHtml = []
    var zipProHtml = []

    //Initialize the device type html, but don't close it
    {
      /*thermostatHtml.push(
					<GroupSubMenuItem key={this.state.resolvedPortalSite._id + "_" + "1"} title={"Thermostats"}>
				);*/
    }
    {
      /*wimeHtml.push(
					<GroupSubMenuItem key={this.state.resolvedPortalSite._id + "_" + "3"} title={"WiMes"}>
				);
				zipProHtml.push(
					<GroupSubMenuItem key={this.state.resolvedPortalSite._id + "_" + "4"} title={"Zip PROs"}>*/
    }

    resolvedSiteDevices.map((resolvedSiteDevice, i) => {
      //Make a dropdown submenu for each individual device for potential links
      switch (resolvedSiteDevice.deviceType) {
        //Thermostat
        case 1:
          thermostatHtml.push(this.generateThermostatHtml(resolvedSiteDevice))
          break
        //Wime/Zippro, currently connected
        case 3:
          wimeHtml.push(this.generateZipProHtml(resolvedSiteDevice))
          break

        default:
          zipProHtml.push(this.generateZipProHtml(resolvedSiteDevice))
      }
    })

    //We need different categories depending on the type of device in question, so let's make separate JSX for each type, then concatinate the html

    //Wrap each device type HTML in a subgroup if there are any of that device type in the site, otherwise, set to undefined so we don't even
    //Show that type for this site
    thermostatHtml =
      thermostatHtml.length > 0 ? (
        <GroupSubMenuItem key={this.state.resolvedPortalSite._id + '_term' + '1'} title={'Thermostats'}>
          {thermostatHtml}
        </GroupSubMenuItem>
      ) : undefined

    wimeHtml =
      wimeHtml.length > 0 ? (
        <GroupSubMenuItem key={this.state.resolvedPortalSite._id + '_wimes' + '3'} title={'WiMes'}>
          {wimeHtml}
        </GroupSubMenuItem>
      ) : undefined

    zipProHtml =
      zipProHtml.length > 0 ? (
        <GroupSubMenuItem key={this.state.resolvedPortalSite._id + '_zip' + '4'} title={'Zip PROs'}>
          {zipProHtml}
        </GroupSubMenuItem>
      ) : undefined

    //Put them all together (alphabetical order, and deviceType order)
    //And wrap in a React.Fragment in case there are no
    var siteDevicesHtml = (
      <React.Fragment>
        {thermostatHtml}
        {/*
			{wimeHtml}
			{zipProHtml}
			*/}
      </React.Fragment>
    )

    return siteDevicesHtml
  }

  generateDeviceHtml = (resolvedSiteDevice) => {
    var deviceTypeInt = resolvedSiteDevice.deviceType
    switch (deviceTypeInt) {
      //Thermostat
      case 1:
        return this.generateThermostatHtml(resolvedSiteDevice)
        break
      //Wime/Zippro, currently connected
      case 3:
        return this.generateZipProHtml(resolvedSiteDevice)
        break

      default:
        return this.generateZipProHtml(resolvedSiteDevice)
    }
  }

  generateThermostatHtml = (resolvedSiteDevice) => {
    return (
      <GroupSubMenuItem 
				key={resolvedSiteDevice._id + resolvedSiteDevice.name} 
				title={resolvedSiteDevice.name}
			>
        <MenuItem
          isActive={this.props.isActive}
          isActiveSection={this.props.isActiveSection}
          toggle={this.props.toggle}
          shared={this.props.shared}
          key={resolvedSiteDevice._id + resolvedSiteDevice.name + '_data'}
          title={'Data Analysis'}
          to={`/site/${this.state.resolvedPortalSite._id}/device/${resolvedSiteDevice._id}/analysis`}
        />
        <MenuItem
          isActive={this.props.isActive}
          isActiveSection={this.props.isActiveSection}
          toggle={this.props.toggle}
          shared={this.props.shared}
					key={resolvedSiteDevice._id + resolvedSiteDevice.name + '_control'}
          title={'Control'}
          to={`/site/${this.state.resolvedPortalSite._id}/device/${resolvedSiteDevice._id}/control`}
        />
      </GroupSubMenuItem>
    )
  }

  generateZipProHtml = (resolvedSiteDevice) => {
    return (
      <GroupSubMenuItem 
				key={resolvedSiteDevice._id + resolvedSiteDevice.name} 
				title={resolvedSiteDevice.name}>
        <MenuItem
          isActive={this.props.isActive}
          isActiveSection={this.props.isActiveSection}
          toggle={this.props.toggle}
          shared={this.props.shared}
          key={resolvedSiteDevice._id}
          title={'Data Analysis'}
          to={`/site/${this.state.resolvedPortalSite._id}/device/${resolvedSiteDevice._id}/analysis`}
        />
      </GroupSubMenuItem>
    )
  }

  render() {
    //If the user has a site && we have finished loading it
    if (this.state.resolvedPortalSite) {
      //Check if there are devices associated with the site
      if (
        this.state.resolvedPortalSite.resolvedSiteDevices &&
        this.state.resolvedPortalSite.resolvedSiteDevices.length > 0
      ) {
        var siteDeviceItems = this.generateSiteDevicesHtml(this.state.resolvedPortalSite.resolvedSiteDevices)
      }
      //This is the contained html for an entire site in the sidenav
      var siteSideNavHtml = (
        <GroupMenuItem
          isActive={this.props.isActive}
          isActiveSection={this.props.isActiveSection}
          toggle={this.props.toggle}
          shared={this.props.shared}
          title={this.state.resolvedPortalSite.name}
          icon='home'
          to='/home'
        >
          {/*Create a "dashboard" button in the list for this site*/}
          <MenuItem
            isActive={this.props.isActive}
            isActiveSection={this.props.isActiveSection}
            toggle={this.props.toggle}
            shared={this.props.shared}
            title={'Dashboard'}
            to={`/site/${this.state.resolvedPortalSite._id}/dashboard`}
          />
          <MenuItem
            isActive={this.props.isActive}
            isActiveSection={this.props.isActiveSection}
            toggle={this.props.toggle}
            shared={this.props.shared}
            title={'Manage Site'}
            to={`/site/${this.state.resolvedPortalSite._id}/sitemanager`}
          />
          {/*<MenuItem isActive={this.props.isActive} isActiveSection={this.props.isActiveSection} toggle={this.props.toggle} shared={this.props.shared} title={"Schedules"} to={`/site/${this.state.resolvedPortalSite._id}/schedules`}/>*/}
          {/*Create a "devices" subgroup that contains a list of all devices*/}
          <GroupSubMenuItem title={'Devices'} to='/home' key='home'>
            {siteDeviceItems}
          </GroupSubMenuItem>
        </GroupMenuItem>
      )
    }
    return <React.Fragment>{siteSideNavHtml}</React.Fragment>
  }
}

export default SideNavSiteUnorderedList
