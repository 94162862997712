import React, { Component } from 'react'
import { Item } from '../components/TopNavBar'

class UserMenuItem extends Component {
  render() {
    return (
      <Item {...this.props}>
        <Item.Content className="user-profile">
          <span className=" fa fa-user fa-lg fa-fw"></span>
          <span className=" fa fa-angle-down"></span>
        </Item.Content>
        <Item.SubMenu className="dropdown-usermenu pull-right">
          <li><a href="/"><i className="fa fa-sign-out pull-right"></i> Log Out</a></li>
        </Item.SubMenu>
      </Item>
    )
  }
}

export default UserMenuItem
