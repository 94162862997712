import React, { Component } from 'react';
import { Modal, Button, ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import Spinner from '../../indicators/spinner.js'
import CommandSpinner from '../../indicators/commandspinner.js'

import api from '../../util/api.js';

const { setMessageAreaMode, setMessageArea } = api;

import constants from '../../../config/constants.js'

const displayFont = {
  fontFamily: "Digital Dream",
  fontSize: "xx-large",
  fontWeight: 600
}

class NewMessageArea extends React.Component {

  constructor(props) {
    super(props);

    console.log("new message area constructor");

    this.state = {
      show: false,
      option: 1, // 1 = Display Co2, 2 = Display Temp & Hum, 3 = Display Co2 & Temp & Hum, 4 = Display Text
      tstat_msg: null,
      apiCalling: false,
      activeCommandId: '',
      checkedSensors: [],
      hasBeenUsed: false,
      sensorDefaultValues: []
    }
  }

  componentDidUpdate(prevProps) {
    //  Three conditions:
    //    - Switch hasn't been used.
    //    - LiveData is coming down as a prop
    //    - LiveData has changed
    //  The outcome:
    //    - Switch state is decided by that live data (i.e. device state)
    if (!this.state.hasBeenUsed && this.props.deviceLiveData && this.props.deviceLiveData.hasOwnProperty(constants.THERMOSTAT_COMMAND_KEYS_OBJ.MESSAGE_TEXT)
        && this.props.deviceLiveData != prevProps.deviceLiveData) {
        
          this.setState({
            tstat_msg: this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.MESSAGE_TEXT]
          },() => {
            //Check if default is sensor display format
            console.log("existing tstat_msg is: ", this.state.tstat_msg);
            if (/^[01]:[01]:[01]$/.test(this.state.tstat_msg)) {
             console.log("previouos is sensor display format");
             var sensorsChecked = this.state.tstat_msg.split(":");
             console.log("default sensors array is: ", sensorsChecked);
             var sensorsParsed = [];
             sensorsChecked.map((sensor, index) => {
               if(sensor == 1)
                sensorsParsed.push(index + 1);
              //return parseInt(sensor);
             });
             console.log("default sensors array post parse is: ", sensorsParsed);
             this.setState({
              sensorDefaultValues: sensorsParsed,
              option: 1
             });
            }
            else{
              console.log("previouos is custom text");
              this.setState({
               option: 2
              });
              //this.setState({option: 4});
            }
          });
    }
  }

  handleShow = (e) => {
    this.setState({show: true});
  }

  handleClose = (e) => {
    this.setState({show: false});
  }

  handleSubmit = (e) => {
    var messageText = "";
    switch (this.state.option) {
      case 1: // Display Co2 Reading
        //console.log("Placeholder: Call API to set Message Area Mode 'Co2'.");
        this.setTextMessageAreaMode(0);
        break;
      case 2: // Display Temp & Humidity Reading
        // console.log("Placeholder: Call API to set Message Area Mode 'Temp & Hum'.");
        this.setTextMessageAreaMode(1);
        break;
      case 3: // Display Co2 & Temp & Humidity Reading
        // console.log("Placeholder: Call API to set Message Area Mode 'Co2 & Temp & Hum'.");
        this.setTextMessageAreaMode(2);
        break;
      case 4: // Display Custom Text
        //  Make sure we have an ASCII string of 17 chars or less.
        this.setTextMessageArea();
        break;
    }
  }

  setTextMessageAreaMode = (mode) => {
    // this.setState({ apiCalling: true });
    // setMessageAreaMode(this.props.siteID, this.props.deviceID, mode)
    // .then(response => {
    //   this.setState({
    //     apiCalling: false,
    //     activeCommandId: response.data.commandId
    //   });
    //   if(response && response.success === true){
    //     console.log("API: Set message area mode success.")
    //   }
    //   else{
    //     console.log("Couldn't update message area mode")
    //     console.log(response);
    //   }
    // })
    // .catch((err) => {
    //   console.error(err);
    // });
  }

  setTextMessageArea = () => {
    if (/^[\x00-\x7F]{0,10}$/.test(this.state.tstat_msg)) {
      console.log("Submitting message: " + this.state.tstat_msg);
      //  Continue below.
    }
    else if (this.state.tstat_msg.length > 10) {
      alert("Error: the message you've written is too long. The maximum length is 10 characters.");
      return;
    }
    else {
      alert("Error: the message you've written is not a valid ASCII string.");
      return;
    }

    this.setState({ apiCalling: true });
    setMessageArea(this.props.siteID, this.props.deviceID, this.state.tstat_msg)
    .then(response => {
      this.setState({
        apiCalling: false,
        activeCommandId: response.data.commandId
      });
      if(response && response.success === true){
        console.log("API: Set message area success.")
      }
      else{
        console.log("Couldn't update message area.")
        console.log(response);
      }
    })
    .catch((err) => {
      console.error(err);
    });
  }

  handleMsgChange = (e) => {
    this.setState({ tstat_msg: e.target.value });
  }

  handleOptionChange = (value) => {
    this.setState({ option: value });
  }

  handleSensorChange = (e) => {
    console.log("sensor change, e is: ", e);
    this.setState({checkedSensors: e});
  }

  handleSubmitMessage = (e) => {
    //  According to the manual, the message submitted must be an
    //  ASCII string. Let's make sure it is.

    var messageText = "";

    //Handle display sensor values
    if(this.state.option == 1){
      messageText = "0:0:0";
      for(var i = 0; i < this.state.checkedSensors.length; i++){
        var checkedOption = this.state.checkedSensors[i];
        switch(checkedOption){
          case 1: //Co2
            messageText = "1" + messageText.substring(1);
            //return this.substring(0, index) + replacement + this.substring(index + replacement.length);
            break;
          case 2: //Temp
            messageText = messageText.substring(0, 2) + "1" + messageText.substring(3);
            break;
          case 3: //Humidity
            messageText = messageText.substring(0, 4) + "1";
            break;
        }
      }
    }
    else{
      messageText = this.state.tstat_msg;
      console.log("messageText custom is: ", messageText);
      if (!/^[\x00-\x7F]*$/.test(this.state.tstat_msg )) {
        alert("Error: the message you've written is not a valid ASCII string.");
        return;
      }
      else if (this.state.tstat_msg.length > 10) {
        alert("Error: the message you've written is too long. The maximum length is 10 characters.");
        return;
      }
    }

    console.log("setting messagearea to : ", messageText);
    this.setState({ apiCalling: true });
    setMessageArea(this.props.siteID, this.props.deviceID, messageText)
    .then(response => {
      this.setState({
        apiCalling: false,
        activeCommandId: response.data.commandId
      });
      if(response && response.success === true){
        console.log("API: Set message area success.")
      }
      else{
        console.log("Couldn't update message area.")
        console.log(response);
      }
    })
    .catch((err) => {
      console.error(err);
    });

  }

  clearActiveCommandId = () => {
    this.setState({
      activeCommandId: "",
    })
  }

  render() {
    return (
      <div>
        <Button onClick={this.handleShow}><i className="fa fa-pencil-square-o"></i></Button>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Configure Message Area</Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass={'samModalBody'}>
          <center style={{paddingLeft: "20px", paddingRight: "20px"}}>
            <div style={{width: "100%"}}>
              <ButtonToolbar>
                <ToggleButtonGroup style={{float: "none"}} onChange={this.handleOptionChange} type="radio" name="options" defaultValue={this.state.option}>
                  <ToggleButton block={true} value={1}>Choose which sensor readings to display</ToggleButton>
                  <ToggleButton block={true} value={2}>Display Custom Text</ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
              { this.state.option === 1 &&
              <div style={{marginTop: "5px"}}>
                <div>Select up to three sensors to display on the message area</div>
                <ButtonToolbar style={{visibility: this.state.option === 1 ? "visible" : "hidden", "marginTop": "8px", width: "100%"}}>
                  <ToggleButtonGroup onChange={this.handleSensorChange} style={{width: "100%", float: "none"}} type="checkbox" defaultValue={this.state.sensorDefaultValues}>
                    <ToggleButton style={{width: "33%"}} value={1}>CO2</ToggleButton>
                    <ToggleButton style={{width: "33%"}} value={2}>Temperature</ToggleButton>
                    <ToggleButton style={{width: "33%"}} value={3}>Relative Humidity</ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
              }
              { this.state.option === 2 &&
                <div style={{marginTop: "5px"}}>
                  <div>Enter a custom display message text (up to 10 characters)</div>
                  <input value={this.state.tstat_msg} style={{"visibility": this.state.option === 2 ? "visible" : "hidden", "marginTop": "8px", width: "100%"}} onChange={this.handleMsgChange} type="text"/>
                </div>
              }
              <div style={{marginTop: "10px"}}>
              <Spinner active={this.state.apiCalling} style={{marginTop: "3px"}}/>
              </div>
              
              <Button block={true} style={{"marginTop": "8px", marginLeft: "0px"}} bsStyle="primary" type="button" className="btn btn-default" onClick={this.handleSubmitMessage}>Submit</Button>
            </div>
          </center>
          </Modal.Body>
        </Modal>
      </div>
    )
  }

}

export default NewMessageArea;
