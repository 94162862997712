import React, { Component } from 'react';
import { Row, Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import {XPanel, PageTitle} from '../../components';
import MyToolTip from '../../indicators/mytooltip.js'
import HolidayScheduleDetails from './holidayscheduledetails.js';
import Spinner from '../../indicators/spinner.js';
import DatePicker from "react-datepicker";
import axios from 'axios';
import * as moment from 'moment'

var mapToFormat = require('./mapToFormat.js');

import api from '../../util/api.js';

const { deleteHolidaySchedule } = api;

class HolidayScheduleHoriz extends React.Component {
  
  constructor() {
    super();
    this.state = {
      apiCalling: false,
      failCalling: false,
    }
  }

  handleDelete = (e) => {
      this.setState({ 
        failCalling: false,
        apiCalling: true,
       });
      deleteHolidaySchedule(this.props.scheduleObj._id, this.props.scheduleObj.name)
      .then((res) => {
        console.log(res);
        this.props.handleRefreshSchedules();
        this.setState({
          apiCalling: false,
          failCalling: false,
        });
      })
      .catch((err) => {
        console.log("delete schedule error");
        console.log(err);
        this.setState({
          apiCalling: false,
          failCalling: true,
        });
      });
  }

  getDeleteSiteButtonJsx = (site) => {
    return(
      <div>
        {this.props.scheduleObj.name}
        <Button className={'btn-danger'} style={{marginLeft: "10px"}} onClick={this.handleDelete}>DELETE</Button>
      </div>
    );
  }

  render() {
    return (
      <Row style={{ paddingBottom: '32px' }} md={ 12 } sm={ 12 } xs={ 12 }>
        <XPanel>
          <XPanel.Title title={this.getDeleteSiteButtonJsx()}></XPanel.Title>
          <XPanel.Content>
          
          <Row>
            <Col md={ 12 } sm={ 12 } xs={ 12 } style={{width: "100%"}}>
              {/* <Row>
                <b>Schedule Start Datetime: </b>{this.props.scheduleObj.startDatetime}
              </Row>
              <Row>
                <b>Schedule End Datetime: </b>{this.props.scheduleObj.endDatetime}
              </Row> */}
              <Row>
                <center>
                  Date range for this holiday schedule:
                  
                  <h3><b>{moment(this.props.scheduleObj.startDatetime).format("YYYY/MM/DD")}</b> - <b>{moment(this.props.scheduleObj.endDatetime).format("YYYY/MM/DD")}</b></h3>
                </center>
              </Row>
            </Col>
          </Row>
          <Row>
            <center>
              <DatePicker
                selectsRange={true}
                startDate={new Date(this.props.scheduleObj.startDatetime)}
                endDate={new Date(this.props.scheduleObj.endDatetime)}
                placeholderText="Click to select a date"
                monthsShown={moment(this.props.scheduleObj.endDatetime).month() - moment(this.props.scheduleObj.startDatetime).month() + 1}
                disabled
                disabledKeyboardNavigation
                selectsDisabledDaysInRange
                onChange={(update) => {
                  //setDateRange(update);
                  // this.setState({
                  //   startDatetime: update[0],
                  //   endDatetime: update[1]
                  // }, () => {

                  //   //Second item was selected, draw this date
                  //   // if(update[1] !== null) {
                  //   //   this.handleTimeChange(this.state.startDatetime, this.state.endDatetime);
                  //   // }
                  // });
                  //console.log("update: ", update);
                }}
                isClearable={true}
                inline
              />
            </center>
          </Row>
          <Col md={ 12 } sm={ 12 } xs={ 12 } style={{width: "100%"}}>
            <HolidayScheduleDetails scheduleObj={this.props.scheduleObj} />
          </Col>
          <Col md={ 1 } sm={ 1 } xs={ 1 }>
            
            <Spinner active={this.state.apiCalling} fail={this.state.failCalling}/>
          </Col>

          </XPanel.Content>
        </XPanel>
        
      </Row>
    )
  }

}

export default HolidayScheduleHoriz;
