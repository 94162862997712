import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames';
import SharedState from  '../SharedState'
import Title from './Title'
import Content from './Content'
import XcContent from './XcContent'
import { MenuItem } from 'react-bootstrap'

const propTypes = {
  visible: PropTypes.bool,
  onHide: PropTypes.func
}

const defaultProps = {
  visible: true,
  onHide: () => {}
}

class XPanel extends Component {
  constructor(props) {
    super(props)
    this.shared = SharedState(this, {
      onHide: props.onHide
    })
    this.state = {
      collapsed: false
    }
  }

  render () {
    const { children, className } = this.props
    const childrenProps = {shared: this.shared}
    const childrenWithProps = React.Children.map(children, c => {
      var scrollStyle = {//{height: "100%", overflow: "hidden", overflowY: "scroll", maxHeight: "95%", overflow: "auto"}
          style: {
            overflowY: "auto",
            overflow: "auto",
            height: "80%",
          }
      };
      if(c.type.name === "Content" && this.props.showScroll){
        var combinedStyle = {...childrenProps, ...scrollStyle};
        var contentElement = React.cloneElement(c, combinedStyle);
        return React.cloneElement(c, combinedStyle);
      }

      return React.cloneElement(c, childrenProps);
    });
    const collapsedStyle = this.state.collapsed ? {height: 'auto'} : {height: "100%"}
    const visibleStyle = this.props.visible ? {marginBottom: "10px"} : {display: 'none'}
    const style = {...collapsedStyle, ...visibleStyle}

    return (
      <div className={cn('x_panel', className, {tile: true})} style={style}>
        {childrenWithProps}
      </div>
    )
  }
}

XPanel.propTypes = propTypes
XPanel.defaultProps = defaultProps
XPanel.Title = Title
XPanel.Content = Content
XPanel.XcContent = XcContent
XPanel.MenuItem = MenuItem

export default XPanel
