import React, { Component } from 'react';
import classnames from 'classnames';

const spinnerStyle = {
  fontSize: "1.5em",
  margin: "0px",
}

//  This generic Spinner component provides some progress visualization for user
//  Behavior:
//    props.active determines whether the Spinner is shown.
//    When it first becomes active, the Spinner shows a spinning progress icon.
//    When it becomes inactive, the Spinner shows a checkmark for one second,
//      then disappears.
//  How to Use (Example):
//    In parent component, define some variable such as "apiCalling".
//    When you make your API call, set apiCalling true.
//    When your API call comes back, set apiCalling false.
//    Provide apiCalling as the "active" prop for this Spinner.

class Spinner extends React.Component {
  constructor() {
    super();
    this.state = {
      spinnerClass: {
        'display': 'none'
      }
    }
  }

  //<i className="fa-solid fa-circle-exclamation"></i>

  componentDidUpdate(prevProps, prevState) {
    if (this.props === prevProps) return;
    if(this.props.fail === true){
      this.setState({
        spinnerClass: {
          'fa': true,
          'fa-spinner': false,
          'fa-check': false,
          'Fan-icon': false,
          'fa-exclamation-triangle': true
        }
      }, () => {
        this.countDownToDisappear();
      });
    }
    else if (this.props.active === true) { // active, whee
      this.setState({
        spinnerClass: {
          'fa': true,
          'fa-spinner': true,
          'fa-check': false,
          'Fan-icon': true,
          'fa-exclamation-triangle': false
        }
      });
    }
    else { // not active
      if (prevState.spinnerClass['display'] === 'none') return; // do not show the checkmark if we are coming from default state
      this.setState({
        spinnerClass: {
          'fa': true,
          'fa-sync': false,
          'fa-check': true,
          'Fan-icon': false,
          'fa-exclamation-triangle': false
        }
      }, () => {
        this.countDownToDisappear();
      });
    }
  }

  countDownToDisappear = () => {
    setTimeout(() => {
      this.setState({
        spinnerClass: {
          'display': 'none'
        }
      });
    }, 1000);
  }

  render() {
    let spinnerClass = classnames(this.state.spinnerClass);
    return (
      <i style={spinnerStyle} className={spinnerClass}></i>
    );
  }

}

export default Spinner;
