import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import HolidayScheduleHoriz from './holidayschedulehoriz.js';

class HolidaySchedulesMenuList extends React.Component {
  constructor() {
    super();
  }

  render() {
    let scheduleElements;
    if (this.props.didApiFetch === false) {
      scheduleElements = <div style={{ fontSize: "16px" }} ><br/></div>
    }
    else if (this.props.scheduleObjs == undefined || this.props.scheduleObjs.length === 0) {
      scheduleElements = <div style={{ fontSize: "16px" }} ><br/>No holiday schedules found.</div>
    }
    else {
      scheduleElements = this.props.scheduleObjs.map((scheduleObj, index) => {
        return (
          <HolidayScheduleHoriz scheduleObj={scheduleObj} siteID={ this.props.siteID }
            index={index} key={index} handleRefreshSchedules={this.props.handleRefreshSchedules}/>
        )
      })
    }

    return (
      <Col>
        {scheduleElements}
      </Col>
    )
  }
}

export default HolidaySchedulesMenuList;
