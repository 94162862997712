import React, { Component } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import ScheduleVert from '../schedules/schedulevert.js';
import Save from '../../indicators/save.js';
import MyToolTip from '../../indicators/mytooltip.js';
import Spinner from '../../indicators/spinner.js';
import CommandSpinner from '../../indicators/commandspinner.js';

import api from '../../util/api.js';

const { getUserPromise, postSchedule } = api;
var equal = require('deep-equal');
var mapToFormat = require("../schedules/mapToFormat.js");

var apiUrl = "https://jdpy43j0u7.execute-api.us-west-2.amazonaws.com";

class ViewThermoSchedule extends React.Component {
  constructor() {
    super();

    this.state = {
      show: false,
      userSchedules: [],
      enableSave: false,
      apiCalling: false,
      activeCommandId: '',
      deviceScheduleName: '',
    }
  }

  componentDidMount() {
    this.getSchedules();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.configuration === prevProps.configuration) {
      return;
    }
    this.checkWhetherToEnableSave();
  }

  checkWhetherToEnableSave = () => {
    if (this.props.configuration === null || !this.props.configuration.hasOwnProperty('tstat_prog')) {
      this.setState({ enableSave: false });
      return;
    }
    this.setState({
      enableSave: this.isNewSchedule(mapToFormat.deviceScheduleToUserSchedule(this.props.configuration, this.state.deviceScheduleName))
    });
  }

  isNewSchedule = (schedule) => {
    let inputSchedule = JSON.parse(JSON.stringify(schedule)); // deep copy input.
    delete inputSchedule.name; // remove the name.
    for (let i = 0; i < this.state.userSchedules.length; i++) {
      let otherSchedule = JSON.parse(JSON.stringify(this.state.userSchedules[i])); // deep copy other
      let otherScheduleName = otherSchedule.name;
      delete otherSchedule.name;
      if (equal(inputSchedule, otherSchedule)) {
        console.log("ViewThermoSchedule: It's not a new schedule.");
        this.setState({ deviceScheduleName: otherScheduleName });
        return false;
      }
    }
    this.setState({ deviceScheduleName: "Front Panel Schedule" });
    return true;
  }

  postSchedule = () => {
    let newSchedObj = mapToFormat.deviceScheduleToUserSchedule(this.props.configuration, this.state.deviceScheduleName);
    console.log(newSchedObj);
    this.setState({ apiCalling: true });
    postSchedule(newSchedObj)
    .then(response => {
      console.log(response);
      this.setState({
        apiCalling: false,
        // activeCommandId: response.commandId
       });
      if(response && response.success === true){
        console.log("API: New schedule submitted successfully.");
      }
      else{
        console.log("API: Failed to submit new schedule.")
      }
    });
  }

  getSchedules = () => {
    getUserPromise()
    .then(user => {
      console.log("getSchedules user.thermostatschedules: ", user.thermostatschedules);
        this.setState({
          userSchedules: user.thermostatschedules
        }, () => { this.checkWhetherToEnableSave() });
    })
    .catch((error) => {
      console.log("ViewThermoSchedule: Error getting schedules.");
      console.log(error);
    });
  }

  handleShow = (e) => {
    this.setState({ show: true })
  }

  handleClose = (e) => {
    this.setState({ show: false })
  }

  clearActiveCommandId = () => {
    console.log("ViewThermoSchedule: Cleared active command ID.");
    this.setState({
      activeCommandId: "",
    })
  }

  render() {
    let buttonElement = <div className="count-verbose">None</div>
    let scheduleElement = <div>Placeholder. Nothing here.</div>
    if (this.props.configuration && this.props.configuration.hasOwnProperty('tstat_prog')) {
      buttonElement = <Button onClick={this.handleShow}>
                        Click to View
                      </Button>
      scheduleElement = <ScheduleVert scheduleObj={mapToFormat.deviceScheduleToUserSchedule(this.props.configuration, this.state.deviceScheduleName)} isLoadedSchedule/>
    }
    let saveTooltip = "We noticed this schedule isn't saved to your account. Click here to save it."

    let saveElement = this.state.enableSave ?
                      <Save onSave={ this.postSchedule } tooltip={ saveTooltip } /> :
                      <span></span>

    return (
      <div>
        {buttonElement}

        <Modal show={this.state.show} onHide={this.handleClose} bsSize="lg">
          <Modal.Header closeButton>
            <Modal.Title>Current Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass={'samModalBody2'}>
            {saveElement}
            <Spinner active={ this.state.apiCalling } />
            <CommandSpinner
              commandId={ this.state.activeCommandId } clearActiveCommandId={this.clearActiveCommandId}
              siteID={this.props.siteID} deviceID={this.props.deviceID}
              getLiveDataAfterTime={this.props.getLiveDataAfterTime}
            />
            <center className="centerTable">
              <div className="tableCell">
                { scheduleElement }
              </div>
            </center>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default ViewThermoSchedule;
