import React, { Component } from 'react'
import { Button, DropdownButton, MenuItem } from 'react-bootstrap'
import ScheduleVert from '../schedules/schedulevert.js'
import Spinner from '../../indicators/spinner.js'
import CommandSpinner from '../../indicators/commandspinner.js'

import api from '../../util/api.js'

const { getUserPromise, postCreateThermostatHolidayScheduleEvent, setSchedule } = api
var mapToFormat = require('../schedules/mapToFormat.js')

var apiUrl = 'https://jdpy43j0u7.execute-api.us-west-2.amazonaws.com'

class ChangeHolidaySchedule extends React.Component {
  constructor() {
    super()

    this.state = {
      currSelectedSchedule: null,
      scheduleObjs: [],
      activeCommandId: '',
    }
  }

  componentDidMount() {
    this.getSchedules()
  }

  getSchedules = () => {
    getUserPromise()
      .then((user) => {
        this.setState({
          scheduleObjs: user.thermostatholidayschedules,
          user: user,
        })
      })
      .catch((error) => {
        console.log('ViewThermoSchedule: Error getting schedules.')
        console.log(error)
      })
  }

  handleScheduleSubmit = (e) => {
    if (this.state.currSelectedSchedule == null) {
      alert('You must select a schedule to submit.')
      return
    }
    // console.log("this.state.user: ", this.state.user);
    // console.log("this.props.portalDeviceID: ", this.props.portalDeviceID);
    // console.log("this.state.currSelectedSchedule: ", this.state.currSelectedSchedule);
    this.setState({ apiCalling: true })
    //return;
    postCreateThermostatHolidayScheduleEvent(this.props.portalDeviceID, this.state.currSelectedSchedule._id).then(
      (res) => {
        console.log(res)

        this.setState({
          apiCalling: false,
        })
      },
    )
    // setSchedule(this.props.siteID, this.props.deviceID, coolScheduleForDevice)
    // .then(setSchedule(this.props.siteID, this.props.deviceID, heatScheduleForDevice))
    // .then(commandIds => {
    //   this.setState({ apiCalling: false });
    //   console.log(commandIds);
    //   //  Since setting the schedule actually consists of
    //   //  6 commands, just grab the first one and use that
    //   //  for the command spinner.
    //   this.setState({ activeCommandId: commandIds[0] });
    // });
  }

  onSelectSchedule = (eventKey) => {
    this.setState({
      currSelectedSchedule: this.state.scheduleObjs[eventKey],
    })
  }

  clearActiveCommandId = () => {
    this.setState({
      activeCommandId: '',
    })
  }

  render() {
    let scheduleMenuItemElements = []
    if (this.state.scheduleObjs.length > 0) {
      scheduleMenuItemElements = this.state.scheduleObjs.map((scheduleObj, index) => {
        return (
          <MenuItem onSelect={this.onSelectSchedule} eventKey={index} key={index}>
            {scheduleObj.name}
          </MenuItem>
        )
      })
    }

    return (
      <center>
        <DropdownButton title='Schedules' style={{ marginRight: '5px' }}>
          {scheduleMenuItemElements}
        </DropdownButton>

        {/* <CommandSpinner
          commandId={ this.state.activeCommandId } clearActiveCommandId={this.clearActiveCommandId}
          siteID={this.props.siteID} deviceID={this.props.deviceID}
          getLiveDataAfterTime={this.props.getLiveDataAfterTime}
          handleSchedulePosted={this.getSchedules}
        /> */}
        <Button
          style={{ marginBottom: '0px', marginLeft: '5px' }}
          onClick={this.handleScheduleSubmit}
          type='button'
          className='btn btn-default'
        >
          Submit
        </Button>
        <Spinner active={this.state.apiCalling} />
        <div>
          <ScheduleVert scheduleObj={this.state.currSelectedSchedule} hideActivate={true} fillParent={true} />
        </div>
      </center>
    )
  }
}

export default ChangeHolidaySchedule
