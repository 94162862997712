import React, { Component } from 'react';
import { Row, Col, Button, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';
import Spinner from '../../indicators/spinner.js'

import constants from '../../../config/constants.js'
import api from '../../util/api.js';

const { postThermostatTemperatureUnitsPromise, postThermostatClockPromise, setSetpoint } = api;

class ChangeTemperatureUnits extends React.Component {
  constructor() {
    super();

    this.state = {
      apiCalling: false,
      activeCommandId: '',
      hasBeenUsed: false,
      hour: 1,
      minute: 0,
      ampm: "AM",
      day: "Sunday",
    }
  }

  componentDidUpdate(prevProps) {
    //  Three conditions:
    //    - Switch hasn't been used.
    //    - config is coming down as a prop
    //    - config has changed
    //  The outcome:
    //    - Switch state is decided by that config data (i.e. device state)

    if (!this.state.hasBeenUsed && this.props.deviceConfig &&
        this.props.deviceConfig != prevProps.deviceConfig) {
        var temperatureUnits = this.props.deviceConfig[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.TEMPERATURE_DISPLAY_UNITS]];
        
        console.log("temperatureUnits are: ", temperatureUnits);
        
        this.setState({
            temperatureUnits: temperatureUnits,
        });

    }

  }

  handleSubmit = () => {
    this.setState({
        apiCalling: false,
    });
    postThermostatTemperatureUnitsPromise(this.props.siteID, this.props.deviceID, this.state.temperatureUnits)
    .then(() => {
        this.setState({
            apiCalling: false,
        });
    })
    .catch((e) => {
        console.log(e);
        this.setState({
            apiCalling: false,
        });
    });
    // postThermostatClockPromise(this.props.siteID, this.props.deviceID, clockString)
    // .then(() => {
    //     this.setState({
    //         apiCalling: false,
    //     });
    // })
    // .catch((e) => {
    //     console.log(e);
    //     this.setState({
    //         apiCalling: false,
    //     });
    // });

    // if(Math.abs(this.state.heatSetValue - this.state.coolSetValue) < 4){
    //   // console.log("You do not have a 4 degree differential between your heat and cool setpoints. Please adjust your setpoints and try again.");
    //   alert("You do not have a 4 degree differential between your heat and cool setpoints. Please adjust your setpoints and try again.");
    //   return;
    // }
    // this.setState({ apiCalling: true });
    // setSetpoint(this.props.siteID, this.props.deviceID,
    //   "H", this.state.heatSetValue)
    // .then(res => {
    //   console.log("and then....");
    //   return setSetpoint(this.props.siteID, this.props.deviceID,
    //     "C", this.state.coolSetValue);
    // })
    // .then(res => {
    //   console.log(res);
    //   this.setState({
    //     apiCalling: false,
    //     activeCommandId: res.data.commandId,
    //   });
    // })
    // .catch((e) => {
    //   console.log(e);
    //   this.setState({ apiCalling: false });
    // });
  }

  selectOption = (buttonSelectedValue) => {

    if(!this.state.hasBeenUsed)
        this.setState({hasBeenUsed: true});
    
    this.setState({temperatureUnits: buttonSelectedValue});

  }

  render() {

    return (
      <center>
        {/*
        <Knob min="0" max="100" size="150" program="COOL"
          value={this.state.knobValue}
          changeValue={this.handleChangeKnobValue}
          color={knobColor}
          defaultValue={defaultValue}
          mode={this.props.deviceLiveData ? this.props.deviceLiveData.tstat_read_data.tstat_mode: "..."}
        />
        */}
        <Row>
          <Col md={ 12 } sm={ 12 } xs={ 12 }>
            <div>

                <ToggleButtonGroup type="radio" name="options" defaultValue={"F"} style={{width: "100%"}} value={this.state.temperatureUnits} onChange={(e) => {this.selectOption(e)}}>
                  <ToggleButton value={"C"} style={{width: "50%"}}>°C</ToggleButton>
                  <ToggleButton value={"F"} style={{width: "50%"}}>°F</ToggleButton>
                </ToggleButtonGroup>

            </div>
            
          </Col>
        </Row>
        <Row>
          <Button style={{"marginTop": "8px", marginRight: "0px"}} type="button" className="btn btn-default" onClick={this.handleSubmit}>Submit</Button>
        </Row>
        <Row>
          <Spinner active={this.state.apiCalling}/>
        </Row>
      </center>
    )
  }
}

export default ChangeTemperatureUnits;
