import React, { Component } from 'react';
import { Row, Col, Button, ToggleButtonGroup, ToggleButton, InputGroup} from 'react-bootstrap';
import Spinner from '../../indicators/spinner.js'

import constants from '../../../config/constants.js'

import api from '../../util/api.js';

const { postThermostatClockPromise, setSetpoint } = api;

const dayMap = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

class ChangeClock extends React.Component {
  constructor() {
    super();

    this.state = {
      apiCalling: false,
      activeCommandId: '',
      hasBeenUsed: false,
      hour: 1,
      minute: 0,
      ampm: "AM",
      day: "Sunday",
    }
  }

  handleModeChange = (value) => {
    this.setState({ mode: value });
  }

  componentDidUpdate(prevProps) {
    //  Three conditions:
    //    - Switch hasn't been used.
    //    - LiveData is coming down as a prop
    //    - LiveData has changed
    //  The outcome:
    //    - Switch state is decided by that live data (i.e. device state)
    if (!this.state.hasBeenUsed && this.props.deviceLiveData && this.props.deviceLiveData.hasOwnProperty(constants.THERMOSTAT_COMMAND_KEYS_OBJ.CLOCK)
        && this.props.deviceLiveData != prevProps.deviceLiveData) {
        var clockStringParts = this.props.deviceLiveData[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CLOCK].split(":");
        var dayString = dayMap[parseInt(clockStringParts[0])];
        var hourString = parseInt(clockStringParts[1]);
        var ampmValue = hourString <= 11 ? "AM" : "PM";
        hourString = hourString % 12;
        var minuteString = parseInt(clockStringParts[2]);
        this.setState({
            day: dayString,
            hour: hourString,
            minute: minuteString,
            ampm: ampmValue,
        });

    }
  }

  handleSubmit = () => {
    var dayInt = dayMap.indexOf(this.state.day);
    var hourInt = parseInt(this.state.hour);

    if((this.state.ampm == "PM" && hourInt != 12))
        hourInt += 12;
    if(this.state.ampm == "AM" && hourInt == 12){
        hourInt -= 12;
    }

    var minuteInt = parseInt(this.state.minute);

    var clockString = dayInt + ":" + hourInt + ":" + minuteInt;
    
    postThermostatClockPromise(this.props.siteID, this.props.deviceID, clockString)
    .then(() => {
        this.setState({
            apiCalling: false,
        });
    })
    .catch((e) => {
        console.log(e);
        this.setState({
            apiCalling: false,
        });
    });
    // if(Math.abs(this.state.heatSetValue - this.state.coolSetValue) < 4){
    //   // console.log("You do not have a 4 degree differential between your heat and cool setpoints. Please adjust your setpoints and try again.");
    //   alert("You do not have a 4 degree differential between your heat and cool setpoints. Please adjust your setpoints and try again.");
    //   return;
    // }
    // this.setState({ apiCalling: true });
    // api.setSetpoint(this.props.siteID, this.props.deviceID,
    //   "H", this.state.heatSetValue)
    // .then(res => {
    //   console.log("and then....");
    //   return setSetpoint(this.props.siteID, this.props.deviceID,
    //     "C", this.state.coolSetValue);
    // })
    // .then(res => {
    //   console.log(res);
    //   this.setState({
    //     apiCalling: false,
    //     activeCommandId: res.data.commandId,
    //   });
    // })
    // .catch((e) => {
    //   console.log(e);
    //   this.setState({ apiCalling: false });
    // });
  }

  selectOption = (e) => {

    if(!this.state.hasBeenUsed)
        this.setState({hasBeenUsed: true});

    if(e.target.id === "day_select"){
        this.setState({day: e.target.value});
    }
    else if(e.target.id === "hour_input"){
        if(e.target.value > 12 || e.target.value < 1)
            return;
        this.setState({hour: e.target.value});
    }
    else if(e.target.id === "minute_input"){
        if(e.target.value > 59 || e.target.value < 0)
            return;
        this.setState({minute: e.target.value});
    }
    else if(e.target.id === "ampm_select"){
        this.setState({ampm: e.target.value});
    }
    
  }

  render() {

    return (
      <center>
        {/*
        <Knob min="0" max="100" size="150" program="COOL"
          value={this.state.knobValue}
          changeValue={this.handleChangeKnobValue}
          color={knobColor}
          defaultValue={defaultValue}
          mode={this.props.deviceLiveData ? this.props.deviceLiveData.tstat_read_data.tstat_mode: "..."}
        />
        */}
        <Row>
          <Col md={ 12 } sm={ 12 } xs={ 12 }>
            <div>
                <select id="day_select" value={this.state.day} onChange={(e) => {this.selectOption(e)}} style={{height:"25px", "marginRight": "5px"}}>
                    <option value="Sunday">Sunday</option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                </select>
                <input id="hour_input" type="number" onChange={(e) => {this.selectOption(e)}} value={this.state.hour} style={{width: "50px", "marginRight": "5px"}}/>
                <b style={{"marginRight": "5px"}}>:</b>
                <input id="minute_input" type="number" onChange={(e) => {this.selectOption(e)}} value={this.state.minute} style={{width: "50px", "marginRight": "5px"}}/>
                <select id="ampm_select" onChange={(e) => {this.selectOption(e)}} style={{height: "25px"}} value={this.state.ampm}>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                </select>
              
            </div>
            
          </Col>
        </Row>
        <Row>
          <Button style={{"marginTop": "8px"}} type="button" className="btn btn-default" onClick={this.handleSubmit}>Submit</Button>
        </Row>
        <Row>
          <Spinner active={this.state.apiCalling}/>
        </Row>
      </center>
    )
  }
}

export default ChangeClock;
