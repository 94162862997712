import React, { Component } from 'react';
import classnames from 'classnames';
import api from '../util/api.js';

const { getCommand }	= api;

const spinnerStyle = {
  fontSize: "2em",
  margin: "4px",
}

//  This CommandSpinner component provides some progress visualization for user
//    and also checks continuously whether a command has been carried out.

class CommandSpinner extends React.Component {
  constructor() {
    super();
    this.state = {
      spinnerClass: {
        'display': 'none'
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //  All we care about here is commandId. If that hasn't changed,
    //  don't do anything.
    if (this.props.commandId === prevProps.commandId) return;
    if (this.props.commandId !== "") { // This means we became active.
      //  Important: Clear any regular check that's already happening. Otherwise, we will have
      //  multiple checks running and the later one will error out when it tries to hit the API with a blank commandID.
      if (this.state.hasOwnProperty("regCheckForCommandCarriedOut")) {
        clearInterval(this.state.regCheckForCommandCarriedOut);
      }
      let regCheckForCommandCarriedOut = setInterval(this.checkForCommandCarriedOut, 10000);
      this.setState({
        spinnerClass: {
          'fa': true,
          'fa-home': true,
          'fa-check': false,
          'Fan-icon': true,
        },
        regCheckForCommandCarriedOut: regCheckForCommandCarriedOut,
      });
    }
    else { // This means we became unactive.
      if (prevState.spinnerClass['display'] === 'none') return; // do not show the checkmark if we are coming from default state
      this.setState({
        spinnerClass: {
          'fa': true,
          'fa-home': false,
          'fa-check': true,
          'Fan-icon': false,
        }
      }, () => {
        this.countDownToDisappear();
      });
    }
  }

  countDownToDisappear = () => {
    setTimeout(() => {
      this.setState({
        spinnerClass: {
          'display': 'none'
        }
      });
    }, 1000);
  }

  checkForCommandCarriedOut = () => {
    getCommand(this.props.siteID, this.props.deviceID, this.props.commandId)
    .then(res => {
      if (!res.data.hasOwnProperty("command")) { // EROR HERE
        clearInterval(this.state.regCheckForCommandCarriedOut);
        this.props.getLiveDataAfterTime(15000); // in 15 seconds refresh live data, hopefully showing our changes
        this.props.clearActiveCommandId();
      }
    });
  }

  render() {
    let spinnerClass = classnames(this.state.spinnerClass);
    return (
      <i style={spinnerStyle} className={spinnerClass}></i>
    );
  }

}

export default CommandSpinner;
